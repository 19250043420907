import { combineReducers } from 'redux';

import data from './data';
import state from './state';


export default combineReducers({
  data,
  state
});
