import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import locale from 'antd/es/locale/ru_RU';
import { ConfigProvider } from 'antd';
import App from './components/app';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/ru';


ReactDOM.render(
  <ConfigProvider locale={locale}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
