import React from 'react';
import bem from 'easy-bem';
import { Divider, Form } from 'antd';
import './style.less';
import InputV2 from 'components/input-v2';
import { requiredField, rulesValidationOne } from 'utils/validation/validation';
import FileDragger from 'components/dragger';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/button-v2';
import { useSelector } from 'react-redux';
import { getPublishedCourses } from 'models/courses/selectors';


const FormAds = ({
  ad, readonly = false, onSubmit, handleClose
}) => {
  const b = bem('form-ads');
  const courses = useSelector(getPublishedCourses).items;
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      onFinish={onSubmit}
      initialValues={ad}
    >
      <InputV2
        type="form"
        name="title"
        rules={[requiredField, ...rulesValidationOne]}
        label="Название подборки"
        placeholder="Введите текст"
        disabled={readonly}
        required
      />
      <Divider />
      <div className={b('ads-banner')}>
        <Form.Item
          label="Обложка для десктоп"
          rules={[requiredField]}
          name={['imageUrls', 'desktop']}
          getValueProps={(file) => file}
          required
          shouldUpdate
        >
          <FileDragger
            accept=".png, .jpeg, .jpg"
            disabled={readonly}
            initialFile={ad?.imageUrls?.desktop}
            fileType={['png', 'jpeg']}
            maxLength={1}
            aspect={1920 / 200}
            text="Загрузите баннер размером 1920х200px, максимальный размер 10мб"
            special
            crop
          />
        </Form.Item>
        <Divider />
        <Form.Item
          label="Обложка для планшета"
          rules={[requiredField]}
          name={['imageUrls', 'tablet']}
          getValueProps={(file) => file}
          required
          shouldUpdate
        >
          <FileDragger
            accept=".png, .jpeg, .jpg"
            disabled={readonly}
            initialFile={ad?.imageUrls?.tablet}
            fileType={['png', 'jpeg']}
            maxLength={1}
            aspect={1024 / 136}
            text="Загрузите баннер размером 1024х136px, максимальный размер 10мб"
            special
            crop
          />

        </Form.Item>
        <Divider />
        <Form.Item
          label="Обложка для мобильной версии"
          rules={[requiredField]}
          name={['imageUrls', 'phone']}
          getValueProps={(file) => file}
          required
          shouldUpdate
        >
          <FileDragger
            accept=".png, .jpeg, .jpg"
            disabled={readonly}
            initialFile={ad?.imageUrls?.phone}
            fileType={['png', 'jpeg']}
            maxLength={1}
            aspect={375 / 224}
            text="Загрузите баннер размером 375х224px, максимальный размер 10мб"
            special
            crop
          />

        </Form.Item>
        <Divider />
      </div>
      <Form.List name="courses">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <div key={key} className={b('field-list-item')}>
                <InputV2
                  name={[name]}
                  rules={[requiredField]}
                  label="Курсы"
                  type="select"
                  placeholder="Выберите курс"
                  options={courses}
                  disabled={readonly}
                  required
                  {...rest}
                />
                { fields.length > 1
                  && (
                    <PlusOutlined
                      className={b('icon-delete')}
                      onClick={() => !readonly && remove(name)}
                    />
                  )}
              </div>
            ))}
            <Form.Item>
              <Button
                className={b('item-add')}
                type="text"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={readonly}
              >
                добавить ещё курс
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Divider />
      <div className={b('footer')}>
        <Button type="primary" ghost onClick={handleClose}>Отменить</Button>
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
            >
              Сохранить
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormAds;
