import * as c from './constants';


export const fetchTags = (query = '') => ({
  type: c.getTags.tries,
  payload: query,
});
export const addTag = (tag) => ({
  type: c.addTag.tries,
  payload: tag
});

export const deleteTag = (id) => ({
  type: c.deleteTag.success,
  payload: id
});

export const fetchEvaluationCriteria = (query = '') => ({
  type: c.setEvaluationCriteria.tries,
  payload: query,
});
export const addEvaluationCriteria = (record) => ({
  type: c.addEvaluationCriteria.tries,
  payload: record
});

export const onArchiveEvaluationCriteria = (id) => ({
  type: c.onArchiveStatusEvaluationCriteria.success,
  payload: id
});

export const fetchEducationTypes = () => ({
  type: c.getEducationTypes.tries,
});

export const createEducationType = (type) => ({
  type: c.createEducationType.tries,
  payload: type
});

export const deleteEducationType = (id) => ({
  type: c.deleteEducationType.success,
  payload: id
});

export const fetchEducationProfiles = () => ({
  type: c.getEducationProfiles.tries,
});
export const fetchEducationLevels = () => ({
  type: c.getEducationLevels.tries,
});

export const createLevel = (level) => ({
  type: c.createEducationLevel.tries,
  payload: level
});

export const deleteLevel = (id) => ({
  type: c.deleteEducationLevel.success,
  payload: id
});

export const fetchEducationFormats = (query = '') => ({
  type: c.getEducationFormats.tries,
  payload: query,
});

export const addNewEducationProfile = (newEducationProfile) => ({
  type: c.getNewEducationProfile.tries,
  payload: newEducationProfile,
});

export const editProfile = (id, profile) => ({
  type: c.editProfile.tries,
  payload: ({ id, profile })
});

export const changeStatusOfEducationProfile = (id, status) => ({
  type: c.editStatusEducationProfile.success,
  payload: ({ id, status })
});

export const deleteProfile = (id) => ({
  type: c.deleteSingleEducationProfile.success,
  payload: id
});

export const createEducationFormat = (newEducationFormat) => ({
  type: c.getNewEducationFormat.tries,
  payload: newEducationFormat,
});

export const deleteEducationFormat = (id) => ({
  type: c.deleteEducationFormat.success,
  payload: id,
});

export const fetchQuestions = (query) => ({
  type: c.getQuestions.tries,
  payload: query,
});

export const fetchQuestionsAdmin = (query) => ({
  type: c.getQuestionsAdmin.tries,
  payload: query,
});

export const addQuestion = (question) => ({
  type: c.addQuestion.success,
  payload: question
});

export const deleteQuestion = (id) => ({
  type: c.deleteQuestion.tries,
  payload: id
});

export const editQuestion = (id, question) => ({
  type: c.editQuestionData.tries,
  payload: ({ id, question }),
});

export const changeStatusToPublishedQuestion = (question) => ({
  type: c.editQuestion.tries,
  payload: question
});

export const changeStatusToDraftQuestion = (question) => ({
  type: c.editQuestionToDraft.tries,
  payload: question
});

export const fetchReviewsAdmin = (query) => ({
  type: c.getReviewsAdmin.tries,
  payload: query,
});

export const addReview = (review) => ({
  type: c.addReview.tries,
  payload: review
});

export const deleteReview = (id) => ({
  type: c.deleteReview.tries,
  payload: id
});

export const editReview = (id, review) => ({
  type: c.editReview.tries,
  payload: ({ id, review }),
});
