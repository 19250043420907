import React, { useCallback, useMemo, useState } from 'react';
import bem from 'easy-bem';
import './style.less';
import { Button, Dropdown } from 'antd';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import ButtonV2 from 'components/button-v2';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getEducationFormats } from 'models/manualControlOfCouses/selectors';
import { deleteEducationFormat, fetchEducationFormats } from 'models/manualControlOfCouses/actions';
import { deleteEducationFormat as deleteSingleFormat } from 'models/manualControlOfCouses/api';
import Menu from 'components/menu';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import EducationFormIcon from 'components/education-form-icon/component';
import ModalCreateEducationForm from './modalCreateEducationForm';
import ActionModal from '../../../blog-advertising/components/actionModal';
import ModalReviewEducationForm from './modalReviewEducationForm';


export default function EducationForm() {
  const b = bem('education-form');
  const [createEducationForm, setCreateEducationForm] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const educationFormats = useSelector(getEducationFormats);
  const dispatch = useDispatch();

  const deleteFormat = async () => {
    try {
      const response = await deleteSingleFormat(isDeleteModal._id);
      dispatch(deleteEducationFormat(response.data._id));
      successMessage('Запись удалена');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const menu = useCallback((value) => (
    <Menu>
      <li className={b('menu-item')}>
        <Button
          type="text"
          onClick={() => setIsReviewModal(value)}
        >
          Посмотреть
        </Button>
      </li>
      <li>
        <Button className="menu-manual__delete" type="text" onClick={() => setIsDeleteModal(value)}>
          Удалить
        </Button>
      </li>
    </Menu>
  ), [b]);

  const columns = [
    {
      title:
  <span>
    Иконка
  </span>,
      dataIndex: 'icon',
      key: 'icon',
      render: (icon, idx) => (
        <EducationFormIcon key={idx} icon={icon} className={b('icon')} />
      ),
    },
    {
      title:
  <span>
    Формат обучения
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Описание
  </span>,
      dataIndex: 'description',
      key: 'key',
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown className={b('dropdown')} overlay={menu(record)}>
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const sortEducationTypes = useMemo(() => educationFormats.items
    .map((item) => ({ ...item, key: item._id })), [educationFormats]);
  const handleChangePage = (params) => {
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchEducationFormats(paramsString));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        searchField="title"
        columns={columns}
        data={sortEducationTypes}
        total={educationFormats.total}
        paginationWithBack
        changePage={handleChangePage}
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setCreateEducationForm(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalCreateEducationForm
        isVisibleModal={createEducationForm}
        onClose={() => setCreateEducationForm(false)}
      />
      <ModalReviewEducationForm
        isVisibleModal={!!isReviewModal}
        onClose={() => setIsReviewModal(false)}
        educationForm={isReviewModal}
      />
      <ActionModal
        isModalVisible={!!isDeleteModal}
        contentText="Вы уверены, что хотите удалить формат обучения?"
        onClose={() => setIsDeleteModal(false)}
        submitText="Удалить"
        title="Удалить запись формата обучения"
        onSubmit={deleteFormat}
      />
    </div>
  );
}
