import * as c from './constants';


export const setDrawerVisible = (value) => ({
  type: c.setDrawerVisible.success,
  payload: value
});

export const setIsMobile = (value) => ({
  type: c.setIsMobile.success,
  payload: value
});

export const setIsTablet = (value) => ({
  type: c.setIsTablet.success,
  payload: value
});

export const setIsLaptop = (value) => ({
  type: c.setIsLaptop.success,
  payload: value
});

export const setAppWidth = (width) => ({
  type: c.setAppWidth.success,
  payload: width
});

export const setFilter = (value) => ({
  type: c.setFilter.success,
  payload: value
});
