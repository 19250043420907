import React from 'react';
import bem from 'easy-bem';
import './style.less';
import { Form } from 'antd';
import FileDragger from 'components/dragger';


export default function ModalDocument({ form }) {
  const b = bem('document');

  return (
    <div className={b()}>
      <Form.Item
        name="document"
        label="Загрузите шаблон сертификата об окончании курса"
        getValueProps={(file) => file}
      >
        <FileDragger
          className={b('upload')}
          maxLength={1}
          text="Переместите фото сюда или"
          textBlue="выберите файл"
          accept=".pdf"
          fileType={['pdf']}
          initialFile={form.getFieldValue('document')}
        />
      </Form.Item>
    </div>
  );
}
