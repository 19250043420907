import * as c from './constants';


export const fetchOrdersUser = () => ({
  type: c.getOrdersUser.tries,
});
export const fetchOrdersUserPeace = (query = '') => ({
  type: c.getOrdersUserPiece.tries,
  payload: query,
});

export const addOrder = (order) => ({
  type: c.addOrderUser.success,
  payload: order,
});
