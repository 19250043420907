import { CloseOutlined, FileImageOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
  Upload, Tooltip,
} from 'antd';
import './style.less';
import bem from 'easy-bem';
import React from 'react';
import FileCrop from 'components/file-crop';


const { Dragger } = Upload;

const DraggerFileUpload = ({
  fileList,
  onChangeHandler,
  text,
  showUploadList,
  textBlue,
  crop = false,
  disabled,
  maxLength,
  tooltip,
  error,
  imgSrc,
  setImgSrc,
  onOkModal,
  aspect,
  multiple = false,
  ...rest
}) => {
  const b = bem('dragger-file-upload');
  if (crop) {
    return (
      <div>
        <Dragger
          fileList={fileList}
          multiple={multiple}
          onChange={onChangeHandler}
          showUploadList={showUploadList}
          iconRender={() => <FileImageOutlined />}
          className={b('dragger', { hidden: fileList.length >= maxLength, error: error !== '' })}
          disabled={disabled}
          beforeUpload={() => false}
        >
          <Tooltip title={tooltip}>
            <div className={b('dragger-body', { error: error !== '' })}>
              { error
                ? (
                  <>
                    <CloseOutlined style={{ color: '#F39A9A', fontSize: '30px' }} />
                    <p className={b('error')}>
                      {error}
                    </p>
                  </>
                )
                : (
                  <>
                    <PaperClipOutlined style={{ color: '#E4E7F2', fontSize: '30px' }} />
                    <p>
                      {text}
                      {' '}
                      <span>{textBlue}</span>
                    </p>
                  </>
                )}
            </div>
          </Tooltip>
        </Dragger>
        { Boolean(imgSrc)
          && (
            <FileCrop
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              onOkModal={onOkModal}
              aspect={aspect}
            />
          )}
      </div>
    );
  }

  return (
    <Dragger
      fileList={fileList}
      multiple={multiple}
      onChange={onChangeHandler}
      showUploadList={showUploadList}
      iconRender={() => <FileImageOutlined />}
      className={b('dragger', { hidden: fileList?.length >= maxLength, error: error !== '' })}
      disabled={disabled}
      beforeUpload={() => false}
      {...rest}
    >
      <Tooltip title={tooltip}>
        <div className={b('dragger-body')}>
          { error
            ? (
              <>
                <CloseOutlined style={{ color: '#F39A9A', fontSize: '30px' }} />
                <p className={b('error')}>
                  {error}
                </p>
              </>
            )
            : (
              <>
                <PaperClipOutlined style={{ color: '#E4E7F2', fontSize: '30px' }} />
                <p>
                  {text}
                  {' '}
                  <span>{textBlue}</span>
                </p>
              </>
            )}
        </div>
      </Tooltip>
    </Dragger>
  );
};

export default DraggerFileUpload;
