import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PartSelector from '../PartSelector';
import './style.less';


export const SortableContent = ({
  fields, form, remove, readOnly, move
}) => {
  const onDragEnd = (result) => {
    move(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sortableParts">
        {(provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((field, index) => (
              <Draggable key={`draggable-${field.key}`} draggableId={`draggable-${field.key}`} index={index}>
                {(providedDraggable) => (
                  <PartSelector
                    field={field}
                    form={form}
                    remove={remove}
                    readOnly={readOnly}
                    providedDraggable={providedDraggable}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
