import React from 'react';
import './style.less';
import bem from 'easy-bem';
import Button from 'components/button-v2';
import ModalV2 from 'components/modal-v2';


const ModalAdminCourse = (
  {
    title,
    isModalVisible,
    onClose,
    onHandleClick,
    icon,
    submitText,
    closeText = 'Отменить',
    contentText,
    ...rest
  }
) => {
  const b = bem('modal-admin-course');

  return (
    <ModalV2
      visible={isModalVisible}
      width="707px"
      title={title}
      onClose={onClose}
      footer={(
        <div className={b('actions')}>
          {closeText && <Button type="primary" ghost onClick={onClose}>{closeText}</Button>}
          {submitText && <Button type="primary" onClick={onHandleClick}>{submitText}</Button>}
        </div>
      )}
      {...rest}
    >
      <div className={b('content')}>
        {icon}
        <p className={b('text')}>
          {contentText}
        </p>
      </div>
    </ModalV2>
  );
};

export default ModalAdminCourse;
