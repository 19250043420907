import React from 'react';
import ModalV2 from 'components/modal-v2';
import TitleText from 'components/modal/modal-review/title-text-component/component';
import bem from 'easy-bem';
import './style.less';
import EducationFormIcon from 'components/education-form-icon/component';


const ModalReviewEducationForm = ({ isVisibleModal, onClose, educationForm }) => {
  const b = bem('modal-review-education-form');

  return (
    <ModalV2
      className={b()}
      title="Добавить новую запись"
      closable={false}
      visible={isVisibleModal}
      onClose={onClose}
      footer=""
    >
      <div className={b()}>
        <div className={b('icon')}><EducationFormIcon icon={educationForm.icon} /></div>
        <TitleText label="Название формата" text={educationForm.title} />
        <TitleText label="Описание формата" text={educationForm.description} />
      </div>
    </ModalV2>
  );
};

export default ModalReviewEducationForm;
