import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import { successMessage } from 'utils/message';
import * as c from './constants';
import { assignmentRole, getUsers, getUsersOneId } from './api';


function* getUsersSaga({ payload }) {
  const { success, failure } = c.getUsers;
  try {
    const users = yield call(getUsers, payload);
    yield put({ type: success, payload: users });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getUsersPartSaga({ payload }) {
  const { success, failure } = c.getUsersPart;
  try {
    const users = yield call(getUsers, payload);
    yield put({ type: success, payload: users });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getContentManagersPartSaga({ payload }) {
  const { success, failure } = c.getContentManagersPart;
  try {
    const users = yield call(getUsers, payload);
    yield put({ type: success, payload: users });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getUsersOneIdSaga({ payload }) {
  const { success, failure } = c.getUsersOneId;
  try {
    const users = yield call(getUsersOneId, payload);
    yield put({ type: success, payload: users });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* handleUnAssignedRoleSaga({ payload }) {
  const { success, failure } = c.unAssignedRole;
  try {
    const users = yield call(assignmentRole, payload);
    yield put({ type: success, payload: users });
    successMessage('Пользователь исключён из списка контент-менеджеров');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getUsers.tries, getUsersSaga);
  yield takeLatest(c.getUsersPart.tries, getUsersPartSaga);
  yield takeLatest(c.getContentManagersPart.tries, getContentManagersPartSaga);
  yield takeLatest(c.getUsersOneId.tries, getUsersOneIdSaga);
  yield takeLatest(c.unAssignedRole.tries, handleUnAssignedRoleSaga);
}
