import React, { useEffect, useState } from 'react';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import { Form, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { inputStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import { editPromo } from 'models/blogAndAdvertising/actions';
import DatePicker from 'components/datepicker';


const ModalEditDate = ({
  onClose,
  isModalVisible,
  promo,
}) => {
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => {
    onClose();
    setEndDate(null);
    setDisabled(true);
  };

  useEffect(() => {
    if (promo) {
      setEndDate(dayjs(promo?.endDate));
    }
  }, [promo]);

  const onSubmit = () => {
    dispatch(editPromo({ id: promo._id, endDate }));
    handleClose();
  };

  const onHandleChange = (date) => {
    if (!date) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setEndDate(date);
  };

  return (
    <ModalV2
      className="modal"
      title="Продление промоакции"
      closable={false}
      visible={isModalVisible}
      onClose={handleClose}
      footer=""
    >
      <Form
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Дата завершения промоакции"
          required
        >
          <DatePicker
            format="DD.MM.YYYY"
            placeholder="Ввод даты"
            style={{ ...inputStyle, width: 210 }}
            disabledDate={(current) => current < dayjs(promo.endDate).subtract(0, 'days')}
            value={endDate}
            onChange={onHandleChange}
          />
        </Form.Item>
        <Divider />
        <Button
          htmlType="submit"
          type="primary"
          disabled={disabled}
        >
          Сохранить
        </Button>
      </Form>
    </ModalV2>
  );
};

export default ModalEditDate;
