import { getUserRole } from 'models/user/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import AdminView from './components/admin-view';
import ContentManagerView from './components/content-manager-view';


const AdminPanel = () => {
  const role = useSelector(getUserRole);
  if (role === 'admin') {
    return <AdminView />;
  }

  return <ContentManagerView />;
};

export default AdminPanel;
