import {
  get as oGet,
  post as oPost,
  remove,
  put as oPut,
  patch as oPatch
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const TEMP_COURSE_MANAGEMENT_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getCourses = (query) => {
  const url = query ? `/courses?${query}` : '/courses';

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const getCoursesBundle = (query) => {
  const url = query ? `/courses-bundle?${query}` : '/courses-bundle';

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const getAdminCourses = (query) => {
  const url = `/admin/courses?${query}`;

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};
export const createCourse = (newCourse) => {
  const url = '/courses';
  const body = JSON.stringify(newCourse);

  return oPost(
    url,
    body,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteCourse = (id) => {
  const url = `/courses/${id}`;

  return remove(url, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const onPublishCourse = (id) => {
  const url = `/courses/${id}/publish`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const onArchiveCourse = (id) => {
  const url = `/courses/${id}/archive`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const unPublishCourse = (id) => {
  const url = `/courses/${id}/unpublish`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const moderateCourse = (id) => {
  const url = `/courses/${id}/moderate`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const moderateRevokeCourse = (id) => {
  const url = `/courses/${id}/moderate-revoke`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const reworkCourse = (id) => {
  const url = `/courses/${id}/rework`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const replicateCourse = (id) => {
  const url = `/courses/${id}/clone`;

  return oPatch(url, '', {}, TEMP_COURSE_MANAGEMENT_API);
};

export const onEditCourse = (id, course) => {
  const url = `/courses/${id}`;
  const body = JSON.stringify(course);

  return oPut(url, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const fetchCourse = (slug) => {
  const url = `/courses/slug/${slug}`;

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};
export const fetchCourseReviews = (id) => {
  const url = `/reviews/courses/${id}`;

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};
export const onUploadImage = (image) => {
  const url = '/courses/image';

  return oPost(url, image, {}, false, TEMP_COURSE_MANAGEMENT_API);
};
export const onUploadSvg = (svg) => {
  const url = '/courses/svg';

  return oPost(url, svg, {}, false, TEMP_COURSE_MANAGEMENT_API);
};
export const onUploadfile = (file) => {
  const url = '/courses/pdf';

  return oPost(url, file, {}, false, TEMP_COURSE_MANAGEMENT_API);
};
export const getSlug = (slug) => {
  const url = `/courses/slug/check/${slug}`;

  return oGet(
    url,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const onCheckDuplicateCourse = (title) => {
  const url = '/courses/title/check';
  const body = JSON.stringify(title);

  return oPost(
    url,
    body,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};
