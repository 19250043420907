import React from 'react';
import { Form, Divider } from 'antd';
import {
  requiredField,
  requiredQuill,
  rulesQuillValidationOne,
  rulesValidationOne
} from 'utils/validation/validation';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';
import bem from 'easy-bem';
import TextEditor from 'components/text-editor';
import './style.less';


const FormQuestion = ({ question, onSubmit }) => {
  const [form] = Form.useForm();
  const b = bem('question-modal');
   
  const disableBtn = () => (!form.isFieldsTouched(true) && !question)
  || form.getFieldsError().filter(({ errors }) => errors.length)
    .length > 0;

  return (
    <Form
      onFinish={(values) => onSubmit(values, form)}
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={question}
    >
      <InputV2
        rules={[requiredField, ...rulesValidationOne]}
        type="form"
        name="text"
        label="Вопрос"
        placeholder="Введите текст"
        required
      />
      <Form.Item
        label="Ответ"
        rules={[requiredQuill, ...rulesQuillValidationOne]}
        name="answer"
        required
      >
        <TextEditor
          size="large"
          autoSize={{ minRows: 1, maxRows: 2 }}
          placeholder="Введите текст"
        />
      </Form.Item>
      <Divider className={b('divider')} />
      <div className={b('footer')}>

        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              htmlType="submit"
              className="buttonSubmit"
              type="primary"
              disabled={disableBtn()}
            >
              Сохранить
            </Button>

          )}

        </Form.Item>
      </div>
    </Form>
  );
};

export default FormQuestion;
