import React from 'react';
import { Table } from 'antd';
import bem from 'easy-bem';

import './style.less';


const TableV2 = (props) => {
  const b = bem('table-v2');

  const { ...rest } = props;

  const locale = {
    triggerDesc: 'Нажмите для сортировки по убыванию',
    triggerAsc: 'Нажмите для сортировки по возрастанию',
    cancelSort: 'Нажмите, чтобы отменить сортировку',
  };

  return (
    <div className={b()}>
      <Table locale={locale} {...rest} />
    </div>
  );
};

export default TableV2;
