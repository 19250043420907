import React, { useState } from 'react';
import { Form, Divider } from 'antd';
import bem from 'easy-bem';
import './style.less';
import { ReactComponent as Widget } from 'assets/icons/education-form-icons/widget-icon.svg';
import { ReactComponent as Notebook } from 'assets/icons/education-form-icons/notebook-icon.svg';
import { ReactComponent as Pen } from 'assets/icons/education-form-icons/pen-icon.svg';
import { ReactComponent as Gift } from 'assets/icons/education-form-icons/gift-icon.svg';
import { ReactComponent as User } from 'assets/icons/education-form-icons/user-add-icon.svg';
import { ReactComponent as Chat } from 'assets/icons/education-form-icons/chat-icon.svg';
import { ReactComponent as Clock } from 'assets/icons/education-form-icons/clock-icon.svg';
import { ReactComponent as Globe } from 'assets/icons/education-form-icons/globe-icon.svg';
import { ReactComponent as Target } from 'assets/icons/education-form-icons/target-icon.svg';
import { ReactComponent as Brush } from 'assets/icons/education-form-icons/brush-icon.svg';
import { ReactComponent as Search } from 'assets/icons/education-form-icons/search-icon.svg';
import { useDispatch } from 'react-redux';
import { createEducationFormat } from 'models/manualControlOfCouses/actions';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';
import { requiredField, rulesValidationOne } from 'utils/validation/validation';


export default function ModalCreateEducationForm({
  isVisibleModal,
  onClose,
  initialValues = false
}) {
  const b = bem('modal-create-education-form');
  const [form] = Form.useForm();
  const [icon, setIcon] = useState('');
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    form.resetFields();
    onClose();
    setIcon('');
  };

  const icons = [
    { title: 'widget', icon: <Widget className={b('icon')} /> },
    { title: 'notebook', icon: <Notebook className={b('icon')} /> },
    { title: 'edit', icon: <Pen className={b('icon')} /> },
    { title: 'gift', icon: <Gift className={b('icon')} /> },
    { title: 'user-add', icon: <User className={b('icon')} /> },
    { title: 'chat', icon: <Chat className={b('icon')} /> },
    { title: 'clock', icon: <Clock className={b('icon')} /> },
    { title: 'globe', icon: <Globe className={b('icon')} /> },
    { title: 'target', icon: <Target className={b('icon')} /> },
    { title: 'brush', icon: <Brush className={b('icon')} /> },
    { title: 'search', icon: <Search className={b('icon')} /> }
  ];
  const onSubmit = () => {
    const educationFormat = form.getFieldsValue();
    dispatch(createEducationFormat(JSON.stringify(educationFormat)));
    onCloseHandler();
  };

  const isEmty = () => (Object.keys(initialValues).length === 0);
  const handleChooseIcon = (title) => {
    setIcon(title);
    form.setFieldValue('icon', title);
  };

  return (
    <ModalV2
      className={b()}
      title="Добавить новую запись"
      closable={false}
      visible={isVisibleModal}
      onClose={onCloseHandler}
      footer=""
    >
      <Form
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={initialValues || {}}
      >
        <Form.Item name="icon">
          <div className={b('icons')}>
            {icons.map((item) => (
              <div
                className={
                  b('icon-wrapper', { active: (icon || (initialValues && initialValues.icon)) === item.title })
                }
                onClick={() => isEmty() && handleChooseIcon(item.title)}
                key={item.title}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                aria-disabled={!isEmty()}
              >
                {item.icon}
                <div className={
                  b('icon-bg', { active: (icon || (initialValues && initialValues.icon)) === item.title })
                }
                />
              </div>
            ))}
          </div>
        </Form.Item>
        <InputV2
          rules={[requiredField, ...rulesValidationOne]}
          type="form"
          name="title"
          label="Название формата"
          placeholder="Введите текст"
          required
          readOnly={!isEmty()}
        />
        <InputV2
          rules={[requiredField, ...rulesValidationOne]}
          type="form"
          name="description"
          label="Описание формата"
          placeholder="Введите текст"
          required
          readOnly={!isEmty()}
        />
        <Divider className={b('divider')} />
        <div className={b('footer')}>
          <Button type="primary" ghost onClick={onCloseHandler}>{isEmty() ? 'Отмена' : 'Закрыть'}</Button>
          { isEmty()
            && (
              <Form.Item shouldUpdate className="submit">
                {() => (
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={
                      !form.isFieldsTouched(['title', 'description'], true)
                    || form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                    || !icon
                    }
                  >
                    Сохранить
                  </Button>

                )}
              </Form.Item>
            )}
        </div>
      </Form>
    </ModalV2>
  );
}
