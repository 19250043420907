import React, { useRef } from 'react';
import './style.less';
import bem from 'easy-bem';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { requiredField } from 'utils/validation/validation';
import { useDebounce } from 'react-use';
import { getSlug } from 'models/courses/api';
import { useDispatch, useSelector } from 'react-redux';
import { setSlug } from 'models/courses/actions';
import { getSlugCourse } from 'models/courses/selectors';
import { errorMessageCatcher } from 'utils/request';
import ModalAddBtn from 'components/modal/modal-course/components/add-more-btn';
import ModalUpload, { inputStyle } from '../modal-upload/component';
import { colors } from './colors';


const { Option } = Select;
const ModalOverview = ({ form }) => {
  const b = bem('overview');
  const g = bem('modal-global-style');
  const dispatch = useDispatch();
  const slug = useSelector(getSlugCourse);
  const prevSlug = useRef(slug);

  const onChangeSlugInput = (e) => {
    const pattern = new RegExp(/^[a-zA-Z0-9-]*$/);
    if (pattern.test(e.target.value) === true) {
      dispatch(setSlug(e.target.value));
    }
  };

  useDebounce(async () => {
    if (prevSlug.current !== slug && slug.length > 0) {
      try {
        const { status } = await getSlug(slug);
        if (status === 'used') {
          form.setFields([
            {
              name: 'slug',
              errors: ['Запись с таким наименованием уже создана'],
            },
          ]);
        }
      } catch (e) {
        errorMessageCatcher(e);
      }
    }
  }, 1000, [slug]);

  return (
    <div className={b()}>
      <Form.List name="videoUrls">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <div key={key} className={b('item')}>
                <Form.Item
                  {...rest}
                  name={name}
                  label="Ссылка на видео"
                >
                  <Input
                    style={{ ...inputStyle, height: 52 }}
                    placeholder="Ccылка вида https://www.youtube.com/embed/xxxxxxxxxx"
                    className={b('info-input')}
                  />
                </Form.Item>
                <div className={g('item-close')}>
                  { fields.length > 1
                    && <PlusOutlined onClick={() => remove(name)} />}
                </div>
              </div>
            ))}
            <Form.Item>
              {fields.length <= 3
                && <ModalAddBtn onAdd={() => add()} />}
            </Form.Item>
          </>
        )}

      </Form.List>
      <Form.Item
        name="slug"
        label="Технический псевдоним"
        rules={[requiredField]}
        className={b('info-input')}
        getValueProps={() => ({ value: slug })}
      >
        <Input
          style={{ ...inputStyle }}
          size="large"
          placeholder="Ввод текста"
          value={slug}
          onChange={onChangeSlugInput}
        />
      </Form.Item>
      <Form.Item
        label="Цвет карточки курса"
        name="cardColor"
        className={b('color-input')}
      >
        <Select
          style={{ ...inputStyle, width: '100%' }}
          size="large"
          placeholder="Выберите цвет"
        >
          {colors.map((item, key) => (
            <Option value={item.title} key={key}>
              <div className={b('option')}>
                <div className={b('color')} style={{ background: `${item.title}` }} />
                {item.title}
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Иконка курса (SVG)"
        name="cardLogoSvg"
        getValueProps={(file) => file}
      >
        <ModalUpload
          crop={false}
          accept=".svg"
          initialFile={form?.getFieldValue('cardLogoSvg')}
        />
      </Form.Item>
    </div>
  );
};

export default ModalOverview;
