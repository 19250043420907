import { Divider } from 'antd';
import bem from 'easy-bem';
import React from 'react';
import { dateTypeText } from 'utils/textOfStatus';
import { formatDate } from 'utils/date';
import ReviewSelector from '../../review-selector-component';
import TitleText from '../../title-text-component';
import './style.less';


const durationUnitsWithPluralForms = [
  {
    value: 'academic_hour',
    pluralForms: 'академический час'
  },
  {
    value: 'hour',
    pluralForms: 'час'
  },
  {
    value: 'day',
    pluralForms: 'день'
  },
  {
    value: 'week',
    pluralForms: 'неделя'
  },
  {
    value: 'month',
    pluralForms: 'месяц',
  },
  {
    value: 'year',
    pluralForms: 'год'
  }
];

const AllInformationReview = ({ initialValues }) => {
  const b = bem('all-information-review');

  const itemPluralForm = durationUnitsWithPluralForms
    .find((item) => item.value === (initialValues?.durationUnit ? initialValues?.durationUnit : 'hour'));
  const durationUnit = itemPluralForm?.pluralForms || [];

  return (
    <div className={b()}>
      <div className={b('info')}>
        <div className={b('aboutCourse')}>
          <TitleText label="Название курса" text={initialValues?.title} />
          <TitleText label="Описание курса" html={initialValues?.description} />
        </div>
        <Divider />
        <div>
          {initialValues.dates.length > 0 ? initialValues.dates.map((item, key) => (
            <div key={key} className={b('dates')}>
              <TitleText label="Дата начала курса" text={formatDate(item.startDate)} />
              <TitleText label="Дата окончания курса" text={formatDate(item.endDate)} />
            </div>
          )) : <TitleText label="Даты проведения курса" text={dateTypeText[initialValues.dateType]} />}
        </div>
        <Divider />
        <div className={b('course-duration')}>
          <TitleText label="Продолжительность курса" text={initialValues?.duration} />
          <TitleText label="Единица измерения" text={durationUnit} />
          {/* eslint-disable-next-line max-len */}
          <TitleText label="Продолжительность курса в академических часах" text={initialValues?.durationAcademicHour} />
        </div>

        <Divider />
        <div className={b('directionEdu')}>
          <ReviewSelector title="Направление обучения" text={initialValues?.educationProfiles} />
          <TitleText label="Тип обучения" text={initialValues?.educationType.title} />
        </div>
        <div className={b('aboutEdu')}>
          <TitleText label="Формат обучения" text={initialValues?.educationType.title} />
          {initialValues?.address && (
            <TitleText
              label="Адрес места проведения"
              text={initialValues?.address || 'нет'}
            />
          )}
          <TitleText label="Уровень сложности" text={initialValues?.level.title} />
          <TitleText label="Результаты обучения" text={initialValues?.educationResult || 'Нет'} />
        </div>
        <div className={b('requirements')}>
          <ReviewSelector title="Критерии оценки" text={initialValues?.evaluationCriteria} />
          <Divider />
          <TitleText label="Специальные требования" text={initialValues?.specialRequirements.join() || 'Нет'} />
        </div>
      </div>
    </div>
  );
};

export default AllInformationReview;


