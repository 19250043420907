/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import bem from 'easy-bem';
import Tag from 'components/tag';
import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { ReactComponent as DragIcon } from 'assets/icons/blog-admin/move-icon.svg';
import { TextPart, ImagePart, QuotePart } from './components';
import './style.less';


const partsComponents = {
  text: {
    label: 'Текст',
    component: TextPart
  },
  image: {
    label: 'Картинка',
    component: ImagePart
  },
  quote: {
    label: 'Цитата',
    component: QuotePart
  }
};

const PartSelector = ({
  field, form, remove, readOnly, providedDraggable
}) => {
  const b = bem('part-selector');
  const value = form.getFieldValue ? form.getFieldValue(['parts', field.name]) : undefined;
  const partType = value?.defaultValue?.type || 'text';
  const SelectedComponent = partsComponents[partType].component || partsComponents.text.component;
  
  return (
    <div
      className={b()}
      style={{ backgroundColor: '#ffffff' }}
      {...providedDraggable.draggableProps}
      ref={providedDraggable.innerRef}
    >
      <div className={b('header')}>
        <div>
          <Tag
            color="secondary"
            bgColor="#0071CE"
            text={partsComponents[partType].label}
          />
        </div>
        <div className={b('drag-container')}>
          <div {...providedDraggable.dragHandleProps}>
            <DragIcon />
          </div>
        </div>
        <div className={b('remove-container')}>
          <DeleteIcon onClick={() => remove(field.name)} />
        </div>
      </div>
      <SelectedComponent field={field} readOnly={readOnly} form={form} />
    </div>
  );
};

export default PartSelector;
