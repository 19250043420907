import * as c from './constants';


export const fetchPartners = () => ({
  type: c.getPartners.tries,
});

export const fetchStatuses = () => ({
  type: c.getStatuses.tries,
});

export const fetchAuthors = () => ({
  type: c.getAuthors.tries,
});

export const fetchBitrixCategory = () => ({
  type: c.getBitrixCategory.tries,
});
