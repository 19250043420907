import React from 'react';
import ModalV2 from 'components/modal-v2';
import { addNewEducationProfile } from 'models/manualControlOfCouses/actions';
import { useDispatch } from 'react-redux';
import FormDirection from '../form/component';


const CreateNewDirectionModal = ({ isModalVisible, onClose }) => {
  const dispatch = useDispatch();
  const onCreateDirection = async (newDirection) => {
    dispatch(addNewEducationProfile(newDirection));
    onClose();
  };

  return (
    <ModalV2
      title="Создать новое направление"
      visible={isModalVisible}
      width={992}
      closable={false}
      footer=""
      tooltipClose="После отмены восстановление заполненных  полей невозможно"
      onClose={onClose}
      destroyOnClose
    >
      <FormDirection
        onSubmitHandler={onCreateDirection}
        onClose={onClose}
      />
    </ModalV2>
  );
};

export default CreateNewDirectionModal;
