import { transformObjectTitlesToIds } from 'utils/transformObjectTitlesToIds';


export const getAppWidth = (state) => state.common.appWidth;
export const getIsMobile = (state) => state.common.isMobile;
export const getIsTablet = (state) => state.common.isTablet;
export const getIsLaptop = (state) => state.common.isLaptop;
export const getFilter = (state) => state.common.filter;
export const getTransformedFilters = (state) => {
  const { filter } = state.common;
  const {
    educationTypes,
    educationProfiles,
    educationLevels,
    tags
  } = state.manual;

  return transformObjectTitlesToIds(filter, {
    educationType: educationTypes,
    educationProfiles,
    levels: educationLevels,
    tags
  });
};
