import * as c from '../constants';


const initialState = {
  tags: { items: [], total: 0 },
  educationTypes: { items: [], total: 0 },
  educationProfiles: { items: [], total: 0 },
  educationLevels: { items: [], total: 0 },
  educationFormats: { items: [], total: 0 },
  evaluationCriteria: { items: [], total: 0 },
  disabledLocationCourse: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.getNecessaryDataToCourse.success: {
      const {
        tags,
        educationTypes,
        educationProfiles,
        educationLevels,
        educationFormats,
        evaluationCriteria
      } = action.payload;

      return {
        ...state,
        tags,
        educationTypes,
        educationProfiles,
        educationLevels,
        educationFormats,
        evaluationCriteria
      };
    }

    case c.onChangeDisabledLocationCourse.success: {
      return { ...state, disabledLocationCourse: action.payload };
    }

    case c.setSlug.success: {
      return { ...state, slug: action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
