import React, { useCallback, useMemo, useState } from 'react';
import { textStatus } from 'utils/textOfStatus';
import { useDispatch, useSelector } from 'react-redux';
import { getEducationProfiles } from 'models/manualControlOfCouses/selectors';
import { changeStatusOfEducationProfile, deleteProfile } from 'models/manualControlOfCouses/actions';
import { deleteEducationProfile, changeStatusOfProfile } from 'models/manualControlOfCouses/api';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { Button, Dropdown } from 'antd';
import Menu from 'components/menu';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { getUserRole } from 'models/user/selectors';
import bem from 'easy-bem';
import './style.less';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import ButtonV2 from '../../../../../button-v2/Button';
import CreateNewDirectionModal from './modal/components/create-new-direction';
import EditDirectionModal from './modal/components/edit-direction-modal';
import ActionModal from '../../../blog-advertising/components/actionModal';


const DirectionsOfStudy = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);
  const b = bem('direction-of-study');

  const [isAddDirection, setIsAddDirection] = useState(false);
  const [isEditDirection, setIsEditDirection] = useState(null);
  const [isDeleteDirection, setIsDeleteDirection] = useState(null);
  const directionsOfStudies = useSelector(getEducationProfiles);
  const sortDirectionsOfStudies = useMemo(() => directionsOfStudies.items
    .sort((a, c) => a.title.localeCompare(c.title))
    .sort((a, c) => a.status.localeCompare(c.status))
    .map((item) => ({ ...item, key: item._id })), [directionsOfStudies]);
  const bagColor = (status) => {
    switch (status) {
      case 'active': return '#73D071';
      case 'draft': return '#BDBDBD';
      default: return 'red';
    }
  };

  const changeStatusDirection = async (value) => {
    const status = (value.status === 'active' ? 'draft' : 'active');
    try {
      const response = await changeStatusOfProfile({ id: value?._id, status });
      dispatch(changeStatusOfEducationProfile(response?._id, status));
      successMessage('Статус изменен');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const deleteDirection = async () => {
    try {
      const response = await deleteEducationProfile(isDeleteDirection);
      dispatch(deleteProfile(response.data._id));
      successMessage('Запись удалена');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const menu = useCallback((value) => {
    const dropdownMenuVersions = (
      <>
        <li>
          <Button type="text" onClick={() => setIsEditDirection(value)}>
            Редактировать
          </Button>
        </li>
        <li>
          {value.status === 'active'
            ? (
              <Button type="text" onClick={() => changeStatusDirection(value)}>
                Деактивировать
              </Button>
            )
            : (
              <Button type="text" onClick={() => changeStatusDirection(value)}>
                Активировать
              </Button>
            )}
        </li>
        <li>
          <Button type="text" className="menu-manual__delete" onClick={() => setIsDeleteDirection(value._id)}>
            Удалить
          </Button>
        </li>
      </>
    );

    return (
      <Menu>
        {dropdownMenuVersions}
      </Menu>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title:
  <span>
    Направление обучения
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'key',
      render: (data, index) => (
        <span key={index.id} style={{ color: bagColor(data) }}>
          {textStatus[data]}
        </span>
      ),
    },
    userRole === 'admin' && {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (index) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(index)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    }
  ];

  return (
    <div className={b()}>
      <GeneralTableTemplate
        searchField="title"
        columns={columns}
        data={sortDirectionsOfStudies}
        total={directionsOfStudies.total}
      >
        <ButtonV2
          className={b('button-add')}
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={() => setIsAddDirection(true)}
        >
          Создать новое направление
        </ButtonV2>
      </GeneralTableTemplate>
      <CreateNewDirectionModal
        isModalVisible={isAddDirection}
        onClose={() => setIsAddDirection(false)}
      />
      <EditDirectionModal
        values={isEditDirection}
        isModalVisible={!!isEditDirection}
        onClose={() => setIsEditDirection(null)}
      />
      <ActionModal
        title="Удалить направление"
        isModalVisible={!!isDeleteDirection}
        contentText="Вы уверены, что хотите удалить направление обучения?"
        submitText="Удалить"
        onSubmit={deleteDirection}
        onClose={() => setIsDeleteDirection(null)}
      />
    </div>
  );
};

export default DirectionsOfStudy;
