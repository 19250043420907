import { BACKEND_HOST } from 'utils/env';
import {
  get as oGet,
} from 'utils/request';


export const API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getReviews = (payload = '') => {
  const url = `/reviews?${payload}`;

  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};
