import CardTag from 'components/card-tag';
import bem from 'easy-bem';
import React from 'react';
import { teacherRole } from 'utils/textOfStatus';
import FileNameArea from '../../file-name-area';
import './style.less';
import TitleText from '../../title-text-component/component';


const TeachersReview = ({ teachers }) => {
  const b = bem('teachers-review');

  return (
    <div className={b()}>
      {teachers.map((item, idx) => (
        <div className={b('teachers-card')} key={idx}>
          <CardTag color="blue" text={item.fullName} />
          <div className={b('teacher-info')}>
            <TitleText label="Должность" text={item.position} />
            <TitleText label="Место работы" text={item.workplace} />
            <TitleText label="Роль на курсе" text={teacherRole[item.role]} />
          </div>
          <TitleText label="Дополнительная информация" html={item.additional || 'нет'} />
          {
            item.avatar && <FileNameArea title={item.avatar} />
          }
        </div>
      )) }
    </div>
  );
};

export default TeachersReview;
