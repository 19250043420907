import React from 'react';
import { ReactComponent as Celebration } from 'assets/icons/celebration-pana-icon.svg';
import ModalAdminCourse from '../component';


const NotificationModal = ({ isModalVisible, onClose }) => (
  <ModalAdminCourse
    title="Курс опубликован"
    visible={isModalVisible}
    width="707px"
    onClose={onClose}
    isModalVisible={isModalVisible}
    onHandleClick={onClose}
    icon={<Celebration />}
    contentText="Онлайн-курс по графическому дизайну (базовый) успешно опубликован!"
    submitText="Отлично!"
    closeText=""
  />
);

export default NotificationModal;
