import { getConstant } from 'utils/constant-factory';


export const getAds = getConstant('GET_ADS');
export const createAd = getConstant('CREATE_AD');
export const editAd = getConstant('EDIT_AD');
export const onPublishAd = getConstant('ON_PUBLISH_AD');
export const deleteAd = getConstant('DELETE_AD');
export const onCopyAd = getConstant('ON_COPY_AD');

export const getPosts = getConstant('GET_POSTS');
export const createPost = getConstant('CREATE_POST');
export const editPost = getConstant('EDIT_POST');
export const onPublishPost = getConstant('ON_PUBLISH_POST');
export const deletePost = getConstant('DELETE_POST');
export const onArchivedPost = getConstant('ON_ARCHIVED_POST');
export const getAdminPromos = getConstant('GET_ADMIN_PROMOS');
export const createPromo = getConstant('CREATE_PROMO');
export const editPromo = getConstant('EDIT_PROMO');
export const onCancelPromo = getConstant('ON_CANCEL_PROMO');
export const getPromosActual = getConstant('GET_PROMOS_ACTUAL');


export const setSlug = getConstant('SET_SLUG');
