import { BACKEND_HOST } from 'utils/env';
import { get as oGet, post as oPost } from '../../utils/request';


export const TEMP_ORDERS_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getOrdersUser = (query = '') => {
  const url = query ? `/profile/courses/orders?${query}` : '/profile/courses/orders';

  return oGet(`${url}`,
    HEADERS, TEMP_ORDERS_API);
};


export const createOrder = (newOrder) => {
  const url = '/orders';

  return oPost(`${url}`,
    newOrder,
    HEADERS,
    false,
    TEMP_ORDERS_API);
};

