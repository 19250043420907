import React from 'react';
import AntIcon from '@ant-design/icons';
import { ReactComponent as Pen } from '../../assets/icons/audience-card/audience-card-icon-1.svg';
import { ReactComponent as Folder } from '../../assets/icons/audience-card/folder-icon.svg';
import { ReactComponent as Box } from '../../assets/icons/audience-card/surprise-box-icon.svg';
import { ReactComponent as Analysis } from '../../assets/icons/audience-card/analysis.svg';
import { ReactComponent as BigData } from '../../assets/icons/audience-card/big-data.svg';
import { ReactComponent as Communication } from '../../assets/icons/audience-card/communication.svg';
import { ReactComponent as Database } from '../../assets/icons/audience-card/database.svg';
import { ReactComponent as Descision } from '../../assets/icons/audience-card/descision-making.svg';
import { ReactComponent as Docs } from '../../assets/icons/audience-card/docs.svg';
import { ReactComponent as Finance } from '../../assets/icons/audience-card/finance.svg';
import { ReactComponent as GoalSetting } from '../../assets/icons/audience-card/goal-setting.svg';
import { ReactComponent as Management } from '../../assets/icons/audience-card/management.svg';
import { ReactComponent as Planning } from '../../assets/icons/audience-card/planning.svg';
import { ReactComponent as Programming } from '../../assets/icons/audience-card/programming.svg';
import { ReactComponent as Security } from '../../assets/icons/audience-card/security.svg';
import { ReactComponent as Teamwork } from '../../assets/icons/audience-card/teamwork.svg';
import { ReactComponent as Testing } from '../../assets/icons/audience-card/testing.svg';


export const competenceIconsList = new Map([
  ['pen', Pen],
  ['folder', Folder],
  ['box', Box],
  ['descision', Descision],
  ['analysis', Analysis],
  ['big-data', BigData],
  ['communication', Communication],
  ['database', Database],
  ['descision', Descision],
  ['docs', Docs],
  ['finance', Finance],
  ['goal-setting', GoalSetting],
  ['management', Management],
  ['planning', Planning],
  ['programming', Programming],
  ['security', Security],
  ['teamwork', Teamwork],
  ['testing', Testing]
]);

export const CompetenceIcon = ({ competenceType = 'pen', className = '' }) => (
  <AntIcon component={competenceIconsList.get(competenceType)} className={className} />
);
