import dayjs from 'dayjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;
const buildTableBody = (data, columns, headerTable) => {
  const body = [headerTable];
  data.forEach((row) => {
    const dataRow = [];
    columns.forEach((column) => {
      dataRow.push({ text: row[column], style: 'td', padding: [16, 16] });
    });
    body.push(dataRow);
  });

  return body;
};

export const writePdf = (title, data, columns, headerTable, widths) => {
  const docDefinition = {
    content: [
      {
        alignment: 'center',
        text: `${title}`,
        style: 'header',
        fontSize: 20,
        color: '#3B4168',
        margin: [0, 50],
      },
      {
        style: 'tableExample',
        layout: {
          vLineColor() {
            return '#ffffff';
          },
          hLineColor() {
            return '#f0f0f0';
          },
        },
        table: {
          headerRows: 1,
          widths,
          style: 'th',
          body: buildTableBody(data, columns, headerTable)
        }
      },
      {
        alignment: 'right',
        text: `${dayjs().format('DD MMMM YYYY')}`,
        style: 'header',
        margin: [0, 50, 0, 0],
      }
    ],
    styles: {
      tableExample: {
        fontSize: 12,
      },
      th: {
        margin: [10, 10],
        color: '#6F7C98',
        overflowWrap: 'break-word',
      },
      td: {
        margin: [10, 10],
        overflowWrap: 'break-word',
      }
    }
  };
  const pdf = pdfMake.createPdf(docDefinition);
  pdf.download(`${title}.pdf`);
};
