import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import { successMessage } from 'utils/message';
import * as c from './constants';
import {
  getTags,
  createTag,
  getEvaluationCriteria,
  createEvaluationCriteria,
  getEducationTypes,
  createEducationType,
  getEducationProfiles,
  getEducationLevels,
  createEducationLevel,
  getEducationFormats,
  createNewEducationProfile,
  editEducationProfile,
  createNewEducationFormat,
  getQuestionsAdmin,
  getQuestions,
  deleteQuestion,
  editQuestion,
  changeStatusOfQuestion,
  getReviewsAdmin,
  createReview,
  deleteReview,
  editReview,
} from './api';


function* getTagsSaga({ payload }) {
  const { success, failure } = c.getTags;
  try {
    const tags = yield call(getTags, payload);
    yield put({ type: success, payload: tags });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createTagSaga({ payload }) {
  const { success, failure } = c.addTag;
  try {
    const tag = yield call(createTag, payload);
    yield put({ type: success, payload: tag });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createEducationTypeSaga({ payload }) {
  const { success, failure } = c.createEducationType;

  try {
    const type = yield call(createEducationType, payload);
    yield put({ type: success, payload: type });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createEducationLevelSaga({ payload }) {
  const { success, failure } = c.createEducationLevel;

  try {
    const type = yield call(createEducationLevel, payload);
    yield put({ type: success, payload: type });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getEvaluationCriteriaSaga({ payload }) {
  const { success, failure } = c.setEvaluationCriteria;
  try {
    const evaluationCriteria = yield call(getEvaluationCriteria, payload);
    yield put({ type: success, payload: evaluationCriteria });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createEvaluationCriteriaSaga({ payload }) {
  const { success, failure } = c.addEvaluationCriteria;
  try {
    const evaluationCriteria = yield call(createEvaluationCriteria, payload);
    yield put({ type: success, payload: evaluationCriteria });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getEducationTypesSaga() {
  const { success, failure } = c.getEducationTypes;
  try {
    const educationTypes = yield call(getEducationTypes);
    yield put({ type: success, payload: educationTypes });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getEducationProfilesSaga() {
  const { success, failure } = c.getEducationProfiles;
  try {
    const educationProfiles = yield call(getEducationProfiles);
    yield put({ type: success, payload: educationProfiles });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createNewEducationProfileSaga({ payload }) {
  const { success, failure } = c.getNewEducationProfile;
  try {
    const educationProfiles = yield call(createNewEducationProfile, payload);
    yield put({ type: success, payload: educationProfiles });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editEducationProfileSaga({ payload }) {
  const { success, failure } = c.editProfile;
  try {
    const profile = yield call(editEducationProfile, payload);
    yield put({ type: success, payload: profile });
    successMessage('Измения сохранены');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getEducationLevelsSaga() {
  const { success, failure } = c.getEducationLevels;
  try {
    const educationLevels = yield call(getEducationLevels);
    yield put({ type: success, payload: educationLevels });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getEducationFormatsSaga({ payload }) {
  const { success, failure } = c.getEducationFormats;
  try {
    const educationFormats = yield call(getEducationFormats, payload);
    yield put({ type: success, payload: educationFormats });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createNewEducationFormatSaga({ payload }) {
  const { success, failure } = c.getNewEducationFormat;
  try {
    const educationFormats = yield call(createNewEducationFormat, payload);
    yield put({ type: success, payload: educationFormats });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getQuestionsAdminSaga({ payload }) {
  const { success, failure } = c.getQuestionsAdmin;
  try {
    const questions = yield call(getQuestionsAdmin, payload);
    yield put({ type: success, payload: questions });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

/* TODO delete simple questions using from here move to question section */
function* getQuestionsSaga({ payload }) {
  const { success, failure } = c.getQuestions;
  try {
    const questions = yield call(getQuestions, payload);
    yield put({ type: success, payload: questions });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* deleteQuestionSaga({ payload }) {
  const { success, failure } = c.deleteQuestion;
  try {
    yield call(deleteQuestion, payload);
    yield put({ type: success, payload });
    successMessage('Вопрос удалён');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editQuestionSaga({ payload }) {
  const { success, failure } = c.editQuestionData;
  try {
    const question = yield call(editQuestion, payload);
    yield put({ type: success, payload: question });
    successMessage('Измения сохранены');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* changeStatusToPublishedQuestionSaga({ payload }) {
  const { success, failure } = c.editQuestion;
  try {
    const status = yield call(changeStatusOfQuestion, payload);
    yield put({ type: success, payload: status });
    successMessage('Вопрос опубликован');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* changeStatusToDraftQuestionSaga({ payload }) {
  const { success, failure } = c.editQuestionToDraft;
  try {
    const status = yield call(changeStatusOfQuestion, payload);
    yield put({ type: success, payload: status });
    successMessage('Вопрос снят с публикации');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getReviewsAdminSaga({ payload }) {
  const { success, failure } = c.getReviewsAdmin;
  try {
    const reviewsAdmin = yield call(getReviewsAdmin, payload);
    yield put({ type: success, payload: reviewsAdmin });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createReviewSaga({ payload }) {
  const { success, failure } = c.addReview;
  try {
    const review = yield call(createReview, payload);
    yield put({ type: success, payload: review });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* deleteReviewSaga({ payload }) {
  const { success, failure } = c.deleteReview;
  try {
    yield call(deleteReview, payload);
    yield put({ type: success, payload });
    successMessage('Отзыв удален.');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editReviewSaga({ payload }) {
  const { success, failure } = c.editReview;
  try {
    const review = yield call(editReview, payload);
    yield put({ type: success, payload: review });
    successMessage('Измения сохранены');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getTags.tries, getTagsSaga);
  yield takeLatest(c.addTag.tries, createTagSaga);
  yield takeLatest(c.setEvaluationCriteria.tries, getEvaluationCriteriaSaga);
  yield takeLatest(c.addEvaluationCriteria.tries, createEvaluationCriteriaSaga);
  yield takeLatest(c.getEducationTypes.tries, getEducationTypesSaga);
  yield takeLatest(c.createEducationType.tries, createEducationTypeSaga);
  yield takeLatest(c.getEducationProfiles.tries, getEducationProfilesSaga);
  yield takeLatest(c.getNewEducationProfile.tries, createNewEducationProfileSaga);
  yield takeLatest(c.editProfile.tries, editEducationProfileSaga);
  yield takeLatest(c.getEducationLevels.tries, getEducationLevelsSaga);
  yield takeLatest(c.createEducationLevel.tries, createEducationLevelSaga);
  yield takeLatest(c.getEducationFormats.tries, getEducationFormatsSaga);
  yield takeLatest(c.getNewEducationFormat.tries, createNewEducationFormatSaga);
  yield takeLatest(c.getQuestionsAdmin.tries, getQuestionsAdminSaga);
  yield takeLatest(c.getQuestions.tries, getQuestionsSaga);
  yield takeLatest(c.deleteQuestion.tries, deleteQuestionSaga);
  yield takeLatest(c.editQuestion.tries, changeStatusToPublishedQuestionSaga);
  yield takeLatest(c.editQuestionToDraft.tries, changeStatusToDraftQuestionSaga);
  yield takeLatest(c.getReviewsAdmin.tries, getReviewsAdminSaga);
  yield takeLatest(c.addReview.tries, createReviewSaga);
  yield takeLatest(c.deleteReview.tries, deleteReviewSaga);
  yield takeLatest(c.editReview.tries, editReviewSaga);
  yield takeLatest(c.editQuestionData.tries, editQuestionSaga);
}

