import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import * as c from './constants';
import {
  getAuthors,
  getPartners,
  getStatuses,
  getBitrixCategory,
} from './api';
  
  
function* getAuthorsSaga() {
  const { success, failure } = c.getAuthors;
  try {
    const authors = yield call(getAuthors);
    yield put({ type: success, payload: authors });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPartnersSaga() {
  const { success, failure } = c.getPartners;
  try {
    const partners = yield call(getPartners);
    yield put({ type: success, payload: partners });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getStatusesSaga() {
  const { success, failure } = c.getStatuses;
  try {
    const statuses = yield call(getStatuses);
    yield put({ type: success, payload: statuses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getBitrixCategorySaga() {
  const { success, failure } = c.getBitrixCategory;
  try {
    const category = yield call(getBitrixCategory);
    yield put({ type: success, payload: category });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}
  
export default function* rootSaga() {
  yield takeLatest(c.getPartners.tries, getPartnersSaga);
  yield takeLatest(c.getAuthors.tries, getAuthorsSaga);
  yield takeLatest(c.getStatuses.tries, getStatusesSaga);
  yield takeLatest(c.getBitrixCategory.tries, getBitrixCategorySaga);
}
