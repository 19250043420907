import React, { useEffect } from 'react';
import bem from 'easy-bem';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'models/user/actions';
import { getLoading } from 'models/user/selectors';
import { setAppWidth } from 'models/common/actions';
import Courses from 'components/courses';
import GlobalLoader from 'components/global-loader';
import { fetchEducationProfiles } from 'models/manualControlOfCouses/actions';


const CoursesPage = () => {
  const b = bem('courses-page');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEducationProfiles());
    dispatch(setUser());
    const handleResize = () => dispatch(setAppWidth(window.innerWidth));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);
  const loading = useSelector(getLoading);

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <div className={b()}>
      <div className={b('content')}>
        <Courses />
      </div>
    </div>
  );
};

export default CoursesPage;
