// @ts-nocheck
import { getFavorites, setFavoritesLoading } from './constants';


const initialState = {
  favorites: { total: -1, items: [] },
  loading: false
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case getFavorites.success: {
      return {
        ...state,
        favorites: action.payload
      };
    }

    case setFavoritesLoading.success: {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
};

export default reducers;
