import React from 'react';
import { Form } from 'antd';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import FormPost from '../form';


const ModalReviewPost = ({
  isVisibleModal, onClose, post
}) => {
  const [form] = Form.useForm();
  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  return (
    <ModalV2
      getContainer={false}
      title="Запись блога"
      closable={false}
      visible={isVisibleModal}
      footer=""
      onClose={closeHandler}
      width={992}
    >
      <FormPost
        form={form}
        onHandleSubmit={closeHandler}
        post={post}
      >
        <Button type="primary" ghost onClick={closeHandler}>Закрыть</Button>
      </FormPost>
    </ModalV2>
  );
};

export default ModalReviewPost;
