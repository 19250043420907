// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import { successMessage } from 'utils/message';
import { getFavorites, addFavorite, removeFavorite } from './api';
import * as c from './constants';


function* getFavoritesSaga(payload) {
  const { success, failure } = c.getFavorites;
  const { success: changeLoadingStatus } = c.setFavoritesLoading;
  yield put({ type: changeLoadingStatus, payload: true });
  try {
    const courses = yield call(getFavorites, payload);
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  } finally {
    yield put({ type: changeLoadingStatus, payload: false });
  }
}

function* addFavoriteSaga({ payload }) {
  const { success, failure } = c.getFavorites;
  try {
    yield call(addFavorite, payload);
    const favorites = yield call(getFavorites, {});
    yield put({ type: success, payload: favorites });
    successMessage('Курс добавлен в Избранное');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* removeFavoriteSaga({ payload }) {
  const { success, failure } = c.getFavorites;
  try {
    yield call(removeFavorite, payload);
    const favorites = yield call(getFavorites, {});
    yield put({ type: success, payload: favorites });
    successMessage('Курс удалён из Избранного');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getFavorites.tries, getFavoritesSaga);
  yield takeLatest(c.setFavorite.tries, addFavoriteSaga);
  yield takeLatest(c.unsetFavorite.tries, removeFavoriteSaga);
}
