import React from 'react';
import { Form } from 'antd';
import { rulesValidationOne } from 'utils/validation/validation';
import TextEditor from 'components/text-editor';


export const TextPart = ({ field, readOnly, subName = 'text' }) => {
  const { name, key, ...rest } = field;
  const modules = {
    toolbar: [
      [
        { list: 'ordered' },
        { list: 'bullet' },
        'clean',
        { align: '' },
        { align: 'center' },
        { align: 'right' },
      ],
      ['bold', 'underline', 'italic'],
    ],
  };
  const formats = [
    'bold',
    'italic',
    'list',
    'bullet',
    'align',
    'underline',
    'clean'
  ];

  return (
    <Form.Item
      {...rest}
      name={[name, subName]}
      label="Введите текст"
      rules={[...rulesValidationOne]}
    >
      <TextEditor
        placeholder="Ввод текста"
        modules={modules}
        formats={formats}
        readOnly={readOnly}
      />
    </Form.Item>
  );
};
