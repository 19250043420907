import Button from 'components/button-v2/Button';
import bem from 'easy-bem';
import React from 'react';
import { Link } from 'react-router-dom';
import './style.less';


const ErrorPage = () => {
  const b = bem('error-page');

  return (
    <div className={b()}>
      <h1>404</h1>
      <h2>Ошибочка вышла</h2>
      <p>
        Данная страница устарела или просто не существует,
        <br />
        перейдите на главную по ссылке ниже
      </p>
      <Button
        type="primary"
        className={b('btn')}
      >
        <Link to="/">На главную</Link>
      </Button>
    </div>
  );
};

export default ErrorPage;
