import React from 'react';
import ModalV2 from 'components/modal-v2';

import { useDispatch } from 'react-redux';
import { createQuestion } from 'models/manualControlOfCouses/api';
import { addQuestion } from 'models/manualControlOfCouses/actions';
import { successMessage } from 'utils/message';
import { onCheckedDuplicate } from 'utils/onCheckDuplicate';
import FormQuestion from '../form/component';


function ModalCreateQuestion({
  onClose, isModalVisible,
}) {
  const dispatch = useDispatch();

  const initialValues = {
    text: '',
    answer: '',
  };


  const onSubmit = async (newQuestion, form) => {
    try {
      const response = await createQuestion(JSON.stringify(newQuestion));
      dispatch(addQuestion(response));
      successMessage('Вопрос сохранён');
      onClose();
    } catch (e) {
      onCheckedDuplicate(form, ['text', 'answer'], e);
    }
  };


  return (
    <ModalV2
      title="Добавить новую запись"
      closable={false}
      visible={isModalVisible}
      onClose={onClose}
      footer=""
      destroyOnClose
    >
      <FormQuestion
        onSubmit={onSubmit}
        question={initialValues}
      />
    </ModalV2>
  );
}

export default ModalCreateQuestion;
