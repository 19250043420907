import React, { useMemo, useState, useCallback } from 'react';
import bem from 'easy-bem';
import { Button, Dropdown } from 'antd';
import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import dayjs from 'dayjs';
import ButtonV2 from 'components/button-v2';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { getPromos } from 'models/blogAndAdvertising/selectors';
import ActionModal from 'components/admin-panel/components/blog-advertising/components/actionModal';
import { cancelPromo, fetchAdminPromos } from 'models/blogAndAdvertising/actions';
import Menu from 'components/menu';
import { formatDate } from 'utils/date';
import { getFullNameUser } from 'utils/get-fullname-user';
import ModalCreatePromo from './components/modal-create-promo';
import ModalPreviewPromo from './components/modal-preview-promo';
import ModalEditDate from './components/modal-edit';


const PromoAdmin = () => {
  const b = bem('promo');
  const promos = useSelector(getPromos);
  const dispatch = useDispatch();
  const [isAddSelection, setIsAddSelection] = useState(false);
  const [isEditPromo, setIsEditPromo] = useState(null);
  const [lookPromo, setLookPromo] = useState(null);
  const [isCompletePromo, setIsCompletePromo] = useState('');
  const onCancelPromo = () => {
    dispatch(cancelPromo(isCompletePromo));
    setIsCompletePromo('');
  };

  const menu = useCallback((value) => (
    <Menu>
      <li>
        <Button type="text" onClick={() => setLookPromo(value)}>
          Посмотреть
        </Button>
      </li>
      <li>
        <Button type="text" onClick={() => setIsEditPromo(value)}>
          Продлить
        </Button>
      </li>
      <li>
        <Button type="text" onClick={() => setIsCompletePromo(value._id)}>
          Удалить промо
        </Button>
      </li>
    </Menu>
  ), []);
  const columns = [
    {
      title:
  <span className={b('table-title')}>
    Курс
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'course',
      key: 'course',
      sorter: (a, d) => a.course?.title?.localeCompare(d.course?.title),
      render: (course) => course.title,
    },
    {
      title:
  <span>
    Автор
  </span>,
      dataIndex: 'userCreator',
      key: 'userCreator',
      render: (user) => user && getFullNameUser(user, true)
    },
    {
      title:
  <span>
    Дата публикации промо
  </span>,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate, index) => (<span key={index.id}>{formatDate(startDate)}</span>)
    },
    {
      title:
  <span>
    Дата завершения промо
  </span>,
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate, index) => (<span key={index.id}>{formatDate(endDate)}</span>)
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];
  const sortPromo = useMemo(() => (promos?.items
    .map((promo) => ({ ...promo, key: promo._id }))
    .sort((a, d) => ((dayjs(d.startDate).isAfter(dayjs(a.startDate))) ? 1 : -1
   || (a.course.title.localeCompare(d.course.title))))), [promos]);

  const handleChangePage = (params) => {
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchAdminPromos(paramsString));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortPromo}
        total={promos.total}
        changePage={handleChangePage}
        paginationWithBack
      >
        <ButtonV2
          type="text"
          className={b('add')}
          icon={<PlusOutlined />}
          onClick={() => setIsAddSelection(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalCreatePromo
        isModalVisible={isAddSelection}
        onClose={() => setIsAddSelection()}
      />
      <ModalEditDate
        isModalVisible={!!isEditPromo}
        onClose={() => setIsEditPromo(null)}
        promo={isEditPromo}
      />
      <ModalPreviewPromo
        values={lookPromo}
        isModalVisible={!!lookPromo}
        onClose={() => setLookPromo(null)}
      />
      <ActionModal
        isModalVisible={!!isCompletePromo}
        contentText="Завершить промоакцию?"
        onClose={() => setIsCompletePromo('')}
        submitText="Завершить"
        title="Завершить промо"
        onSubmit={onCancelPromo}
      />
    </div>
  );
};

export default PromoAdmin;
