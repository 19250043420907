/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import ModalV2 from 'components/modal-v2';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import { onEditCourse } from 'models/courses/api';
import { editCourse, setSlug } from 'models/courses/actions';
import { errorMessageCatcher } from 'utils/request';
import { successMessage } from 'utils/message';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import FormCourse from '../form';


const EditCourseModal = ({
  isModalVisible = false,
  onClose,
  values,
  title = 'Редактирование курса'
}) => {
  const b = bem('modal-create-course');
  const dispatch = useDispatch();
  const editCourseHandle = async (course) => {
    try {
      const response = await onEditCourse(values._id, course);
      dispatch(editCourse(response));
      successMessage('Запись справочника сохранена');
      onClose();
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const getFormatDate = (date) => (date ? dayjs(date) : undefined);

  const initialValues = useMemo(() => {
    const init = cloneDeep(values);
    const getFieldValue = (field) => (init[field]?.length ? init[field] : ['']);
    if (init) {
      init.dates = init.dates.length ? init.dates.map((item) => ({
        startDate: item.startDate && getFormatDate(item.startDate),
        endDate: item.endDate && getFormatDate(item.endDate),
      })) : [];
      init.programs.forEach((field) => {
        if (field.dates) {
          field.dates = [
            dayjs(field.dates.programDateStart),
            dayjs(field.dates.programDateEnd)
          ];
        }

        field.events.forEach((event) => {
          // eslint-disable-next-line no-param-reassign
          if (event.eventDateStart && event.eventDateEnd) {
            event.eventDates = [
              dayjs(event.eventDateStart),
              dayjs(event.eventDateEnd),
            ];
          }
        });
        if (field.events?.length === 0) {
          field.events = [''];
        }
      });
      init.educationProfiles = init.educationProfiles.map((item) => item._id.trim());
      init.educationFormats = init.educationFormats.map((item) => item._id.trim());
      init.educationType = init.educationType?._id;
      init.level = init.level._id;
      init.evaluationCriteria = init.evaluationCriteria.map((item) => item._id.trim());
      init.tags = init.tags.map((item) => item._id);
      init.specialRequirements = getFieldValue('specialRequirements');
      init.competencies = getFieldValue('competencies');
      init.applicants = getFieldValue('applicants');
      init.governmentProgram = getFieldValue('governmentProgram');
      init.videoUrls = getFieldValue('videoUrls');
      init.questions = init.questions.map((item) => item._id.trim());
      dispatch(setSlug(init.slug));
    }

    return init;
  }, [dispatch, values]);

  return (
    <ModalV2
      className={b()}
      title={title}
      visible={isModalVisible}
      width={992}
      closable={false}
      footer=""
      tooltipClose="После отмены восстановление заполненных  полей невозможно"
      onClose={onClose}
      afterClose={() => dispatch(setSlug(''))}
      destroyOnClose
    >
      <FormCourse
        initialValues={initialValues}
        onSubmitHandler={editCourseHandle}
        onClose={onClose}
      />
    </ModalV2>
  );
};

export default EditCourseModal;
