import React from 'react';
import { Provider } from 'react-redux';

import store from 'store/store';
import PageWrapper from '../page-wrapper';

import 'antd/dist/antd.css';
import './style.less';


const App = () => (
  <Provider store={store}>
    <PageWrapper />
  </Provider>
);

export default App;
