import React from 'react';
import bem from 'easy-bem';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import { useDispatch } from 'react-redux';

import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import { onArchiveEvaluationCriteria } from 'models/manualControlOfCouses/actions';
import { onArchiveEvaluationCriteria as archiveCriteria } from 'models/manualControlOfCouses/api';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';

import './styles.less';


const ModalChangeStatus = ({
  isVisibleModal,
  onClose,
  record
}) => {
  const b = bem('modal-change-status');
  const dispatch = useDispatch();
  const onSubmitHandler = async () => {
    const sendRecord = { ...record, status: 'archived' };
    try {
      const response = await archiveCriteria(sendRecord);
      dispatch(onArchiveEvaluationCriteria(response));
      successMessage('Критерий оценки архивирован');
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  return (
    <ModalV2
      className={b('modal')}
      title="Архивировать"
      closable={false}
      visible={isVisibleModal}
      onClose={onClose}
      footer={(
        <div className={b('actions')}>
          <Button type="primary" ghost onClick={onClose}>Отменить</Button>
          <Button type="primary" onClick={onSubmitHandler}>Архивировать</Button>
        </div>
      )}
    >
      <div className={b('image')}>
        <DoneWarning />
        <p className={b('text')}>
          Поместить критерий оценки в архив?
        </p>
      </div>
    </ModalV2>
  );
};

export default ModalChangeStatus;
