import React from 'react';
import bem from 'easy-bem';
import './style.less';
import { ReactComponent as SearchIcon } from 'assets/icons/search/search-icon.svg';
import { ReactComponent as Clear } from 'assets/icons/search/clear-icon.svg';


const Search = ({
  value,
  onInput,
  onClear,
  placeholder,
  className: cn = ''
}) => {
  const b = bem('search-input');

  return (
    <div className={`${b()} ${cn}`}>
      <input
        type="text"
        className={b('input')}
        value={value}
        onChange={onInput}
        placeholder={placeholder}
      />
      { value.length > 0
        && (<Clear onClick={onClear} className={`${b('icon')} ${b('clear')}`} />)}
      <span className={`${b('icon')} ${b('search')}`}><SearchIcon /></span>
    </div>
  );
};

export default Search;
