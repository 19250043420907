import React, { useMemo, useState } from 'react';
import { Dropdown, Button } from 'antd';
import bem from 'easy-bem';
import './style.less';
import dayjs from 'dayjs';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCriterions } from 'models/manualControlOfCouses/selectors';
import ButtonV2 from 'components/button-v2';
import { fetchEvaluationCriteria } from 'models/manualControlOfCouses/actions';
import { textStatus } from 'utils/textOfStatus';
import Menu from 'components/menu';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import ModalAddCriteria from './modal-add-criteria';
import ModalChangeStatus from './modal-change-status';


const textScale = {
  binary: 'Да-нет',
  'ten-point': 'от 1 до 10',
};
const EvaluationCriteria = () => {
  const b = bem('evaluation-criteria');
  const [criterion, setCriterion] = useState(null);
  const [isModalStatus, setIsModalStatus] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const evaluationCriteria = useSelector(getCriterions);
  const dispatch = useDispatch();

  const openModalChangeStatus = (record) => {
    setCriterion(record);
    setIsModalStatus(true);
  };

  const columns = [
    {
      title: <span>Название критерия оценки</span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: <span>Шкала оценки</span>,
      dataIndex: 'scale',
      key: 'scale',
      render: (data) => textScale[data]
    },
    {
      title: <span>Статус</span>,
      dataIndex: 'status',
      key: 'status',
      render: (status, index) => (
        <span className={b('status', { active: status === 'active' })} key={index.id}>
          {textStatus[status]}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (index, record) => (
        record.status === 'active' ? (
          <Dropdown
            key={index}
            className={b('dropdown')}
            overlay={() => (
              <Menu>
                <li>
                  <Button
                    type="text"
                    onClick={() => openModalChangeStatus(record)}
                  >
                    Архивировать
                  </Button>
                </li>
              </Menu>

            )}
          >
            <EllipsisOutlined />
          </Dropdown>
        )
          : null
      )
    },
  ];
  const sortEvaluationCriteria = useMemo(() => {
    const statusActive = evaluationCriteria.items.filter((item) => item.status === 'active')
      .map((item) => ({ ...item, key: item._id }))
      .sort((a, c) => ((dayjs(c.createdAt).isBefore(dayjs(a.createdAt))) ? -1 : 1));

    const statusArchived = evaluationCriteria.items.filter((item) => item.status !== 'active')
      .map((item) => ({ ...item, key: item._id }))
      .sort((a, c) => ((dayjs(c.createdAt).isBefore(dayjs(a.createdAt))) ? -1 : 1));

    return [...statusActive, ...statusArchived];
  }, [evaluationCriteria]);

  const handleChangePage = (params) => {
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchEvaluationCriteria(paramsString));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        data={sortEvaluationCriteria}
        columns={columns}
        total={evaluationCriteria.total}
        paginationWithBack
        changePage={handleChangePage}
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>

      <ModalAddCriteria
        onClose={() => setIsModalAdd(false)}
        isVisibleModal={isModalAdd}
      />
      <ModalChangeStatus
        onClose={() => setIsModalStatus(null)}
        isVisibleModal={isModalStatus}
        record={criterion}
      />
    </div>
  );
};

export default EvaluationCriteria;
