import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserRole, isAuth } from 'models/user/selectors';
import { Redirect, Route } from 'react-router-dom';
import { goToAuth } from 'models/user/actions';
import { warningMessage } from '../../utils/message';


const PrivateRoute = ({ Component, ...rest }) => {
  const isAuthorization = useSelector(isAuth);
  const role = useSelector(getUserRole);
  const userRoles = ['admin', 'content-manager'];

  const dispatch = useDispatch();
  if (!isAuthorization) {
    dispatch(goToAuth());

    return <Redirect to="/" />;
  }

  if (isAuthorization && !userRoles.includes(role)) {
    warningMessage('У вас нет прав');

    return <Redirect to="/" />;
  }

  return (
    <Route {...rest} component={Component} />
  );
};

export default PrivateRoute;
