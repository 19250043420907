import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bem from 'easy-bem';
import dayjs from 'dayjs';
import { Dropdown, Button } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';

import ButtonV2 from 'components/button-v2';
import { getReviewsAdmin } from 'models/manualControlOfCouses/selectors';
import { deleteReview, fetchReviewsAdmin } from 'models/manualControlOfCouses/actions';
import ActionModal from 'components/admin-panel/components/blog-advertising/components/actionModal';
import Menu from 'components/menu';
import { formatDate } from 'utils/date';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { getFullNameUser } from 'utils/get-fullname-user';
import { queryStringify } from 'utils/queryStringify';

import ModalToPreviewReview from './modalToPreviewReview';
import ModalToCreateReview from './modalToCreateReview';
import './style.less';
import ModalToEditReview from './modalToEditReview/component';


const Reviews = () => {
  const b = bem('reviews');
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [lookReview, setLookReview] = useState(null);
  const [isDeleteSelection, setIsDeleteSelection] = useState('');
  const [editReview, setEditReview] = useState(null);
  const reviews = useSelector(getReviewsAdmin);
  const dispatch = useDispatch();
  const handleChangePage = (params) => {
    const paramsString = queryStringify(params);
    dispatch(fetchReviewsAdmin(paramsString));
  };

  const onDelete = () => {
    dispatch(deleteReview(isDeleteSelection));
    setIsDeleteSelection('');
  };

  const reviewsWithKey = useMemo(() => reviews.items
    .map((item) => ({ ...item, key: item._id }))
    .sort((a, d) => ((dayjs(d.createdAt).isAfter(dayjs(a.createdAt))) ? 1 : -1)), [reviews]);

  const menu = useCallback((value) => (
    <Menu>
      <li>
        <Button
          type="text"
          onClick={() => setLookReview(value)}
        >
          Посмотреть
        </Button>
      </li>
      <li>
        <Button
          type="text"
          onClick={() => setEditReview(value)}
        >
          Редактировать
        </Button>
      </li>
      <li>
        <Button
          className="menu-manual__delete"
          type="text"
          onClick={() => setIsDeleteSelection(value._id)}
        >
          Удалить
        </Button>
      </li>
    </Menu>
  ), []);
  const columns = [
    {
      title: <span>Название курса</span>,
      dataIndex: 'courseTitle',
      key: 'courseTitle',
      render: (_text, { course }) => course.title
    },
    {
      title: <span>Дата отзыва</span>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: formatDate
    },
    {
      title: <span>Фамилия, имя автора</span>,
      dataIndex: 'author',
      key: 'author',
      render: (_text, record) => (`${record.authorSurname} ${record.authorName}`)
    },
    {
      title: <span>Кем внесен</span>,
      dataIndex: 'userCreator',
      key: 'editor',
      render: (user) => getFullNameUser(user, true)
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown className={b('dropdown')} overlay={menu(record)}>
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={b()}>
      <GeneralTableTemplate
        data={reviewsWithKey}
        columns={columns}
        total={reviews.total}
        changePage={handleChangePage}
        paginationWithBack
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalToCreateReview
        isModalVisible={isModalAdd}
        onClose={() => setIsModalAdd(null)}
        title="Добавить новый отзыв"
      />
      <ModalToPreviewReview
        values={lookReview}
        isModalVisible={!!lookReview}
        onClose={() => setLookReview(null)}
      />
      <ModalToEditReview
        review={editReview}
        isVisible={!!editReview}
        onClose={() => setEditReview(null)}
      />
      <ActionModal
        isModalVisible={!!isDeleteSelection}
        contentText="Отзыв будет удалён из справочника и с главной страницы. Удалить?"
        onClose={() => setIsDeleteSelection('')}
        submitText="Удалить"
        title="Удаление отзыва"
        onSubmit={onDelete}
      />

    </div>
  );
};

export default Reviews;
