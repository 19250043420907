// @ts-nocheck
import { get as oGet, post as oPost, remove } from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


const API = `${BACKEND_HOST}/api/v1`;
const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getFavorites = ({ payload }) => {
  const url = `/profile/courses/favorites?${payload || ''}`;

  return oGet(`${url}`, {}, API);
};

export const addFavorite = (courseId) => {
  const url = '/favorites';
  const body = JSON.stringify({
    courses: [courseId]
  });

  return oPost(
    `${url}`,
    body,
    HEADERS,
    false,
    API
  );
};

export const removeFavorite = (courseId) => {
  const url = `/courses/${courseId}/favorites`;

  return remove(url, null, HEADERS, API);
};
