import React from 'react';
import bem from 'easy-bem';
import TitleText from '../../title-text-component/component';
import FileNameArea from '../../file-name-area';
import './style.less';


const ModalOverviewReview = ({ overview }) => {
  const b = bem('overview-modal');

  if (!overview) {
    return null;
  }

  return (
    <div className={b()}>
      { overview.videoUrls?.length > 0
        ? overview.videoUrls?.map((item, idx) => (<TitleText label="Ссылка на видео" link={item} key={idx} />))
        : <TitleText label="Ссылка на видео" text="Нет" />}

      <TitleText label="Технический псевдоним" text={overview.slug} />
      { overview.cardColor && <TitleText label="Цвет карточки курсов" text={overview?.cardColor} />}
      { overview.cardLogoSvg && <FileNameArea title={overview?.cardLogoSvg} /> }
    </div>
  );
};

export default ModalOverviewReview;
