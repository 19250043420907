import React from 'react';
import dayjs from 'dayjs';
import { Divider } from 'antd';

import TitleText from 'components/modal/modal-review/title-text-component';
import ModalV2 from 'components/modal-v2';
import FileNameArea from 'components/modal/modal-review/file-name-area';


const ModalToPreviewReview = ({ values, isModalVisible = false, onClose }) => (
  <ModalV2
    visible={isModalVisible}
    getContainer={false}
    closable={false}
    title="Отзыв"
    footer=""
    onClose={onClose}
  >
    <TitleText
      label="Название курса"
      text={values?.course.title}
    />
    <Divider />
    <TitleText
      label="Фамилия автора"
      text={values?.authorSurname}
    />
    <TitleText
      label="Имя автора"
      text={values?.authorName}
    />
    <Divider />
    <TitleText
      label="Отзыв"
      text={values?.text}
    />
    <TitleText
      label="Дата отзыва"
      text={dayjs(values?.createdAt).format('DD.MM.YYYY')}
    />
    <Divider />
    <TitleText label="Фото автора" />
    {
      (values?.authorAvatar && <FileNameArea title={values.authorAvatar} />) || 'Не загружен'
    }
  </ModalV2>
);

export default ModalToPreviewReview;
