// @ts-nocheck
import { getFavorites, setFavorite, unsetFavorite } from './constants';


export const fetchFavorites = (params) => ({
  type: getFavorites.tries,
  payload: params || null
});
export const addFavorite = (courseId) => ({
  type: setFavorite.tries,
  payload: courseId
});
export const removeFavorite = (courseId) => ({
  type: unsetFavorite.tries,
  payload: courseId
});
