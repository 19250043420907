import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { errorMessageCatcher } from 'utils/request';
import { successMessage } from 'utils/message';
import { onEditCourse } from 'models/courses/api';
import { editCourse } from 'models/courses/actions';


const Priority = ({ course }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(course?.sortPriority);

  const onSubmit = async () => {
    if (inputValue === null) {
      setInputValue(course?.sortPriority);

      return;
    }

    if (course?.sortPriority !== inputValue) {
      try {
        const response = await onEditCourse(course?._id, {
          sortPriority: inputValue
        });
        dispatch(editCourse(response));
        successMessage('Запись справочника сохранена');
      } catch (e) {
        errorMessageCatcher(e);
      }
    }
  };


  return (
    <InputNumber
      minLength={0}
      min={0}
      type="number"
      defaultValue={course?.sortPriority}
      value={inputValue}
      onChange={(value) => setInputValue(value)}
      onBlur={() => onSubmit()}
    />
  );
};

export default Priority;
