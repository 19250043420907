import React from 'react';
import './style.less';
import bem from 'easy-bem';
import { Select, Tag, Form } from 'antd';


const { Option } = Select;

export function tagRender(props) {
  const {
    label, closable, onClose
  } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="#F0F7FC"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ padding: '4px 16px', color: '#70A4CF', fontSize: '14px' }}
    >
      {label}
    </Tag>
  );
}

const ModalSelect = ({
  options,
  style,
  name,
  validation,
  label,
  disabled
}) => {
  const b = bem('modal-select-input');

  return (
    <div className={b()}>
      <Form.Item
        label={label}
        name={name}
        rules={validation}
      >
        <Select
          mode="multiple"
          showArrow
          tagRender={tagRender}
          style={{ ...style, height: 'auto', borderRadius: '4px' }}
          placeholder="Введите данные"
          size="large"
          disabled={disabled}
        >
          {options.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default ModalSelect;
