import React from 'react';
import './style.less';
import bem from 'easy-bem';
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Tag from 'components/tag';
import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/modal-copy.svg';
import {
  requiredField,
  requiredQuill,
  rulesQuillValidationOne,
  rulesValidationOne
} from 'utils/validation/validation';
import TextEditor from 'components/text-editor';
import ModalAddBtn from '../../../add-more-btn';
import ModalUpload from '../modal-upload';
import { inputStyle, selectStyle } from '../modal-upload/component';


const ModalPartners = ({ form }) => {
  const b = bem('modal-partners');
  const g = bem('modal-global-style');

  const copyBlock = (add, index) => {
    const copyData = form.getFieldsValue('partners').partners[index];
    add(copyData);
  };

  return (
    <div className={b()}>
      <Form.List name="partners">
        {(fields, { add, remove }) => (
          <>
            <div className={g('container')}>
              {fields.map(({ key, name, ...rest }, index) => (
                <div key={key} className={g('item')}>
                  <div className={g('header')}>
                    <Tag
                      color="secondary"
                      bgColor="#0071CE"
                      text={`Партнер #${index + 1}`}
                    />
                    <div className={g('header-drag')}>
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                    </div>
                    <div className={g('geader-icons')}>
                      <CopyIcon className={g('icon')} onClick={() => copyBlock(add, name)} />
                      <DeleteIcon className={g('icon')} onClick={() => remove(name)} />
                    </div>
                  </div>
                  <div className={b('head-inputs')}>
                    <div className={b('inputs-left')}>
                      <Form.Item
                        {...rest}
                        label="Наименование партнера"
                        rules={[...rulesValidationOne, requiredField]}
                        name={[name, 'title']}
                      >
                        <TextArea
                          style={{ ...selectStyle, height: 52 }}
                          size="large"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          placeholder="Ввод текста"
                          className={b('info-input')}
                        />
                      </Form.Item>
                      <Form.Item
                        {...rest}
                        label="Ссылка"
                        name={[name, 'url']}
                      >
                        <Input
                          style={{ ...inputStyle, height: 47 }}
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          placeholder="Ввод ссылки"
                          className={b('info-input')}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      {...rest}
                      label="Описание партнера"
                      rules={[...rulesQuillValidationOne, requiredQuill]}
                      name={[name, 'description']}
                    >
                      <TextEditor
                        style={{ ...selectStyle, height: 'auto', padding: 0 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                  </div>
                  <div className={b('inputs-block')}>
                    <Form.Item
                      name={[name, 'logo']}
                      className={b('file')}
                      getValueProps={(file) => file}
                    >
                      <ModalUpload
                        initialFile={form.getFieldValue('partners')[name].logo}
                        aspect={386 / 226}
                      />
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
            <div className={b('add-more-btn')}>
              <ModalAddBtn text="Добавить ещё партнера" onAdd={() => add({ logo: '' })} />
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default React.memo(ModalPartners);
