import * as c from '../constants';


const initialState = {
  courses: { total: 0, items: [] },
  publishedCourses: { items: [], total: 0 },
  archivedCourses: { items: [], total: 0 },
  coursesBundle: { items: [], total: 0 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.getAdminCourses.success: {
      return { ...state, courses: action.payload };
    }

    case c.getPublishedCourses.success: {
      return { ...state, publishedCourses: action.payload };
    }

    case c.getCoursesBundle.success: {
      return { ...state, coursesBundle: action.payload };
    }

    case c.getPublishedAdminCourses.success: {
      return { ...state, publishedCourses: action.payload };
    }

    case c.getArchivedCourses.success: {
      return { ...state, archivedCourses: action.payload };
    }

    case c.deleteCourse.success: {
      return {
        ...state,
        courses: {
          items: state.courses.items.filter((course) => course._id !== action.payload),
          total: parseInt(state.courses.total, 10) - 1,
        }
      };
    }

    case c.addCourse.success: {
      return {
        ...state,
        courses: {
          total: parseInt(state.courses.total, 10) + 1,
          items: [...state.courses.items, action.payload],
        }
      };
    }

    case c.onPublishCourse.success: {
      const items = state.courses.items
        .map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, status: 'published' };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        },
        publishedCourses: {
          items: [...state.publishedCourses.items, action.payload],
          total: parseInt(state.courses.total, 10) + 1
        }
      };
    }

    case c.unPublishCourse.success: {
      const items = state.courses.items
        .map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, status: 'draft' };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        },
        publishedCourses: {
          items: [...state.publishedCourses.items, action.payload],
          total: parseInt(state.courses.total, 10) - 1
        }
      };
    }

    case c.onArchiveCourse.success: {
      const { _id } = action.payload;

      return {
        ...state,
        courses: {
          items: state.courses.items.filter((item) => item._id !== _id),
          total: parseInt(state.courses.total, 10) - 1,
        },
        archivedCourses: {
          items: [...state.archivedCourses.items, action.payload],
          total: parseInt(state.courses.total, 10) + 1,
        },
        publishedCourses: {
          items: state.publishedCourses.items.filter((course) => course._id !== action.payload._id),
          total: parseInt(state.courses.total, 10) - 1
        }
      };
    }

    case c.editCourse.success: {
      const items = state.courses.items
        .map((item) => {
          const { _id: id, datePublication } = action.payload;
          if (item._id === id) {
            return { ...item, ...action.payload, datePublication };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        }
      };
    }

    case c.moderateCourse.success: {
      const items = state.courses.items
        .map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, status: 'moderate' };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        }
      };
    }

    case c.reworkCourse.success: {
      const items = state.courses.items
        .map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, status: 'rework' };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        },
      };
    }

    case c.moderateRevokeCourse.success: {
      const items = state.courses.items
        .map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, status: 'draft' };
          }

          return item;
        });

      return {
        ...state,
        courses: {
          ...state.courses,
          items
        },
      };
    }

    case c.replicateCourse.success: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
};

export default reducer;
