import React from 'react';
import './style.less';
import bem from 'easy-bem';
import { PlusOutlined } from '@ant-design/icons';
import ButtonV2 from 'components/button-v2';


const ModalAddBtn = ({ text = 'добавить ещё', onAdd, disabled }) => {
  const b = bem('modal-create-course-add-btn');

  return (
    <ButtonV2
      type="text"
      className={b()}
      icon={<PlusOutlined />}
      onClick={onAdd}
      disabled={disabled}
    >
      {text}
    </ButtonV2>
  );
};

export default ModalAddBtn;
