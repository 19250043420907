import React, { useState, useEffect } from 'react';
import bem from 'easy-bem';

import Search from 'components/search-input';
import Table from 'components/table';
import { useDebounce } from 'react-use';
import PaginationV2 from 'components/pagination-v2';

import './style.less';


const GeneralTableTemplate = ({
  data,
  columns = {},
  searchField = '',
  searchFieldSecond = '',
  children,
  placeholder = 'Поиск по названию',
  paginationWithBack = false,
  changePage,
  ms = 500,
  total = 0,
}) => {
  const [filter, setFilter] = useState([]);
  const [filteredManual, setFilteredManual] = useState(data);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [current, setCurrent] = useState(1);
  const b = bem('general-table-template');

  const DataCallback = (event) => {
    if (filter.length !== event.length) {
      setFilter(event);
    }
  };

  const more = () => {
    if (pageSize + pageSize <= total) {
      setPageSize(pageSize + pageSize);
    } else {
      setPageSize(total);
    }
  };

  const onChangePage = (page, perPage) => {
    if (perPage !== pageSize) {
      setPageSize(perPage);
      setCurrent(1);
    } else {
      setCurrent(page);
    }
  };

  useDebounce(
    () => {
      if (paginationWithBack) {
        const params = {
          page: current,
          limit: pageSize,
          title: search.trim(),
        };
        changePage(params);
      } else {
        const filterArray = data.filter((elem) => {
          const first = `${elem[searchField]}`;
          const second = `${elem[searchFieldSecond]}`;

          return ((
            first.toLocaleLowerCase().indexOf(search.toLocaleLowerCase().trim()) !== -1)
            || (second.toLocaleLowerCase().indexOf(search.toLocaleLowerCase().trim()) !== -1));
        });
        setFilteredManual(filterArray);
      }
    },
    ms,
    [search, current, pageSize]
  );

  useEffect(() => {
    setFilter(filteredManual);
  }, [filteredManual, searchField, searchFieldSecond, search]);

  useEffect(() => {
    setFilteredManual(data);
  }, [data]);

  if (!filter) {
    return 'Загрузка...';
  }

  return (
    <div className={b('')}>
      <div className={b('search-panel')}>
        <Search
          placeholder={placeholder}
          onInput={(e) => setSearch(e.target.value)}
          value={search}
          onClear={() => setSearch('')}
        />
        {children}
      </div>
      <Table
        className={b('table')}
        dataSource={paginationWithBack ? data : filter}
        columns={columns}
        pagination={false}
        showSorterTooltip={false}
      />
      <PaginationV2
        total={total}
        defaultPageSize={total}
        data={paginationWithBack ? data : filteredManual}
        setDataCallback={DataCallback}
        showLeftTotal
        hideOnSinglePage={false}
        more={more}
        currentPage={current}
        onChangePage={onChangePage}
        pageSize={pageSize}
      />
    </div>
  );
};

export default GeneralTableTemplate;
