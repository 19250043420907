import * as c from '../constants';


const initialState = {
  name: undefined,
  surname: undefined,
  fullName: undefined,
  birthDay: undefined,
  email: undefined,
  role: undefined,
  photoUrl: '',
  geo: undefined,
  moderation: undefined,
  work: undefined,
  phone: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.getUser.success: {
      const {
        _id,
        email,
        idAuth,
        name,
        patronymic,
        role,
        status,
        surname,
        updatedAt,
        moderation,
        geo,
        birthday,
        work,
        phone,
      } = action.payload;

      return {
        ...initialState,
        _id,
        surname,
        name,
        idAuth,
        fullName: `${surname} ${name} ${patronymic}`,
        birthDay: `${birthday.day} ${birthday.month} ${birthday.year}`,
        patronymic,
        email,
        status,
        updatedAt,
        role,
        moderation,
        geo,
        work,
        phone
      };
    }

    default:
      return state;
  }
};

export default reducer;
