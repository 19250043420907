import { CheckOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { message } from 'antd';


export const successMessage = (text = '', ms = 4) => {
  message.success({
    content: text,
    duration: ms,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <CheckOutlined />
  });
};

export const errorMessages = (text = '', ms = 4) => {
  message.error({
    content: text,
    duration: ms,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <CloseOutlined style={{ color: '#fff' }} />
  });
};

export const warningMessage = (text = '', ms = 4) => {
  message.warning({
    content: text,
    duration: ms,
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <WarningOutlined style={{ color: '#fff' }} />
  });
};
