import { call, put, takeLatest } from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import * as c from './constants';
import { getOrdersUser } from './api';


function* getOrdersUserSaga() {
  const { success, failure } = c.getOrdersUser;
  const { success: changeLoading } = c.changeUserOrdersLoading;
  yield put({ type: changeLoading, payload: true });
  try {
    const orders = yield call(getOrdersUser);
    yield put({ type: success, payload: orders });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  } finally {
    yield put({ type: changeLoading, payload: false });
  }
}

function* getOrdersUserPieceSaga({ payload }) {
  const { success, failure } = c.getOrdersUserPiece;
  try {
    const orders = yield call(getOrdersUser, payload);
    yield put({ type: success, payload: orders });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}


export default function* rootSaga() {
  yield takeLatest(c.getOrdersUser.tries, getOrdersUserSaga);
  yield takeLatest(c.getOrdersUserPiece.tries, getOrdersUserPieceSaga);
}
