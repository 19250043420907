import React, { FC } from 'react';
import { Result } from 'antd';
import bem from 'easy-bem';
import './style.less';
import underConstructionImg from 'assets/images/under-construction.svg';


export const UnderConstruction: FC = () => {
  const b = bem('under-construction');

  return (
    <Result
      className={b()}
      icon={<img src={underConstructionImg} alt="no-access" />}
      title={<div className={b('title')}>Данный функционал находится на стадии разработки</div>}
    />
  );
};
