import * as c from './constants';


const initialState = {
  users: { total: 0, items: [] },
  partOfUsers: { total: 0, items: [] },
  partOfContentManagers: { total: 0, items: [] },
  usersOneId: { total: 0, items: [] }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getUsers.success: {
      return { ...state, users: action.payload };
    }

    case c.getUsersPart.success: {
      return { ...state, partOfUsers: action.payload };
    }

    case c.getContentManagersPart.success: {
      return { ...state, partOfContentManagers: action.payload };
    }

    case c.getUsersOneId.success: {
      return { ...state, usersOneId: action.payload };
    }

    case c.unAssignedRole.success: {
      return {
        ...state,
        partOfContentManagers: {
          items: state.partOfContentManagers.items.filter((user) => user.id !== action.payload.id),
          total: parseInt(state.partOfContentManagers.total, 10) - 1,
        }
      };
    }

    default:
      return state;
  }
};

export default reducers;
