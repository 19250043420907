import * as c from './constants';


export const fetchAdminCourse = (params) => ({
  type: c.getAdminCourses.tries,
  payload: params,
});

export const addCourse = (course) => ({
  type: c.addCourse.success,
  payload: course
});

export const deleteCourse = (id) => ({
  type: c.deleteCourse.tries,
  payload: id
});

export const onArchiveCourse = (id) => ({
  type: c.onArchiveCourse.tries,
  payload: id,
});

export const unPublishCourse = (id) => ({
  type: c.unPublishCourse.success,
  payload: id,
});

export const onPublishCourse = (id) => ({
  type: c.onPublishCourse.success,
  payload: id,
});

export const moderateCourse = (id) => ({
  type: c.moderateCourse.success,
  payload: id,
});

export const moderateRevokeCourse = (id) => ({
  type: c.moderateRevokeCourse.success,
  payload: id,
});

export const reworkCourse = (id) => ({
  type: c.reworkCourse.success,
  payload: id,
});

export const replicateCourse = (id) => ({
  type: c.replicateCourse.success,
  payload: id,
});

export const editCourse = (course) => ({
  type: c.editCourse.success,
  payload: course,
});

export const fetchAllDataToCourse = (query) => ({
  type: c.getNecessaryDataToCourse.tries,
  payload: query
});

export const onChangeDisabledLocationCourse = (payload) => ({
  type: c.onChangeDisabledLocationCourse.success,
  payload
});

export const fetchCoursesBundle = (payload) => ({
  type: c.getCoursesBundle.tries,
  payload
});

export const fetchPublishedCourses = (query) => ({
  type: c.getPublishedCourses.tries,
  payload: query
});

export const fetchPublishedAdminCourses = () => ({
  type: c.getPublishedAdminCourses.tries,
});

export const setSlug = (slug) => ({
  type: c.setSlug.success,
  payload: slug
});

export const fetchArchivedCourses = (query) => ({
  type: c.getArchivedCourses.tries,
  payload: query,
});
