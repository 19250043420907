import React from 'react';
import bem from 'easy-bem';
import './style.less';
import { ReactComponent as ADIcon } from 'assets/icons/blog-admin/advertising-icon.svg';
import { ReactComponent as PromoIcon } from 'assets/icons/blog-admin/promo-icon.svg';
import { ReactComponent as BlogIcon } from 'assets/icons/blog-admin/blog-icon.svg';
import WrapperTabsAdmin from '../wrapper-tabs-admin';
import PromoAdmin from './components/promo';
import Advertising from './components/advertising';
import Blogs from './components/blog/component';


const BlogAndAdvertising = () => {
  const b = bem('blog-advertising');
  const tabsItems = [
    {
      label:
  <span className={b('tab')}>
    <ADIcon className={b('icon')} />
    Реклама
  </span>,
      key: '1',
      component: <Advertising />
    },
    {
      label:
  <span className={b('tab')}>
    <PromoIcon className={b('icon')} />
    Промо
  </span>,
      key: '2',
      component: <PromoAdmin />
    },
    {
      label:
  <span className={b('tab')}>
    <BlogIcon className={b('icon')} />
    Блог
  </span>,
      key: '3',
      component: <Blogs />,
    },
  ];

  return (
    <div className={b()}>
      <WrapperTabsAdmin tabsItems={tabsItems} title="Блог и реклама" />
    </div>
  );
};

export default BlogAndAdvertising;
