import React, { useMemo, useState, useCallback } from 'react';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import bem from 'easy-bem';
import DOMPurify from 'dompurify';
import { getQuestionsAdmin } from 'models/manualControlOfCouses/selectors';
import {
  deleteQuestion,
  fetchQuestionsAdmin,
  changeStatusToPublishedQuestion,
  changeStatusToDraftQuestion
} from 'models/manualControlOfCouses/actions';
import { useDispatch, useSelector } from 'react-redux';
import ActionModal from 'components/admin-panel/components/blog-advertising/components/actionModal';
import { queryStringify } from 'utils/queryStringify';
import ButtonV2 from 'components/button-v2';
import { textStatus } from 'utils/textOfStatus';
import Menu from 'components/menu';
import sortBy from 'lodash/sortBy';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import './style.less';
import ModalCreateQuestion from './components/modalCreateQuestion';
import ModalQuestionReview from './components/ModalQuestionReview';
import ModalToEditQuestion from './components/modalEditQuestion/component';


const Questions = () => {
  const b = bem('questions');
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [lookQuestion, setLookQuestion] = useState(null);
  const [isDeleteSelection, setIsDeleteSelection] = useState('');
  const [editQuestion, setEditQuestion] = useState(null);
  const [isPublication, setIsPublication] = useState('');
  const [isDraft, setDraft] = useState('');
  const dispatch = useDispatch();
  const questions = useSelector(getQuestionsAdmin);

  const getStatus = (status) => status === 'published';

  const menu = useCallback((value) => (
    <Menu className={b('menu')}>
      <li>
        <Button
          type="text"
          onClick={() => setEditQuestion(value)}
        >
          Редактировать
        </Button>
      </li>
      {value.status === 'published' ? (
        <>
          <li>
            <Button
              type="text"
              onClick={() => setLookQuestion(value)}
            >
              Посмотреть
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setDraft(value._id)}>
              Снять с публикации
            </Button>
          </li>
        </>
      ) : (
        <>
          <li>
            <Button
              type="text"
              onClick={() => setIsPublication(value._id)}
            >
              Опубликовать
            </Button>
          </li>
          <li>
            <Button
              type="text"
              onClick={() => setLookQuestion(value)}
            >
              Посмотреть
            </Button>
          </li>
          <li>
            <Button
              type="text"
              onClick={() => setIsDeleteSelection(value._id)}
              className="menu-manual__delete"
            >
              Удалить
            </Button>
          </li>
        </>
      )}
    </Menu>
  ), [b]);

  const columns = [
    {
      title: <span>ID вопроса</span>,
      dataIndex: 'simpleId',
      key: 'simpleId',
    },
    {
      title: <span>Текст вопроса</span>,
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: <span>Ответ</span>,
      dataIndex: 'answer',
      key: 'answer',
      render: (value) => (
        <span
          className="position-inside"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
        />
      )
    },
    {
      title: <span>Статус</span>,
      dataIndex: 'status',
      key: 'status',
      render: (value, index) => (
        <span
          className={b('status', { active: getStatus(value) })}
          key={index.id}
        >
          {textStatus[value] || ''}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown className={b('dropdown')} overlay={menu(record)}>
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];
  const sortedQuestions = useMemo(() => {
    const arrQuestions = questions.items.map((question) => ({ ...question, key: question._id }));

    return (sortBy(arrQuestions, ['status', 'updatedAt']).reverse());
  },
  [questions]);

  const handleChangePage = (params) => {
    const query = { text: params.title, ...params };
    delete query.title;
    const paramsString = queryStringify(query);
    dispatch(fetchQuestionsAdmin(paramsString));
  };


  const onDelete = () => {
    dispatch(deleteQuestion(isDeleteSelection));
    setIsDeleteSelection('');
  };

  const changeStatusToPublished = () => {
    dispatch(changeStatusToPublishedQuestion({ id: isPublication, status: 'published' }));
  };

  const changeStatusToDraft = () => {
    dispatch(changeStatusToDraftQuestion({ id: isDraft, status: 'draft' }));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        data={sortedQuestions}
        columns={columns}
        total={questions.total}
        changePage={handleChangePage}
        paginationWithBack
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalCreateQuestion
        isModalVisible={isModalAdd}
        onClose={() => setIsModalAdd(null)}
      />
      <ModalQuestionReview
        values={lookQuestion}
        isModalVisible={!!lookQuestion}
        onClose={() => setLookQuestion(false)}
      />
      <ModalToEditQuestion
        values={editQuestion}
        isModalVisible={!!editQuestion}
        onClose={() => setEditQuestion(null)}
      />
      <ActionModal
        isModalVisible={!!isDeleteSelection}
        contentText="Удалить запись справочника?"
        onClose={() => setIsDeleteSelection('')}
        submitText="Удалить"
        title="Удаление вопроса"
        onSubmit={onDelete}
      />
      <ActionModal
        isModalVisible={!!isPublication}
        contentText="Опубликованный вопрос автоматически появится во всех карточках курса. Опубликовать вопрос?"
        onClose={() => setIsPublication('')}
        submitText="Опубликовать"
        title="Публикация вопроса"
        onSubmit={changeStatusToPublished}
      />
      <ActionModal
        isModalVisible={!!isDraft}
        contentText="После снятия с публикации вопрос будет исключён из всех опубликованных курсов. Снять с публикации?"
        onClose={() => setDraft('')}
        submitText="Снять с публикации"
        title="Снять с публикации"
        onSubmit={changeStatusToDraft}
      />
    </div>
  );
};

export default Questions;
