// @ts-nocheck
import { get as oGet } from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


const API = `${BACKEND_HOST}/api/v1`;
const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };


export const getAuthors = () => {
  const url = '/admin/courses/authors';
  
  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};


export const getStatuses = () => {
  const url = '/admin/courses/statuses';
    
  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};

export const getPartners = () => {
  const url = '/admin/courses/partners-title';
    
  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};

export const getBitrixCategory = () => {
  const url = '/admin/courses/bitrix/category';

  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};
