import { FileImageOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import bem from 'easy-bem';
import './style.less';
import React from 'react';
import FileCrop from 'components/file-crop';


const FileUpload = ({
  fileList,
  onChangeHandler,
  text,
  error,
  showUploadList,
  tooltip,
  disabled,
  imgSrc,
  setImgSrc,
  onOkModal,
  aspect,
  multiple = false,
  ...rest
}) => {
  const b = bem('special-file-upload');

  return (
    <div className={b()}>
      <Upload
        fileList={fileList}
        multiple={multiple}
        onChange={onChangeHandler}
        showUploadList={showUploadList}
        iconRender={() => <FileImageOutlined />}
        disabled={disabled}
        beforeUpload={() => false}
        {...rest}
      >
        <div className={b('upload-text')}>
          <p>
            {text}
            {' '}
          </p>
          <Button
            icon={<PaperClipOutlined />}
            className={b('add-file', { delete: fileList.length > 0 })}
          >
            Прикрепить файл
          </Button>
        </div>
        {error && <p className={b('error')}>{error}</p>}
      </Upload>
      { Boolean(imgSrc)
        && (
          <FileCrop
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
            onOkModal={onOkModal}
            aspect={aspect}
          />
        )}
    </div>
  );
};

export default FileUpload;
