import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import * as c from './constants';
import {
  getReviews,
} from './api';


function* getReviewsSaga() {
  const { success, failure } = c.getReviews;
  try {
    const reviews = yield call(getReviews);
    yield put({ type: success, payload: reviews });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getReviews.tries, getReviewsSaga);
}
