import * as c from './constants';


const initialState = {
  contentManagerReport: null,
  contentManagerName: '',
  courseReport: null,
  loading: false
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getContentManagerReport.success: {
      const { courses, contentManager } = action.payload;
      const { surname, name, patronymic } = contentManager;

      return {
        ...state,
        contentManagerReport: courses,
        contentManagerName: `${surname} ${name} ${patronymic}`
      };
    }

    case c.getCourseReport.success: {
      return { ...state, courseReport: action.payload };
    }

    case c.setLoadingReport.success: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducers;

