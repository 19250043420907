import React from 'react';
import AntIcon from '@ant-design/icons';
import { ReactComponent as Widget } from 'assets/icons/education-form-icons/widget-icon.svg';
import { ReactComponent as Notebook } from 'assets/icons/education-form-icons/notebook-icon.svg';
import { ReactComponent as Pen } from 'assets/icons/education-form-icons/pen-icon.svg';
import { ReactComponent as Gift } from 'assets/icons/education-form-icons/gift-icon.svg';
import { ReactComponent as User } from 'assets/icons/education-form-icons/user-add-icon.svg';
import { ReactComponent as Chat } from 'assets/icons/education-form-icons/chat-icon.svg';
import { ReactComponent as Clock } from 'assets/icons/education-form-icons/clock-icon.svg';
import { ReactComponent as Globe } from 'assets/icons/education-form-icons/globe-icon.svg';
import { ReactComponent as Target } from 'assets/icons/education-form-icons/target-icon.svg';
import { ReactComponent as Brush } from 'assets/icons/education-form-icons/brush-icon.svg';
import { ReactComponent as Search } from 'assets/icons/education-form-icons/search-icon.svg';


const iconTypes = {
  widget: Widget,
  notebook: Notebook,
  edit: Pen,
  gift: Gift,
  'user-add': User,
  chat: Chat,
  clock: Clock,
  globe: Globe,
  target: Target,
  brush: Brush,
  search: Search,
  'no-icon': 'no-icon'
};


const EducationFormIcon = ({ icon = 'default', className = '' }) => {
  const Component = iconTypes[icon];

  return <AntIcon component={Component} className={className} />;
};

export default EducationFormIcon;
