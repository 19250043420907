export const getCourses = (state) => state.courses.courses.courses;
export const getTags = (state) => state.courses.data.tags.items;
export const getEducationTypes = (state) => state.courses.data.educationTypes.items;
export const getEducationProfiles = (state) => state.courses.data.educationProfiles.items;
export const getEducationLevels = (state) => state.courses.data.educationLevels.items;
export const getEducationFormats = (state) => state.courses.data.educationFormats.items;
export const getEvaluationCriteria = (state) => state.courses.data.evaluationCriteria.items;

export const isDisabledLocationCourse = (state) => state.courses.data.disabledLocationCourse;
export const getPublishedCourses = (state) => state.courses.courses.publishedCourses;
export const getArchivedCourses = (state) => state.courses.courses.archivedCourses;
export const getSlugCourse = (state) => state.courses.data.slug;
export const getCoursesBundle = (state) => state.courses.courses.coursesBundle;
