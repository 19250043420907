import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import bem from 'easy-bem';
import { getUsersTotal } from 'models/users/selectors';
import './style.less';


const WrapperTabsAdmin = ({
  title = 'Unknown', tabsItems = [], showCount = false, onChangeTab = {}
}) => {
  const usersTotalCount = useSelector(getUsersTotal);
  const b = bem('wrapper-tabs');
  const { TabPane } = Tabs;

  return (
    <div className={b()}>
      <div className={b('title')}>
        <h1>{title}</h1>
        {showCount && (
          <span>
            Всего пользователей:
            {' '}
            {usersTotalCount}
          </span>
        )}
      </div>
      <Tabs defaultActiveKey="1" onChange={onChangeTab}>
        {tabsItems.map(({
          label, component, key, icon, ...rest
        }) => (
          <TabPane
            tab={(
              <span className={b('tabs')}>
                { icon }
                { label }
              </span>
            )}
            key={key}
            {...rest}
          >
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default WrapperTabsAdmin;
