import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import ModalAdminCourse from '../component';
import { onArchiveCourse } from '../../../../../../../models/courses/actions';


const DeleteCourseModal = ({ isModalVisible, onClose, id }) => {
  const dispatch = useDispatch();
  const onHandleClick = () => {
    dispatch(onArchiveCourse(id));
    onClose();
  };

  return (
    <ModalAdminCourse
      title="Архивирование курса"
      visible={isModalVisible}
      width="707px"
      onClose={onClose}
      isModalVisible={isModalVisible}
      onHandleClick={onHandleClick}
      icon={<DoneWarning />}
      contentText="Вы уверены, что хотите архивировать курс?"
      submitText="Архивировать"
    />
  );
};

export default DeleteCourseModal;
