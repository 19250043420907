import React from 'react';
import { Form } from 'antd';
import FileDragger from 'components/dragger';


export const ImagePart = ({ field, readOnly, form }) => {
  const { name } = field;

  return (
    <Form.Item
      label=""
      name={[name, 'image']}
    >
      <FileDragger
        accept=".png, .jpeg, .jpg"
        form={form}
        initialFile={form.getFieldValue([name, 'image'])}
        fileType={['png', 'jpeg']}
        crop
        disabled={readOnly}
        maxLength={1}
        aspect={180 / 234}
        multiple
      />
    </Form.Item>
  );
};
