import {
  get as oGet,
  post as oPost,
  put as oPut,
  patch as oPatch,
  remove
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const TEMP_ADS_MANAGEMENT_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getAds = (query = '') => {
  const url = `/banners?${query}`;

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const createAd = (newAd) => {
  const url = '/banners';

  return oPost(
    url,
    newAd,
    HEADERS,
    false,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const editAd = ({ id, advertising }) => {
  const url = `/banners/${id}`;
  const body = JSON.stringify(advertising);

  return oPut(url, body, HEADERS, false, TEMP_ADS_MANAGEMENT_API);
};

export const onPublishAd = (id) => {
  const url = `/banners/${id}/publish`;

  return oPatch(url, '', {}, TEMP_ADS_MANAGEMENT_API);
};

export const deleteAd = (id) => {
  const url = `/banners/${id}`;

  return remove(url, null, {}, TEMP_ADS_MANAGEMENT_API);
};

export const onCopyAd = (advertising) => {
  const url = '/banners';

  return oPost(
    url,
    advertising,
    HEADERS,
    false,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const getPosts = (query) => {
  const url = query ? `/posts?${query}` : '/posts';

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};
export const getPost = (id) => {
  const url = `/posts/${id}`;

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};
export const createPost = (newPost) => {
  const url = '/posts';

  return oPost(
    url,
    newPost,
    HEADERS,
    false,
    TEMP_ADS_MANAGEMENT_API
  );
};
export const editPost = ({ id, post }) => {
  const url = `/posts/${id}`;

  return oPut(
    url,
    post,
    HEADERS,
    false,
    TEMP_ADS_MANAGEMENT_API
  );
};
export const onPublishPost = (id) => {
  const url = `/posts/${id}/publish`;

  return oPatch(url, '', {}, TEMP_ADS_MANAGEMENT_API);
};
export const onArchivedPost = (id) => {
  const url = `/posts/${id}/archive`;

  return oPatch(url, '', {}, TEMP_ADS_MANAGEMENT_API);
};

export const deletePost = (id) => {
  const url = `/posts/${id}`;

  return remove(url, null, {}, TEMP_ADS_MANAGEMENT_API);
};
export const getAdminPromos = (query = '') => {
  const url = `/admin/promos?${query}`;

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const getPromosActual = (query = '') => {
  const url = `/promos?${query}`;

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const createPromo = (newPromo) => {
  const url = '/promos';

  return oPost(
    url,
    newPromo,
    HEADERS,
    false,
    TEMP_ADS_MANAGEMENT_API
  );
};

export const editPromo = ({ id, endDate }) => {
  const url = `/promos/${id}`;
  const body = JSON.stringify({ endDate });

  return oPut(url, body, HEADERS, false, TEMP_ADS_MANAGEMENT_API);
};

export const onCancelPromo = (id) => {
  const url = `/promos/${id}`;

  return remove(url, null, {}, TEMP_ADS_MANAGEMENT_API);
};

export const getSlug = (slug) => {
  const url = `/posts/slug/check/${slug}`;

  return oGet(
    url,
    HEADERS,
    TEMP_ADS_MANAGEMENT_API
  );
};
