import React, { useState, useCallback } from 'react';
import bem from 'easy-bem';
import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import ButtonV2 from 'components/button-v2';
import dayjs from 'dayjs';
import './style.less';
import { useSelector, useDispatch } from 'react-redux';
import { getAds } from 'models/blogAndAdvertising/selectors';
import {
  deletingAd,
  changeStatusToPublishedAd,
  fetchAds
} from 'models/blogAndAdvertising/actions';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { textStatus } from 'utils/textOfStatus';
import Menu from 'components/menu';
import ActionModal from '../actionModal';
import CreateAdModal from './modal/modalCreateAd';
import ModalAdsCopy from './modal/modalCopyAd';
import ModalReviewAd from './modal/modalReview';
import ModalEditAd from './modal/modalEdit';


const Advertising = () => {
  const b = bem('advertising');
  const ads = useSelector(getAds);
  const [isAddSelection, setIsAddSelection] = useState(false);
  const [isEditModal, setIsEditModal] = useState(null);
  const [isReviewModal, setIsReviewModal] = useState(null);
  const [isDeleteModalAd, setIsDeleteModalAd] = useState('');
  const [isPublication, setIsPublication] = useState('');
  const [isCopyModal, setIsCopyModal] = useState(null);
  const dispatch = useDispatch();

  const getStatus = (status) => status === 'published';
  const menu = useCallback((value) => {
    const dropdownMenuVersions = {
      draft:
        (
          <>
            <li>
              <Button type="text" onClick={() => setIsEditModal(value)}>
                Редактировать
              </Button>
            </li>
            <li>
              <Button type="text" onClick={() => setIsPublication(value._id)}>
                Опубликовать
              </Button>
            </li>
            <li>
              <Button className="menu-manual__delete" type="text" onClick={() => setIsDeleteModalAd(value._id)}>
                Удалить
              </Button>
            </li>
            <li>
              <Button type="text" onClick={() => setIsCopyModal(value)}>
                Копировать
              </Button>
            </li>
          </>
        ),
      published: (
        <>
          <li>
            <Button type="text" onClick={() => setIsReviewModal(value)}>
              Посмотреть
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsCopyModal(value)}>
              Копировать
            </Button>
          </li>
        </>
      ),
    };

    return (
      <Menu>
        {// @ts-ignore
          !dropdownMenuVersions[value.status] ? null : dropdownMenuVersions[value.status]
        }
      </Menu>
    );
  }, []);
  const columns = [
    {
      title:
  <span className={b('table-title')}>
    Название подборки
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Дата создания/обновления
  </span>,
      dataIndex: '',
      key: 'y',
      render: (_, record) => (
        <span>
          { `${dayjs(record.createdAt).format('DD.MM.YYYY')}/${dayjs(record.updatedAt).format('DD.MM.YYYY')}`}
        </span>
      ),
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <span className={b('status', { active: getStatus(value) })}>
          {textStatus[value]}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];

  const onDeleteAD = () => {
    dispatch(deletingAd(isDeleteModalAd));
  };

  const handlePublishAd = () => {
    dispatch(changeStatusToPublishedAd(isPublication));
    dispatch(fetchAds());
  };

  const handleChangePage = (params) => {
    const newParams = {
      ...params,
      sort: '-status',
    };
    const paramsString = new URLSearchParams(newParams).toString();
    dispatch(fetchAds(paramsString));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        searchField="title"
        data={ads.items}
        total={ads.total}
        changePage={handleChangePage}
        paginationWithBack
      >
        <ButtonV2
          className={b('button-add')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsAddSelection(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <CreateAdModal
        isVisible={isAddSelection}
        onClose={() => setIsAddSelection(false)}
      />
      <ModalAdsCopy
        isVisible={!!isCopyModal}
        onClose={() => setIsCopyModal(null)}
        value={isCopyModal}
      />
      <ModalReviewAd
        isVisible={!!isReviewModal}
        onClose={() => setIsReviewModal(null)}
        ad={isReviewModal}
      />
      <ModalEditAd
        isVisible={!!isEditModal}
        onClose={() => setIsEditModal(null)}
        ad={isEditModal}
      />
      <ActionModal
        isModalVisible={!!isDeleteModalAd}
        contentText="Удалить запись справочника?"
        onClose={() => setIsDeleteModalAd(false)}
        submitText="Удалить"
        title="Удаление подборки"
        onSubmit={onDeleteAD}
      />
      <ActionModal
        isModalVisible={!!isPublication}
        contentText="Вы уверены что хотите опубликовать подборку на главную страницу, заменив текущую?"
        onClose={() => setIsPublication('')}
        submitText="Опубликовать"
        title="Публикация подборки"
        onSubmit={handlePublishAd}
      />
    </div>
  );
};

export default Advertising;
