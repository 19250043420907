import React from 'react';
import Button from 'components/button-v2';
import ModalV2 from 'components/modal-v2';
import bem from 'easy-bem';
import './style.less';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';


const ActionModal = ({
  isModalVisible,
  title,
  onClose,
  contentText,
  submitText,
  onSubmit
}) => {
  const b = bem('action-modal');
  const onHandleClick = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={b()}>
      <ModalV2
        visible={isModalVisible}
        width="707px"
        title={title}
        onClose={onClose}
        footer={(
          <div className={b('actions')}>
            <Button type="primary" ghost onClick={onClose}>Отменить</Button>
            <Button type="primary" onClick={onHandleClick}>{submitText}</Button>
          </div>
        )}
      >
        <div className={b('content')}>
          <DoneWarning />
          <p className={b('text')}>
            {contentText}
          </p>
        </div>
      </ModalV2>
    </div>
  );
};


export default ActionModal;
