import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { textStatus } from 'utils/textOfStatus';
import { getEducationTypes } from 'models/manualControlOfCouses/selectors';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { Button, Dropdown } from 'antd';
import ButtonV2 from 'components/button-v2';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import './style.less';
import Menu from 'components/menu';
import ModalEducationType from './modalEducationType';


const EducationType = () => {
  const b = bem('educationTypes');
  const educationTypes = useSelector(getEducationTypes);
  const [isModalDelete, setIsModalDelete] = useState(null);
  const [isModalAdd, setIsModalAdd] = useState(false);

  const sortedEducationTypes = useMemo(() => educationTypes.items
    .sort((a, c) => a.title.localeCompare(c.title))
    .sort((a, c) => a.status.localeCompare(c.status))
    .map((item) => ({ ...item, key: item._id })), [educationTypes]);

  const bagColor = (status) => {
    switch (status) {
      case 'active': return '#73D071';
      case 'archived': return '#BDBDBD';
      default: return 'red';
    }
  };

  const columns = [
    {
      title:
  <span>
    Тип обучения
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'key',
      render: (data, index) => (
        <span key={index.id} style={{ color: bagColor(data) }}>
          {textStatus[data]}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={() => (
            <Menu>
              <li>
                <Button type="text" className="menu-manual__delete" onClick={() => setIsModalDelete(record._id)}>
                  Удалить
                </Button>
              </li>
            </Menu>
          )}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    }
  ];

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortedEducationTypes}
        searchField="title"
        total={educationTypes.total}
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalEducationType
        key={1}
        isDelete="true"
        id={isModalDelete}
        isVisibleModal={isModalDelete}
        onClose={() => setIsModalDelete(null)}
        title="Удалить запись"
      />
      <ModalEducationType
        key={2}
        isDelete={false}
        id={isModalAdd}
        isVisibleModal={isModalAdd}
        onClose={() => setIsModalAdd(null)}
        title="Добавить новую запись"
      />
    </div>
  );
};

export default EducationType;
