import React from 'react';
import bem from 'easy-bem';
import { getContentManagerName, getContentManagerReport } from 'models/reports/selectors';
import { useSelector } from 'react-redux';
import './style.less';
import { writePdf } from 'utils/pdf/writePdf';
import ReportModal from 'components/modal/report-modal';


const Report = ({ isModalVisible, onCloseModal }) => {
  const b = bem('content-manager-report');
  const report = useSelector(getContentManagerReport);
  const name = useSelector(getContentManagerName);
  const reportColumns = [
    {
      title:
  <span className={b('table-title')}>
    Название курса
  </span>,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title:
  <span className={b('table-title')}>
    Дата размещения
  </span>,
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'status',
      render: (status, course) => <span className={b('status', { [status]: true })}>{course.statusText}</span>
    },
    {
      title:
  <span>
    Количество слушателей
  </span>,
      dataIndex: 'countOfConsiderationOrders',
      key: 'countOfConsiderationOrders',
    },
  ];
  const handleLoad = () => writePdf(
    name,
    report,
    ['title', 'createdAt', 'statusText', 'countOfConsiderationOrders'],
    [
      { text: 'Название курса', style: 'th' },
      { text: 'Дата размещения', style: 'th' },
      { text: 'Статус', style: 'th' },
      { text: 'Количество слушателей', style: 'th' }
    ],
    ['40%', 'auto', 'auto', 'auto']
  );

  return (
    <ReportModal
      modalsVisible={isModalVisible}
      onClose={onCloseModal}
      report={report}
      text={name}
      columns={reportColumns}
      onLoadPdf={handleLoad}
    />
  );
};

export default Report;
