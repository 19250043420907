import * as c from './constants';


const initialState = {
  orders: { total: 0, items: [] },
  userOrders: { total: 0, items: [] },
  userOrdersLoading: false,
  userOrdersPiece: { total: 0, items: [] },
};
  
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getOrdersUser.success: {
      return { ...state, userOrders: action.payload, };
    }

    case c.getOrdersUserPiece.success: {
      return { ...state, userOrdersPiece: action.payload, };
    }

    case c.changeUserOrdersLoading.success: {
      return { ...state, userOrdersLoading: action.payload, };
    }

    case c.addOrderUser.success: {
      return {
        ...state,
        userOrders: {
          total: parseInt(state.userOrders.total, 10) + 1,
          items: [...state.userOrders.items, action.payload]
        }
      };
    }

    default:
      return state;
  }
};
  
export default reducers;
