import React from 'react';
import {
  Button, DatePicker, Divider, Form, Input, Modal, TimePicker
} from 'antd';
import './style.less';
import bem from 'easy-bem';
import { CloseOutlined } from '@ant-design/icons';


const CreateMailingListModal = ({ modalsVisible, setModalsVisible }) => {
  const b = bem('create-mailing-list-modal');
  const [form] = Form.useForm();

  const { TextArea } = Input;

  return (
    <Modal
      centered
      visible={modalsVisible.createMailingListModal}
      footer={false}
      closable={false}
    >
      <div className={b('container')}>
        <div className={b('title')}>
          <h4>Создание рассылки</h4>
          <CloseOutlined onClick={() => setModalsVisible({
            reportModal: false,
            removeRoleModal: false,
            questionModal: false,
            createMailingListModal: false,
          })}
          />
        </div>
        <Divider />
        <Form
          form={form}
          //  onFinish={onFinish}
          className={b('create-promo-modal')}
          layout="vertical"
        >
          <Form.Item
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 10 }}
            label={<span className={b('label-form')}>Заголовок рассылки</span>}
            name="heading"
            rules={[{ required: true, message: 'Заполните поле' }, { min: 6, message: 'Минимум 6 символов' }]}
          >
            <Input placeholder="Укажите тему письма" />
          </Form.Item>
          <div className={b('pickers')}>
            <Form.Item
              label={<span className={b('label-form')}>Запланировать рассылку на</span>}
              name="register"
              rules={[{ required: true, message: 'Заполните поле', }]}
              labelCol={{ span: 25 }}
            >
              <DatePicker placeholder="17.05.2022" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label={<span className={b('label-form')}>Время отправки (МСК)</span>}
              name="time"
              rules={[{ required: true, message: 'Заполните поле', }]}
              labelCol={{ span: 25 }}
            >
              <TimePicker placeholder="ЧЧ:ММ" style={{ width: '100%' }} />
            </Form.Item>
          </div>
          <Form.Item
            label={<span className={b('label-form')}>Адресаты</span>}
            name="address"
            rules={[{ required: true, message: 'Заполните поле', }]}
          />
          <Form.Item
            label={<span className={b('label-form')}>Текст рассылки</span>}
            name="article"
            rules={[{ required: true, message: 'Заполните поле', }]}
          >
            <TextArea />
          </Form.Item>
          <div className={b('form-buttons')}>
            <Button className={b('save-black')}>
              Сохранить как черновик
            </Button>
            <Button
              htmlType="submit"
              className={b('save-btn')}
            >
              Сохранить
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateMailingListModal;
