import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.locale('ru');
dayjs.extend(customParseFormat);

export const today = dayjs();

export const formatDate = (rawDate: string) => {
  if (!rawDate) return '';

  return dayjs(rawDate).format('DD.MM.YYYY');
};

export const formatDateToISO = (date: string) => dayjs(date).toISOString();

export const formatDateWithMonth = (rawDate: string) => {
  if (!rawDate) return '';

  return dayjs(rawDate).format('DD MMMM YYYY');
};


type delimiterTypes = 'by-month' | 'by-day' | null;

export const formatDateWithMonthLocale = (rawDate: string, delimiter?: delimiterTypes) => {
  if (!rawDate) return '';

  const parsedDate = dayjs(rawDate);

  if (delimiter === 'by-month') {
    return parsedDate.format('DD MMMM');
  }

  if (delimiter === 'by-day') {
    return parsedDate.format('DD');
  }

  return parsedDate.format('DD MMMM YYYY');
};

const findMonthDifference = (startDate: string, endDate:string): number => {
  const start = dayjs(startDate, 'DD.MM.YYYY');
  const end = dayjs(endDate, 'DD.MM.YYYY');

  return dayjs(end).diff(start, 'month');
};

export const getCourseBannerDate = (startDate: string[], endDate: string[], fallbackText: string): string => {
  if (!startDate || !endDate) return fallbackText;

  const [start] = startDate;
  const [end] = endDate;

  const diff = findMonthDifference(start, end);

  const from = diff > 0
    ? formatDateWithMonthLocale(start, 'by-month')
    : formatDateWithMonthLocale(start, 'by-day');

  const to = formatDateWithMonthLocale(end);

  return `${from} - ${to}`;
};

interface ITypeDates {
    [key: string ]: string
    start: string,
    end: string,
}
export const dateFormatString = (dates: ITypeDates, start = 'startDate', end = 'endDate') => {
  let showDates: string;
  const startDate = dates[start];
  const endDate = dates[end];
  const isSameDate = dayjs(startDate).isSame(endDate, 'day');
  const isEqualYear = dayjs(startDate).isSame(endDate, 'year');
  const isEqualMonth = dayjs(startDate).isSame(endDate, 'month');
  if (!dates[end] || isSameDate) {
    showDates = dayjs(startDate).format('DD MMMM YYYY');
  } else if (isEqualMonth && isEqualYear) {
    showDates = `${dayjs(startDate).format('DD')} - ${dayjs(endDate).format('DD MMMM YYYY')}`;
  } else if (isEqualYear) {
    showDates = `${dayjs(startDate).format('DD MMMM')} - ${dayjs(endDate).format('DD MMMM YYYY')}`;
  } else {
    showDates = `${dayjs(startDate).format('DD MMMM YYYY')} - ${dayjs(endDate).format('DD MMMM YYYY')}`;
  }

  return showDates;
};


export const courseDates = (dates: ITypeDates[]): string | null => {
  if (dates.length > 0) {
    const actualDate = dates.reduce((acc, item) => {
      const rangeAcc = today.diff(acc.startDate);
      const rangeItem = today.diff(item.startDate);
      if (Math.abs(rangeAcc) < Math.abs(rangeItem)) {
        return acc;
      }

      return item;
    });

    return dateFormatString(actualDate);
  }

  return null;
};
