import * as actions from './actions';
import * as constants from './constants';
import reducers from './reducers';
import options from './options';
import * as selectors from './selectors';
import saga from './saga';
import * as api from './api';


const model = {
  actions,
  constants,
  reducers,
  options,
  selectors,
  api,
  saga
};

export default model;
