import { UnderConstruction } from 'components/under-construction';
import React from 'react';
import { Tabs } from 'antd';
import bem from 'easy-bem';
import ContentManagerCourses from './components/content-manager-courses/component';
import './style.less';


const menuItems = [
  { label: 'Профиль', key: '1', component: <UnderConstruction /> },
  { label: 'Мои курсы', key: '2', component: <ContentManagerCourses /> },
  { label: 'Партнеры', key: '3', component: <UnderConstruction /> },
];

const ContentManagerView = () => {
  const { TabPane } = Tabs;
  const b = bem('content-manager-view');

  return (
    <div className={b()}>
      <Tabs defaultActiveKey="2" className={b('list')}>
        {menuItems.map((item) => (
          <TabPane tab={<span className={b('main-tabs')}>{item.label}</span>} key={item.key}>
            {item.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ContentManagerView;
