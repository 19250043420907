import * as c from './constants';


const initialState = {
  tags: { total: 0, items: [] },
  /* TODO rename wrong word in all project */
  criterions: { total: 0, items: [] },
  educationTypes: { total: 0, items: [] },
  educationProfiles: { total: 0, items: [] },
  educationLevels: { total: 0, items: [] },
  educationFormats: { total: 0, items: [] },
  questions: { total: 0, items: [] },
  reviews: { total: 0, items: [] },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getTags.success: {
      return { ...state, tags: action.payload };
    }

    case c.deleteTag.success: {
      return {
        ...state,
        tags: {
          items: state.tags.items.filter((tag) => tag._id !== action.payload),
          total: parseInt(state.tags.total, 10) - 1,
        }
      };
    }

    case c.addTag.success: {
      return {
        ...state,
        tags: {
          total: parseInt(state.tags.total, 10) + 1,
          items: [...state.tags.items, action.payload],
        }
      };
    }

    case c.setEvaluationCriteria.success: {
      return { ...state, criterions: action.payload };
    }

    case c.addEvaluationCriteria.success: {
      return {
        ...state,
        criterions: {
          total: parseInt(state.criterions.total, 10) + 1,
          items: [...state.criterions.items, action.payload],
        }
      };
    }

    case c.onArchiveStatusEvaluationCriteria.success: {
      return {
        ...state,
        criterions: {
          ...state.criterions,
          items: state.criterions.items.map((criterion) => {
            if (criterion._id === action.payload._id) {
              return action.payload;
            }

            return criterion;
          })
        }
      };
    }

    case c.getEducationTypes.success: {
      return { ...state, educationTypes: action.payload };
    }

    case c.createEducationType.success: {
      return {
        ...state,
        educationTypes: {
          items: [...state.educationTypes.items, action.payload],
          total: parseInt(state.educationTypes.total, 10) + 1
        }
      };
    }

    case c.deleteEducationType.success: {
      return {
        ...state,
        educationTypes: {
          items: state.educationTypes.items.filter((item) => item._id !== action.payload),
          total: parseInt(state.educationTypes.total, 10) - 1
        }
      };
    }

    case c.getEducationProfiles.success: {
      return { ...state, educationProfiles: action.payload };
    }

    case c.getEducationLevels.success: {
      return { ...state, educationLevels: action.payload };
    }

    case c.createEducationLevel.success: {
      return {
        ...state,
        educationLevels: {
          items: [...state.educationLevels.items, action.payload],
          total: parseInt(state.educationLevels.total, 10) + 1
        }
      };
    }

    case c.deleteEducationLevel.success: {
      return {
        ...state,
        educationLevels: {
          items: state.educationLevels.items.filter((item) => item._id !== action.payload),
          total: parseInt(state.educationLevels.total, 10) - 1
        }
      };
    }

    case c.getEducationFormats.success: {
      return { ...state, educationFormats: action.payload };
    }

    case c.getNewEducationProfile.success: {
      return {
        ...state,
        educationProfiles: {
          items: [...state.educationProfiles.items, action.payload],
          total: parseInt(state.educationProfiles.total, 10) + 1
        }
      };
    }

    case c.editProfile.success: {
      return {
        ...state,
        educationProfiles: {
          ...state.educationProfiles,
          items: state.educationProfiles.items.map((profile) => {
            if (profile._id === action.payload._id) {
              return action.payload;
            }

            return profile;
          })
        }
      };
    }

    case c.editStatusEducationProfile.success: {
      return {
        ...state,
        educationProfiles: {
          ...state.educationProfiles,
          items: state.educationProfiles.items.map((profile) => {
            if (profile._id === action.payload.id) {
              // eslint-disable-next-line no-param-reassign
              profile.status = action.payload.status;
            }

            return profile;
          })
        }
      };
    }

    case c.deleteSingleEducationProfile.success: {
      return {
        ...state,
        educationProfiles: {
          items: state.educationProfiles.items.filter((item) => item._id !== action.payload),
          total: parseInt(state.educationProfiles.total, 10) - 1
        }
      };
    }

    case c.getNewEducationFormat.success: {
      return {
        ...state,
        educationFormats: {
          items: [...state.educationFormats.items, action.payload],
          total: parseInt(state.educationFormats.total, 10) + 1
        }
      };
    }

    case c.deleteEducationFormat.success: {
      return {
        ...state,
        educationFormats: {
          items: state.educationFormats.items.filter((item) => item._id !== action.payload),
          total: parseInt(state.educationFormats.total, 10) - 1
        }
      };
    }

    case c.getQuestionsAdmin.success: {
      return { ...state, questions: action.payload };
    }

    case c.getQuestions.success: {
      return { ...state, questions: action.payload };
    }

    case c.addQuestion.success: {
      return {
        ...state,
        questions: {
          total: parseInt(state.questions.total, 10) + 1,
          items: [...state.questions.items, action.payload],
        }
      };
    }

    case c.deleteQuestion.success: {
      return {
        ...state,
        questions: {
          items: state.questions.items.filter((question) => question._id !== action.payload),
          total: parseInt(state.questions.total, 10) - 1,
        }
      };
    }

    case c.editQuestion.success: {
      return {
        ...state,
        questions: {
          ...state.questions,
          items: state.questions.items.map((question) => {
            if (question._id === action.payload._id) {
              return action.payload;
            }

            return question;
          })
        }
      };
    }

    case c.editQuestionData.success: {
      return {
        ...state,
        questions: {
          ...state.questions,
          items: state.questions.items.map((question) => {
            if (question._id === action.payload._id) {
              return action.payload;
            }

            return question;
          })
        }
      };
    }

    case c.editQuestionToDraft.success: {
      return {
        ...state,
        questions: {
          ...state.questions,
          items: state.questions.items.map((question) => {
            if (question._id === action.payload._id) {
              return action.payload;
            }

            return question;
          })
        }
      };
    }

    case c.getReviewsAdmin.success: {
      return { ...state, reviews: action.payload };
    }

    case c.addReview.success: {
      return {
        ...state,
        reviews: {
          total: parseInt(state.reviews.total, 10) + 1,
          items: [...state.reviews.items, action.payload],
        }
      };
    }

    case c.deleteReview.success: {
      return {
        ...state,
        reviews: {
          items: state.reviews.items.filter((review) => review._id !== action.payload),
          total: parseInt(state.reviews.total, 10) - 1,
        }
      };
    }

    case c.editReview.success: {
      return {
        ...state,
        reviews: {
          ...state.reviews,
          items: state.reviews.items
            .map((item) => {
              if (item._id === action.payload._id) {
                return action.payload;
              }

              return item;
            }),
        }
      };
    }

    default:
      return state;
  }
};

export default reducers;
