import {
  get as oGet,
  post as oPost,
  remove, put as oPut
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const TEMP_COURSE_MANAGEMENT_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getTags = (query = '') => {
  const url = `/tags?${query}`;

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createTag = (newTag) => {
  const url = '/tags';

  return oPost(
    `${url}`,
    newTag,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteTag = (id) => {
  const url = `/tags/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const getEvaluationCriteria = (query = '') => {
  const url = `/evaluation-criteria?${query}`;

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createEvaluationCriteria = (newTag) => {
  const url = '/evaluation-criteria';

  return oPost(
    `${url}`,
    newTag,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const onArchiveEvaluationCriteria = ({
  _id, title, scale, status
}) => {
  const url = `/evaluation-criteria/${_id}`;
  const body = JSON.stringify({ title, scale, status });

  return oPut(`${url}`, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const getEducationTypes = () => {
  const url = '/education-types';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createEducationType = (newType) => {
  const url = '/education-types/';

  return oPost(
    `${url}`,
    newType,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteEducationType = (id) => {
  const url = `/education-types/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const getEducationProfiles = () => {
  const url = '/education-profiles';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createNewEducationProfile = (newEducationProfile) => {
  const url = '/education-profiles';
  const body = JSON.stringify(newEducationProfile);

  return oPost(
    `${url}`,
    body,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const editEducationProfile = ({ id, profile }) => {
  const url = `/education-profiles/${id}`;
  const body = JSON.stringify(profile);

  return oPut(url, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const changeStatusOfProfile = ({ id, status }) => {
  const url = `/education-profiles/${id}`;
  const body = JSON.stringify({ id, status });

  return oPut(url, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const deleteEducationProfile = (id) => {
  const url = `/education-profiles/${id}`;

  return remove(url, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const getEducationLevels = () => {
  const url = '/levels';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createEducationLevel = (newLevel) => {
  const url = '/levels/';

  return oPost(
    `${url}`,
    newLevel,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteEducationLevel = (id) => {
  const url = `/levels/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const getEducationFormats = (query) => {
  const url = query ? `/education-formats?${query}` : '/education-formats';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createNewEducationFormat = (newEducationFormat) => {
  const url = '/education-formats';

  return oPost(
    `${url}`,
    newEducationFormat,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteEducationFormat = (id) => {
  const url = `/education-formats/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const getQuestions = (params) => {
  const url = params ? `/questions?${params}` : '/questions';

  return oGet(`${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API);
};

export const getQuestionsAdmin = (params) => {
  const url = params ? `/admin/questions?${params}` : '/admin/questions';

  return oGet(`${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API);
};

export const createQuestion = (newQuestion) => {
  const url = '/questions';

  return oPost(
    `${url}`,
    newQuestion,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteQuestion = (id) => {
  const url = `/questions/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const editQuestion = ({ id, question }) => {
  const url = `/questions/${id}`;
  const body = JSON.stringify(question);

  return oPut(url, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const changeStatusOfQuestion = ({ id, status }) => {
  const url = `/questions/${id}`;
  const body = JSON.stringify({ status });

  return oPut(`${url}`, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};

export const getReviews = (query) => {
  const url = query ? `/reviews?${query}` : '/reviews';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const getReviewsAdmin = (query) => {
  const url = query ? `/admin/reviews?${query}` : '/admin/reviews';

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const createReview = (newReview) => {
  const url = '/reviews';

  return oPost(
    `${url}`,
    newReview,
    HEADERS,
    false,
    TEMP_COURSE_MANAGEMENT_API
  );
};

export const deleteReview = (id) => {
  const url = `/reviews/${id}`;

  return remove(`${url}`, null, HEADERS, TEMP_COURSE_MANAGEMENT_API);
};

export const editReview = ({ id, review }) => {
  const url = `/reviews/${id}`;
  const body = JSON.stringify(review);

  return oPut(url, body, HEADERS, false, TEMP_COURSE_MANAGEMENT_API);
};
