import React from 'react';
import ModalV2 from 'components/modal-v2';
import { useDispatch } from 'react-redux';
import { editAd } from 'models/blogAndAdvertising/actions';
import FormAds from '../../form';


const ModalEditAd = ({ isVisible, onClose, ad }) => {
  const dispatch = useDispatch();

  const onHandlerSubmit = (newAd) => {
    dispatch(editAd(ad._id, newAd));
    onClose();
  };

  return (
    <ModalV2
      title="Реклама"
      closable={false}
      visible={isVisible}
      footer=""
      onClose={onClose}
      width={548}
      destroyOnClose
    >
      <FormAds
        onSubmit={onHandlerSubmit}
        ad={ad}
        handleClose={onClose}
      />
    </ModalV2>
  );
};

export default ModalEditAd;
