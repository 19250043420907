import AdminCourses from 'components/admin-panel/components/courses';
import WrapperTabsAdmin from 'components/admin-panel/components/wrapper-tabs-admin';
import React from 'react';
import ArchiveCourses from 'components/admin-panel/components/archive-courses';


const ContentManagerCourses = () => {
  const tabsItems = [
    { label: 'Курсы', key: '1', component: <AdminCourses position="up" /> },
    { label: 'Архив курсов', key: '2', component: <ArchiveCourses /> },
  ];

  return (
    <div>
      <WrapperTabsAdmin tabsItems={tabsItems} title="Мои курсы" />
    </div>
  );
};

export default ContentManagerCourses;
