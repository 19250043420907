import React, { useMemo } from 'react';
import { editProfile } from 'models/manualControlOfCouses/actions';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import ModalV2 from 'components/modal-v2';
import FormDirection from '../form';


const EditDirectionModal = ({ values, isModalVisible, onClose }) => {
  const dispatch = useDispatch();

  const onEditDirection = (profile) => {
    dispatch(editProfile(values._id, profile));
    onClose();
  };

  const initialValues = useMemo(() => {
    const init = cloneDeep(values);

    return init;
  }, [values]);

  return (
    <ModalV2
      title="Редактировать запись"
      visible={isModalVisible}
      width={992}
      closable={false}
      footer=""
      tooltipClose="После отмены восстановление заполненных  полей невозможно"
      onClose={onClose}
      destroyOnClose
    >
      <FormDirection
        onSubmitHandler={onEditDirection}
        initialValues={initialValues}
        onClose={onClose}
      />
    </ModalV2>
  );
};

export default EditDirectionModal;
