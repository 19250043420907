import React from 'react';
import bem from 'easy-bem';
import './style.less';


const Menu = ({ children }) => {
  const b = bem('menu-manual');

  return (
    <ul className={b()}>
      {children}
    </ul>
  );
};

export default Menu;
