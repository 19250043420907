import React, { useMemo, useState } from 'react';
import { Divider, Form, Tooltip } from 'antd';
import InputV2 from 'components/input-v2';
import FileDragger from 'components/dragger';
import { requiredField, rulesValidationOne } from 'utils/validation/validation';
import Button from 'components/button-v2';
import { selectStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import TextArea from 'antd/lib/input/TextArea';
import bem from 'easy-bem';

import './style.less';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { getPublishedCourses } from 'models/courses/selectors';


const FormReview = ({ onCloseHandler, review, onSubmit }) => {
  const [form] = Form.useForm();
  const courses = useSelector(getPublishedCourses).items;
  const b = bem('review-modal');
  const [text, setText] = useState('');

  const initialValues = useMemo(() => {
    const init = cloneDeep(review);
    init.courseId = init.course?._id;

    return init;
  }, [review]);


  const rulesValidationSymbols = {
    validator: (_, value) => {
      if (value) {
        return (value.length > 500
          ? Promise.reject(new Error('Превышено количество допустимых символов.'))
          : Promise.resolve());
      }

      return Promise.resolve();
    },

  };
  const requiredFields = ['course', 'authorSurname', 'authorName', 'text'];

  const disabledBtn = () => (!form.isFieldsTouched(requiredFields, true) && !review)
  || form.getFieldsError().filter(({ errors }) => errors.length)
    .length > 0;

  return (
    <Form
      onFinish={(values) => onSubmit(values, form)}
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
    >
      <InputV2
        type="select"
        name="courseId"
        label="Выберите курс"
        value
        rules={[requiredField]}
        options={courses}
        required
      />
      <InputV2
        rules={[requiredField, ...rulesValidationOne]}
        type="form"
        name="authorSurname"
        label="Фамилия автора"
        placeholder="Фамилия автора"
        required
      />
      <InputV2
        rules={[requiredField, ...rulesValidationOne]}
        type="form"
        name="authorName"
        label="Имя автора"
        placeholder="Имя автора"
        required
      />
      <Tooltip
        className={b('tooltip')}
        placement="top"
        title={text.length >= 500 ? 'Максимальное количество символов 500.' : ''}
      >
        <Form.Item
          label="Текст отзыва"
          name="text"
          rules={[requiredField, rulesValidationSymbols]}
        >
          <TextArea
            style={{ ...selectStyle, height: 52 }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 2 }}
            maxLength={500}
            placeholder="Ввод текста"
            className={b('text-input')}
            onChange={(e) => setText(e.target.value)}
          />
        </Form.Item>
      </Tooltip>
      <Form.Item
        label="Фото автора"
        name="authorAvatar"
      >
        <FileDragger
          accept=".png, .jpeg, .jpg"
          maxLength={1}
          text="Переместите фото сюда или"
          initialFile={review?.authorAvatar}
          textBlue="выберите файл"
          fileType={['png', 'jpeg']}
          aspect={1080 / 1080}
          crop
        />
      </Form.Item>
      <Divider className={b('divider')} />
      <div className={b('footer')}>
        <Button
          onClick={onCloseHandler}
          type="primary"
          ghost
          className={b('closeBtn')}
        >
          Отменить
        </Button>
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              htmlType="submit"
              className="buttonSubmit"
              type="primary"
              disabled={disabledBtn()}
            >
              Сохранить
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormReview;
