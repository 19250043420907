import React from 'react';
import ModalV2 from 'components/modal-v2';
import { editReview } from 'models/manualControlOfCouses/actions';
import { useDispatch } from 'react-redux';
import bem from 'easy-bem';
import FormReview from '../form/component';


const ModalToEditReview = ({ isVisible, onClose, review }) => {
  const b = bem('review-modal');
  const dispatch = useDispatch();

  const onSubmit = (newReview) => {
    dispatch(editReview(review._id, newReview));
    onClose();
  };
  
  return (
    <ModalV2
      className={b('modal')}
      title="Редактировать"
      closable={false}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      footer=""
    >
      <FormReview
        onSubmit={onSubmit}
        onCloseHandler={onClose}
        review={review}
      />
    </ModalV2>
  );
};

export default ModalToEditReview;
