export const textStatus = {
  published: 'Опубликован',
  agreed: 'На согласовании',
  draft: 'Черновик',
  archived: 'В архиве',
  active: 'Активен',
  moderate: 'На модерации',
  rework: 'На доработке',
};

export const dateTypeText = {
  choose_dates: 'Выбор даты курса',
  open: 'Открытые даты',
  group_set: 'По факту набора группы',
  accept_application: 'Предварительный прием заявок',
};

export const roleText = {
  'content-manager': 'Контент-менеджер УИ',
  'partner-content-manager': 'Контент-менеджер партнёра',
  user: 'Пользователь',
  admin: 'Администратор'
};

export const coursePayment = {
  full_price: 'Указать стоимость',
  installment: 'Возможна оплата частями',
  free: 'Бесплатно',
};

export const teacherRole = {
  curator: 'Куратор',
  speaker: 'Спикер',
  teacher: 'Преподаватель'
};


export const funnelItem = {
  147: 'Внутренние курсы УИ',
  139: 'Внутренние курсы УИ',
  161: 'Курсы МООК',
  140: 'Курсы МООК',
  164: 'Курсы от вендоров ПО',
  141: 'Курсы от вендоров ПО'
};

export const ordersStatus = {
  consideration: 'Заявка подана'
};

