import React from 'react';
import bem from 'easy-bem';

import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import { useSelector } from 'store/use-selector';
import { getCourseReport } from 'models/reports/selectors';
import ReportModal from 'components/modal/report-modal';
import { writePdf } from 'utils/pdf/writePdf';
import { writeXlsx } from 'utils/xlsx-write/xlsx-write';

import './style.less';


const CourseReport = ({ onClose, isModalVisible, courseTitle }) => {
  const b = bem('report-modal-course');
  const report = useSelector(getCourseReport);


  const reportColumns = [
    {
      title:
  <span className={b('table-title')}>
    ФИО
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'userFullName',
      key: 'userFullName',
    },
    {
      title:
  <span className={b('table-title')}>
    Стоимость, руб.
  </span>,
      dataIndex: 'price',
      key: 'price',
    },
    {
      title:
  <span className={b('table-title')}>
    Дата заявки
  </span>,
      dataIndex: 'createdAt',
      key: 'createdAt',

    },
    {
      title:
  <span className={b('table-title')}>
    Статус
  </span>,
      dataIndex: 'statusText',
      key: 'statusText',
    },
  ];
  const exportXlsx = () => writeXlsx(report, courseTitle);
  const exportPDF = () => writePdf(
    courseTitle,
    report?.orders,
    ['userFullName', 'price', 'createdAt', 'statusText'],
    [
      { text: 'ФИО', style: 'th' },
      { text: 'Стоимость курса, руб.', style: 'th' },
      { text: 'Дата заявки', style: 'th' },
      { text: 'Cтатус', style: 'th' }
    ],
    ['40%', 'auto', 'auto', 'auto']
  );

  return (
    <>
      <ReportModal
        modalsVisible={isModalVisible}
        onClose={onClose}
        report={report?.orders}
        text={courseTitle}
        columns={reportColumns}
        onLoadPdf={exportPDF}
        onLoadXlsx={exportXlsx}
        isXlsx
      />
    </>
  );
};

export default CourseReport;
