import React, { useCallback, useEffect, useState } from 'react';
import './style.less';
import slug from 'slug';
import bem from 'easy-bem';
import {
  Input, Radio, Select, Switch, Form
} from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { errorMessageCatcher } from 'utils/request';

import {
  getEducationTypes,
  getEducationLevels,
  getEducationProfiles,
  getTags,
  getEducationFormats,
  getEvaluationCriteria,
  isDisabledLocationCourse
} from 'models/courses/selectors';
import {
  requiredField,
  requiredQuill,
  rulesQuillValidationOne,
  rulesValidationOne,
  rulesValidationThree
} from 'utils/validation/validation';
import DatePicker from 'components/datepicker';
import { fetchAllDataToCourse, onChangeDisabledLocationCourse, setSlug } from 'models/courses/actions';
import TextEditor from 'components/text-editor';
import { onCheckDuplicateCourse } from 'models/courses/api';

import ModalSelect from '../modal-select';
import ModalAddBtn from '../../../add-more-btn';
import { inputStyle, selectStyle } from '../modal-upload/component';


const { Option } = Select;
const format = 'DD.MM.YYYY';

const durationUnits = [
  {
    value: 'day',
    label: 'День',
  },
  {
    value: 'week',
    label: 'Неделя',
  },
  {
    value: 'month',
    label: 'Месяц',
  },
  {
    value: 'year',
    label: 'Год'
  }];

const AllInformation = ({
  form, setDates, onChangeDates, dates,
}) => {
  const b = bem('all-information');
  const dispatch = useDispatch();
  const tags = useSelector(getTags);
  const educationTypes = useSelector(getEducationTypes);
  const educationProfiles = useSelector(getEducationProfiles);
  const levels = useSelector(getEducationLevels);
  const evaluationCriteria = useSelector(getEvaluationCriteria);
  const educationFormats = useSelector(getEducationFormats);
  const isDisabledLocation = useSelector(isDisabledLocationCourse);
  const [isDisabledDates, setIsDisabledDates] = useState(form.getFieldValue('dateType'));

  const checkTypeEducationForm = useCallback((value) => {
    const educationType = educationTypes.find((item) => item._id === value);
    if (educationType?.title === 'Дистанционно') {
      dispatch(onChangeDisabledLocationCourse(true));
      form.setFieldValue('address', '');
    } else {
      dispatch(onChangeDisabledLocationCourse(false));
    }
  }, [dispatch, educationTypes, form]);

  const deleteItemDate = (fnDelete, idx) => {
    fnDelete(idx);
    const newDates = form.getFieldValue('dates');
    setDates(newDates);
  };

  const onChangeTypeDates = (e) => {
    const { value } = e.target;
    if (value === 'choose_dates') {
      form.setFieldValue('dates', [{ startDate: null, endDate: null }]);
      setDates([{ startDate: null, endDate: null }]);
    } else {
      form.resetFields(['dates']);
      form.setFieldValue('dates', ['']);
      setDates([]);
    }

    setIsDisabledDates(value);
  };

  const setSlugName = (value) => {
    const pseudonym = slug(value);
    form.setFields([
      {
        name: 'slug',
        errors: [],
        value: pseudonym
      },
    ]);
    dispatch(setSlug(pseudonym));
  };

  const checkCourseTitle = async (value) => {
    const { status } = await onCheckDuplicateCourse({
      title: value,
    });
    if (status === 'used') {
      form.setFields([
        {
          name: 'title',
          errors: ['Значение уже используется в другом курсе'],
        },
      ]);
    } else {
      setSlugName(value);
    }
  };

  const onTitleChange = (event) => {
    const { value } = event.target;
    const title = value.trim();
    try {
      checkCourseTitle(title);
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  useEffect(() => {
    dispatch(fetchAllDataToCourse('status=active'));
  }, [dispatch]);

  useEffect(() => {
    checkTypeEducationForm(form.getFieldValue('educationType'));
  }, [checkTypeEducationForm, educationTypes, form]);

  return (
    <div className={b()}>
      <div className={b('info')}>
        <Form.Item
          name="title"
          label="Название курса"
          rules={[...rulesValidationOne, requiredField]}
          required
        >
          <TextArea
            style={{ ...selectStyle, height: 52 }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 2 }}
            placeholder="Ввод текста"
            className={b('info-input')}
            onChange={onTitleChange}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание курса"
          rules={[...rulesQuillValidationOne, requiredQuill]}
          required
        >
          <TextEditor
            size="large"
            placeholder="Ввод текста"
          />
        </Form.Item>
      </div>

      <div className={b('date')}>
        <Form.Item name="dateType">
          <Radio.Group
            className={b('date-radios')}
            onChange={onChangeTypeDates}
          >
            <Radio value="choose_dates">Выбор даты курса</Radio>
            <Radio value="open">Открытые даты</Radio>
            <Radio value="group_set">По факту набора группы</Radio>
            <Radio value="accept_application">Предварительный приём заявок</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={b('date-inputs')}>
          <Form.List name="dates">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...rest }) => (
                  <div key={key} className={b('list-dates')}>
                    <Form.Item
                      {...rest}
                      name={[name, 'startDate']}
                      label="Дата начала курса"
                      rules={isDisabledDates !== 'choose_dates' ? [] : [requiredField]}
                    >
                      <DatePicker
                        format={format}
                        placeholder="Ввод даты"
                        style={{ ...inputStyle, width: 210 }}
                        className={b('date-piker')}
                        onChange={(date) => onChangeDates(name, 'startDate', date)}
                        disabledDate={(current) => current < dayjs().subtract(0, 'days')}
                        disabled={isDisabledDates !== 'choose_dates'}
                      />
                    </Form.Item>
                    <Form.Item
                      {...rest}
                      name={[name, 'endDate']}
                      label="Дата окончания курса"
                      rules={isDisabledDates !== 'choose_dates' ? [] : [requiredField]}
                    >
                      <DatePicker
                        format={format}
                        placeholder="Ввод даты"
                        style={{ ...inputStyle, width: 210 }}
                        onChange={(date) => onChangeDates(name, 'endDate', date)}
                        className={b('date-piker')}
                        disabled={!dates[name]?.startDate}
                        disabledDate={(current) => current < dayjs(dates[name].startDate).subtract(0, 'days')}
                      />
                    </Form.Item>
                    {fields.length > 1 && (
                      // eslint-disable-next-line max-len
                      <PlusOutlined
                        className={b('special-select-item-close')}
                        onClick={() => deleteItemDate(remove, name)}
                      />
                    )}
                  </div>
                ))}
                <Form.Item hidden={dates.length < 1}>
                  <ModalAddBtn
                    text="добавить ещё поток"
                    onAdd={() => add({ startDate: null, endDate: null })}
                    disabled={isDisabledDates !== 'choose_dates'}
                  />
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className={b('selects-small')}>
            <Form.Item
              label="Продолжительность курса"
              name="duration"
              rules={[requiredField, rulesValidationThree]}
            >
              <Input
                style={{ ...inputStyle, width: 210 }}
                placeholder="Ввод текста"
              />
            </Form.Item>
            <Form.Item
              label="Единицы измерения"
              name="durationUnit"
              rules={[requiredField]}
            >
              <Select
                style={{ ...inputStyle, width: '100%' }}
                size="large"
                placeholder="Выберите значение"
              >
                {durationUnits.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={b('selects-hour')}>
            <Form.Item
              label="Продолжительность курса в академических часах"
              name="durationAcademicHour"
              rules={[rulesValidationThree]}
            >
              <Input
                style={{ ...inputStyle, width: 400 }}
                placeholder="Ввод текста"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className={b('selects')}>
        <ModalSelect
          label="Направления обучения"
          validation={[requiredField]}
          name="educationProfiles"
          options={educationProfiles}
          style={{ ...inputStyle, width: '100%' }}
        />
        <ModalSelect
          label="Формат обучения"
          name="educationFormats"
          validation={[requiredField]}
          options={educationFormats}
          style={{ ...inputStyle, width: '100%' }}
        />
        <Form.Item
          label="Тип обучения"
          name="educationType"
          rules={[requiredField]}
        >
          <Select
            style={{ ...inputStyle, width: '100%' }}
            size="large"
            placeholder="Выберите значение"
            onChange={checkTypeEducationForm}
          >
            {educationTypes.map((item) => (
              <Option value={item._id} key={item._id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Адрес места проведения"
          name="address"
          rules={isDisabledLocation ? [] : [requiredField]}
        >
          <Input
            style={{ ...inputStyle, width: '100%' }}
            size="large"
            placeholder="Начните вводить адрес"
            disabled={isDisabledLocation}
          />
        </Form.Item>
        <div className={b('selects-small')}>
          <Form.Item
            label="Уровень сложности"
            name="level"
            rules={[requiredField]}
          >
            <Select
              style={{ ...inputStyle, width: '100%' }}
              size="large"
              placeholder="Выберите значение"
            >
              {levels.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Результаты обучения" name="educationResult">
            <Input
              style={{ ...inputStyle }}
              placeholder="Ввод текста"
            />
          </Form.Item>
        </div>
        <ModalSelect
          label="Критерии оценки"
          name="evaluationCriteria"
          validation={[requiredField]}
          options={evaluationCriteria}
          style={{ ...inputStyle, width: '100%' }}
        />
        <ModalSelect
          label="Теги"
          name="tags"
          validation={[requiredField]}
          options={tags}
          style={{ ...inputStyle, width: '100%' }}
        />
      </div>
      <div className={b('special-selects')}>
        <Form.List name="specialRequirements">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key} className={b('special-select-item')}>
                  <Form.Item
                    {...field}
                    label="Специальные требования"
                    rules={[...rulesValidationOne]}
                  >
                    <Input placeholder="Введите данные" />
                  </Form.Item>
                  <PlusOutlined
                    className={b('special-select-item-close')}
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <ModalAddBtn onAdd={() => add()} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className={b('switch-btn')}>
        <Form.Item name="allowMessages" valuePropName="checked">
          <Switch defaultChecked size="small" />
        </Form.Item>
        <p>Разрешить задавать вопросы о курсе</p>
      </div>
    </div>
  );
};

export default AllInformation;
