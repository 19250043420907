import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
  exact
  key="courses"
  path={[
    '/', '/home', '/main', '/index.html', '/guest',
    '/courses',
    '/courses/:slug',
    '/letters',
    '/compilation/:id',
    '/articles/:id',
    '/courses-admin',
    '*',
  ]}
  component={Page}
/>;
