import * as c from './constants';


const initialState = {
  ads: { total: 0, items: [] },
  posts: { total: 0, items: [] },
  promos: { total: 0, items: [] },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getAds.success: {
      return { ...state, ads: action.payload };
    }

    case c.createAd.success: {
      return {
        ...state,
        ads: {
          total: parseInt(state.ads.total, 10) + 1,
          items: [...state.ads.items, action.payload],
        }
      };
    }

    case c.onCopyAd.success: {
      return {
        ...state,
        ads: {
          total: parseInt(state.ads.total, 10) + 1,
          items: [...state.ads.items, action.payload],
        }
      };
    }

    case c.editAd.success: {
      return {
        ...state,
        ads: {
          ...state.ads,
          items: state.ads.items
            .map((item) => {
              if (item._id === action.payload._id) {
                return action.payload;
              }

              return item;
            }),
        }
      };
    }

    case c.onPublishAd.success: {
      return {
        ...state,
        ads: {
          ...state.ads,
          items: state.ads.items
            .map((item) => {
              if (item._id === action.payload._id) {
                return action.payload;
              }

              return { ...item, status: 'draft' };
            }),
        }
      };
    }

    case c.deleteAd.success: {
      return {
        ...state,
        ads: {
          ...state.ads,
          items: state.ads.items
            .filter((item) => item._id !== action.payload._id),
        }
      };
    }

    case c.getPosts.success: {
      return {
        ...state,
        posts: action.payload
      };
    }

    case c.createPost.success: {
      return {
        ...state,
        posts: {
          items: [...state.posts.items, action.payload],
          total: parseInt(state.posts.total, 10) + 1,
        }
      };
    }

    case c.editPost.success: {
      return {
        ...state,
        posts: {
          ...state.posts,
          items: state.posts.items.map((post) => {
            if (post._id === action.payload._id) {
              return action.payload;
            }

            return post;
          })
        }
      };
    }

    case c.onArchivedPost.success: {
      return {
        ...state,
        posts: {
          ...state.posts,
          items: state.posts.items.map((post) => {
            if (post._id === action.payload._id) {
              return action.payload;
            }

            return post;
          })
        }
      };
    }

    case c.onPublishPost.success: {
      return {
        ...state,
        posts: {
          ...state.posts,
          items: state.posts.items.map((post) => {
            if (post._id === action.payload._id) {
              return action.payload;
            }

            return post;
          })
        }
      };
    }

    case c.deletePost.success: {
      return {
        ...state,
        posts: {
          total: parseInt(state.posts.total, 10) - 1,
          items: state.posts.items.filter((post) => post._id !== action.payload)
        }
      };
    }

    case c.getAdminPromos.success: {
      return { ...state, promos: action.payload };
    }

    case c.getPromosActual.success: {
      return { ...state, promos: action.payload };
    }

    case c.createPromo.success: {
      return {
        ...state,
        promos: {
          total: parseInt(state.promos.total, 10) + 1,
          items: [...state.promos.items, action.payload],
        }
      };
    }

    case c.onCancelPromo.success: {
      return {
        ...state,
        promos: {
          items: state.promos.items.filter((item) => item._id !== action.payload._id),
          total: parseInt(state.promos.total, 10) - 1,
        }
      };
    }

    case c.editPromo.success: {
      return {
        ...state,
        promos: {
          ...state.promos,
          items: state.promos.items
            .map((item) => {
              if (item._id === action.payload._id) {
                return action.payload;
              }

              return item;
            }),
        }
      };
    }

    case c.setSlug.success: {
      return { ...state, slug: action.payload };
    }

    default:
      return state;
  }
};

export default reducers;

