import React from 'react';
import './style.less';
import bem from 'easy-bem';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import ModalV2 from 'components/modal-v2';
import Table from 'components/table';
import ButtonV2 from 'components/button-v2';
import { useSelector } from 'react-redux';
import { getLoadingReport } from 'models/reports/selectors';


const ReportModal = ({
  modalsVisible,
  columns,
  onClose,
  report,
  text,
  onLoadPdf,
  onLoadXlsx,
  isXlsx = false,
}) => {
  const b = bem('report-modal');
  const loading = useSelector(getLoadingReport);

  return (
    <ModalV2
      visible={modalsVisible}
      width={992}
      title="Отчет"
      onClose={onClose}
      closable={false}
      footer={(
        <div className={b('footer')}>
          <ButtonV2 type="primary" ghost icon={<Download />} onClick={onLoadPdf}>
            <span>PDF</span>
          </ButtonV2>
          {
            isXlsx && (
              <ButtonV2 type="primary" ghost icon={<Download />} onClick={onLoadXlsx}>
                <span>XLSX</span>
              </ButtonV2>
            )
          }
        </div>
      )}
    >
      <div className={b('container')}>
        { loading ? <div>Загрузка...</div>
          : (
            <>
              <h4 className={b('subtitle')}>{text}</h4>
              <div className={b('table')}>
                <Table
                  dataSource={report}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </>
          )}
      </div>
    </ModalV2>
  );
};

export default ReportModal;
