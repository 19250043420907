import React, { useEffect } from 'react';
import { Pagination } from 'antd';
import bem from 'easy-bem';

import './style.less';
import Button from 'components/button-v2';


const PaginationV2 = (props) => {
  const b = bem('pagination-v2');

  const {
    total = 30,
    pageSize = 10,
    defaultPageSize = 10,
    currentPage = 1,
    onChangePage = () => {},
    data = [],
    setDataCallback = () => {},
    showLeftTotal = false,
    more = () => {},
    pageSizeOptions = ['20', '50', '100'],
    className: cn = '',
    ...rest
  } = props;

  const getPageSizeOptions = () => {
    if (pageSizeOptions.length) {
      return pageSizeOptions;
    }

    if (total < defaultPageSize) {
      return [defaultPageSize];
    }

    const pageCount = Math.ceil(total / defaultPageSize);

    let step;
    const options = [];

    for (step = 0; step < pageCount; step += 1) {
      // варианты размеров страницы
      options.push(`${defaultPageSize * (step + 1)}`);
    }

    return options;
  };

  useEffect(() => {
    if (!data) return;

    let offset = currentPage > 0 ? pageSize * (currentPage - 1) : currentPage;

    let end = offset + pageSize;
    if (end > data.length) {
      end = data.length;
    }

    const newData = [];

    for (offset; offset < end; offset += 1) {
      newData.push({ ...data[offset], key: offset });
    }

    setDataCallback(newData);
  }, [currentPage, data, pageSize, setDataCallback]);

  return (
    <div className={`${b()} ${cn}`}>
      <div className={b('body')}>
        <span className={b('left-total', { hidden: !showLeftTotal })}>
          <span>{`Всего записей: ${total}`}</span>
        </span>

        <Pagination
          data-options-label="На странице:"
          total={total}
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
          showTotal={(totalRec, range) => `${range[0]}-${range[1]} из ${totalRec}`}
          pageSizeOptions={getPageSizeOptions()}
          size="small"
          locale={{ items_per_page: '' }}
          onChange={onChangePage}
          hideOnSinglePage
          {...rest}
        />
      </div>
      <Button
        type="primary"
        ghost
        className={b('button')}
        onClick={more}
        disabled={pageSize >= total}
      >
        Показать ещё
      </Button>
    </div>
  );
};

export default PaginationV2;
