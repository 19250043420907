/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
import { combineReducers } from 'redux';

import user from './user';
import common from './common';
import manual from './manualControlOfCouses';
import courses from './courses';
import ads from './blogAndAdvertising';
import users from './users';
import favorites from './favorites';
import orders from './orders';
import reviews from './reviews';
import reports from './reports';
import compilation from './compilation';
import filters from './filters';


const modules = [user, common, manual, courses, ads, users, favorites, orders, reviews, reports, compilation, filters];

export default modules;

export const actions = modules.reduce((previousValue, { actions }) => ({ ...previousValue, ...actions }), {});

export const constants = modules.reduce((previousValue, { constants }) => ({ ...previousValue, ...constants }), {});

export const reducers = combineReducers(
  modules.reduce((
    previousValue,
    { reducers, options }
  ) =>
    (reducers ? { ...previousValue, [options.name]: reducers } : previousValue), {})
);

export const saga = modules.reduce((previousValue, { saga }) => (saga ? [...previousValue, saga] : previousValue), []);

export const selectors = modules.reduce((previousValue, { selectors }) => ({ ...previousValue, ...selectors }), {});
