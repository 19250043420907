import React, { useState } from 'react';
import { UnderConstruction } from 'components/under-construction';
import { ReactComponent as AllUsersIcon } from 'assets/icons/user-managment/all-users-icon.svg';
import { ReactComponent as ContentManagerIcon } from 'assets/icons/user-managment/content-manager-icon.svg';
import { ReactComponent as MailIcon } from 'assets/icons/user-managment/mail-icon.svg';
import bem from 'easy-bem';
import AllUsers from './components/all-users';
import ContentManagers from './components/content-managers';
import RemoveRoleModal from '../../modal/remove-role-modal';
// hidden until the component is ready
// import MailingList from './components/mailing-list';
import CreateMailingListModal from '../../modal/create-mailing-list-modal';
import WrapperTabsAdmin from '../wrapper-tabs-admin';
import './style.less';


const UserManagement = () => {
  const [modalsVisible, setModalsVisible] = useState({
    reportModal: false,
    removeRoleModal: false,
    questionModal: false,
    createMailingListModal: false
  });
  const b = bem('user-management');
  const tabsItems = [
    {
      icon: <AllUsersIcon className={b('icon')} />,
      label: 'Все пользователи',
      key: '1',
      component: <AllUsers setModalsVisible={setModalsVisible} />
    },
    {
      icon: <ContentManagerIcon className={b('icon')} />,
      label: 'Контент-менеджеры',
      key: '2',
      component: <ContentManagers setModalsVisible={setModalsVisible} />
    },
    {
      icon: <MailIcon className={b('icon')} />,
      label: 'Рассылки',
      key: '3',
      component: <UnderConstruction /> /* <MailingList setModalsVisible={setModalsVisible} /> */
    },
  ];

  return (
    <div className={b()}>
      <WrapperTabsAdmin tabsItems={tabsItems} title="Все пользователи" showCount />
      <RemoveRoleModal modalsVisible={modalsVisible} setModalsVisible={setModalsVisible} />
      <CreateMailingListModal modalsVisible={modalsVisible} setModalsVisible={setModalsVisible} />
    </div>
  );
};

export default UserManagement;
