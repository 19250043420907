import React, { useState } from 'react';
import { Checkbox, Form, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { createPost } from 'models/blogAndAdvertising/actions';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import './style.less';
import { ReactComponent as Tick } from 'assets/icons/filters/tick.svg';
import bem from 'easy-bem';
import FormPost from '../form';


const ModalCreateBlog = ({
  isVisibleModal, onClose, post = null
}) => {
  const b = bem('create-post');
  const [publishAnonymously, setPublishAnonymously] = useState(false);
  const requiredFields = ['title', 'imageUrls', 'text', 'bgImageUrl'];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  const onCreatePost = (newPost) => {
    dispatch(createPost(JSON.stringify({ ...newPost, publishAnonymously })));
    closeHandler();
  };  

  const disabledBtn = () => (!form.isFieldsTouched(requiredFields, true) && !post)
      || form.getFieldsError().filter(({ errors }) => errors.length)
        .length > 0;

  return (
    <ModalV2
      getContainer={false}
      title="Добавить запись блога"
      closable={false}
      visible={isVisibleModal}
      footer=""
      onClose={closeHandler}
      width={992}
    >
      <FormPost
        form={form}
        onHandleSubmit={onCreatePost}
        post={post}
      >
        <div className={b('checkbox')}>
          <Checkbox
            checked={publishAnonymously}
            onChange={(e) => setPublishAnonymously(e.target.checked)}
            checkedIcon={<Tick />}
          >
            Скрывать имя автора
          </Checkbox>
        </div>
        <div className={b('saveCancel')}>
          <Tooltip title="После отмены восстановление заполненных полей невозможно">
            <Button type="primary" ghost onClick={closeHandler}>Отменить</Button>
          </Tooltip>
          <Form.Item shouldUpdate>
            {
              () => (
                <Tooltip title={disabledBtn() && 'Заполните все обязательные поля'}>
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={disabledBtn()}
                    >
                      Сохранить
                    </Button>
                  </div>
                </Tooltip>
              )
            }
          </Form.Item>
        </div>
      </FormPost>
    </ModalV2>
  );
};

export default ModalCreateBlog;
