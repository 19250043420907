import * as c from '../constants';


const initialState = {
  isAuth: false,
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.getUser.success: {
      return {
        ...state,
        isAuth: true
      };
    }

    case c.setLoading.success: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
