import {
  get as oGet,
  post as oPost
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const TEMP_USERS_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getUsers = (query) => {
  const url = query ? `/users?${query}` : '/users';

  return oGet(`${url}`, HEADERS, TEMP_USERS_API);
};

export const getUsersOneId = (query) => {
  const url = query ? `/users/oneid?${query}` : '/users/oneid';

  return oGet(`${url}`,
    HEADERS, TEMP_USERS_API);
};

export const assignmentRole = (user) => {
  const url = '/users/add';

  return oPost(
    `${url}`,
    user,
    HEADERS,
    false,
    TEMP_USERS_API
  );
};
