/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import '../style.less';
import bem from 'easy-bem';
import {
  Tabs,
  Form,
} from 'antd';
import AllInformation from './components/modal-all-information';
import Competence from './components/modal-competence';
import ModalProgram from './components/modal-program';
import ModalTeachers from './components/modal-teachers';
import ModalPartners from './components/modal-partners';
import ModalOverview from './components/modal-overview';
import ModalDocument from './components/modal-document';
import ModalPrice from './components/modal-price';
import FooterActionsCourse from '../footer';


const { TabPane } = Tabs;
const FormCourse = ({
  initialValues,
  onSubmitHandler,
  onClose
}) => {
  const b = bem('modal-create-course');
  const [form] = Form.useForm();
  const [dates, setDates] = useState(initialValues.dates);
  const onHandleChangeDates = (idx, name, value) => {
    if (dates[idx]) {
      const changeDates = dates.map((item, index) => {
        if (index === idx) {
          return { ...item, [name]: value };
        }

        return item;
      });
      setDates(changeDates);
    } else {
      setDates((prev) => ([...prev, { [name]: value }]));
    }
  };

  const onSubmit = (course) => {
    if (course.dateType !== 'choose_dates') {
      course.dates = [];
    }

    if (course.bitrixData?.assignedUserId?.length === 0 || !course.bitrixData?.assignedUserId) {
      course.bitrixData.assignedUserId = 1;
    }

    course.programs.forEach((field) => {
      if (field.dates?.length) {
        field.dates = {
          programDateStart: field.dates[0],
          programDateEnd: field.dates[1],
        };
      } else {
        delete field.dates;
      }

      if (field.description) {
        field.description = field.description.replace(/<(.|\n)*?>/g, '');
      }

      field.events = field.events?.filter((event) => {
        if (event.eventDates?.length) {
          const { eventDates } = event;

          // eslint-disable-next-line prefer-destructuring
          event.eventDateStart = eventDates[0];
          // eslint-disable-next-line prefer-destructuring
          event.eventDateEnd = eventDates[1];
        } else {
          delete event.eventDateStart;
          delete event.eventDateEnd;
        }

        delete event.eventDates;

        return event.eventDateStart || event.eventDateStart || event.title;
      });
      if (!field.events?.length) {
        delete field.events;
      }
    });
    course.applicants = course.applicants.filter((applicant) => applicant?.title);
    course.competencies = course.competencies.filter((competence) => competence?.title || competence?.description);
    if (course.digitalProfessions) {
      course.digitalProfessions = course.digitalProfessions
        .map((item) => {
          if (item.description && item.description.includes('<p><br></p>')) {
            item.description = item.description.replace(/<p><br><\/p>/g, '');
          }

          return item;
        })
        .filter((digit) => digit.participant || digit.description);
    } else {
      course.digitalProfessions = [];
    }

    course.stateProgramEmployment = course.stateProgramEmployment
      ? course.stateProgramEmployment.filter((employment) => employment.participant || employment.description)
      : [];
    course.durationAcademicHour = course.durationAcademicHour
      ? course.durationAcademicHour : null;
    course.anotherStatePrograms = course.anotherStatePrograms
      ? course.anotherStatePrograms
        .filter(({ bannerDesktop, bannerTablet, bannerPhone }) => bannerDesktop || bannerTablet || bannerPhone)
      : [];
    course.specialRequirements = course.specialRequirements.filter((spec) => spec);
    course.videoUrls = course.videoUrls.filter((url) => url);
    onSubmitHandler(course);
  };

  return (
    <Form
      className={b('body')}
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={initialValues}
    >
      <Tabs className={b('tabs')} defaultActiveKey="1">
        <TabPane tab="Информация" key="1" forceRender>
          <AllInformation
            form={form}
            onChangeDates={onHandleChangeDates}
            setDates={setDates}
            dates={dates}
          />
        </TabPane>
        <TabPane tab="Стоимость" key="8" forceRender>
          <ModalPrice form={form} />
        </TabPane>
        <TabPane tab="Слушатели" key="2" forceRender>
          <Competence
            form={form}
          />
        </TabPane>
        <TabPane tab="Программа" key="3" forceRender>
          <ModalProgram
            form={form}
            dates={dates}
          />
        </TabPane>
        <TabPane tab="Преподаватели" key="4" forceRender>
          <ModalTeachers
            form={form}
          />
        </TabPane>
        <TabPane tab="Партнёры" key="5" forceRender>
          <ModalPartners
            form={form}
          />
        </TabPane>
        <TabPane tab="Документ" key="6" disabled>
          <ModalDocument form={form} />
        </TabPane>
        <TabPane tab="Обзор" key="7" forceRender>
          <ModalOverview form={form} />
        </TabPane>
      </Tabs>
      <FooterActionsCourse form={form} onClose={onClose} />
    </Form>
  );
};

export default FormCourse;

