import * as c from './constants';


const initialState = {
  banner: {},
  courses: {},
  reviews: {}
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getBanner.success: {
      return {
        ...state,
        banner: action.payload.banner,
        courses: action.payload.courses,
        reviews: action.payload.reviews
      };
    }

    default:
      return state;
  }
};

export default reducers;

