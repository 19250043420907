import React from 'react';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import { useDispatch } from 'react-redux';
import { unPublishCourse } from 'models/courses/actions';
import { unPublishCourse as unPublishCourseFromApi } from 'models/courses/api';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import ModalAdminCourse from '../component';


const RemovePublicationModal = ({ isModalVisible, onClose, id }) => {
  const dispatch = useDispatch();
  const onHandleClick = async () => {
    try {
      const response = await unPublishCourseFromApi(id);
      dispatch(unPublishCourse(response));
      successMessage('Курс перемещен в черновик!');
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  return (
    <ModalAdminCourse
      title="Снятие с публикации"
      visible={isModalVisible}
      width="707px"
      onClose={onClose}
      isModalVisible={isModalVisible}
      onHandleClick={onHandleClick}
      icon={<DoneWarning />}
      contentText="После снятия с публикации курс помещается в черновик.
      Вы уверены, что хотите снять курс с публикации?"
      submitText="Снять с публикации"
      buttonWidth={180}
    />
  );
};

export default RemovePublicationModal;
