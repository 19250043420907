export const getFullNameUser = (user, short = false) => {
  if (!user) {
    return null;
  }

  const { patronymic, surname, name } = user;
  if (short) {
    return `${surname} ${name && name[0]}. ${patronymic && patronymic[0]}.`;
  }

  return `${surname} ${name} ${patronymic}`;
};
