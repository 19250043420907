import React from 'react';
import bem from 'easy-bem';
import { LoadingOutlined } from '@ant-design/icons';

import './style.less';


const GlobalLoader = () => {
  const b = bem('global-loader');

  return (
    <div className={b()}>
      <div className={b('icon')}><LoadingOutlined /></div>
      <div className={b('text')}>Загрузка...</div>
    </div>
  );
};

export default GlobalLoader;
