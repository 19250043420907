import CardTag from 'components/card-tag';
import bem from 'easy-bem';
import React from 'react';
import TitleText from '../../title-text-component/component';
import FileNameArea from '../../file-name-area';
import './style.less';


const ModalPartnersReview = ({ partners }) => {
  const b = bem('modal-partners');

  return (
    <div className={b()}>
      {partners.map((item, idx) => (
        <div className={b('partners-item')} key={idx}>
          <CardTag color="blue" text={item.title} />
          <TitleText label="Описание партнера" html={item.description} />
          { item.url && <TitleText label="Ccылка" html={item.url} /> }
          { item.logo && <FileNameArea title={item.logo} /> }
        </div>
      ))}
    </div>
  );
};

export default ModalPartnersReview;
