import React from 'react';
import ModalV2 from 'components/modal-v2';
import { useDispatch } from 'react-redux';
import { createAd } from 'models/blogAndAdvertising/actions';
import FormAds from '../../form';


const CreateAdModal = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();

  const onHandlerSubmit = (ad) => {
    dispatch(createAd((JSON.stringify(ad))));
    onClose();
  };

  const initialValue = {
    courses: [''],
  };

  return (
    <ModalV2
      title="Добавить подборку"
      closable={false}
      visible={isVisible}
      footer=""
      onClose={onClose}
      width={548}
      destroyOnClose
    >
      <FormAds
        onSubmit={onHandlerSubmit}
        handleClose={onClose}
        ad={initialValue}
      />
    </ModalV2>
  );
};

export default CreateAdModal;
