import bem from 'easy-bem';
import React from 'react';
import { Divider } from 'antd';

import { coursePayment, funnelItem } from 'utils/textOfStatus';

import TitleText from '../../title-text-component/component';

import './style.less';


const PriceReview = ({
  payment, months, paymentTerm, priceType, monthlyPayment, bitrixUserId, bitrixCategoryId
}) => {
  const b = bem('price-review');

  return (
    <div className={b()}>
      { priceType !== 'free'
        ? (
          <>
            <div className={b('price-tab')}>
              <TitleText label="Стоимость" text={`${payment} руб.`} />
              <TitleText label="Количество месяцев рассрочки" text={months || 'Нет'} />
              <TitleText label="Ежемесячный платеж" text={Number.isNaN(monthlyPayment) ? 'Нет' : monthlyPayment} />
              <TitleText label="ID ответственного в битриксе" text={bitrixUserId} />
              <TitleText label="Передача заявок в битрикс" text={funnelItem[bitrixCategoryId]} />
            </div>
            <TitleText label="Условия оплаты" html={paymentTerm || 'Нет'} />
          </>
        )
        : <TitleText label="Оплата" text={coursePayment[priceType]} />}
      <Divider />
    </div>
  );
};

export default PriceReview;
