import { Divider } from 'antd';
import React from 'react';

import TitleText from 'components/modal/modal-review/title-text-component';
import ModalV2 from 'components/modal-v2';
import { formatDate } from 'utils/date';
import { getFullNameUser } from 'utils/get-fullname-user';


const ModalPreviewPromo = ({
  values,
  isModalVisible,
  onClose,
}) => (
  <ModalV2
    visible={isModalVisible}
    getContainer={false}
    closable={false}
    title="Промоакция"
    footer=""
    onClose={onClose}
  >
    <div className="info">
      <TitleText label="Название курса" text={values?.course.title} />
      <Divider />
      <TitleText label="Автор" text={getFullNameUser(values?.course?.userCreator, true)} />
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
        <TitleText label="Цвет карточки промо" text={values?.cardColor} />
        <div style={{
          background: `${values?.cardColor}`, width: 20, height: 20, borderRadius: 10, marginTop: 22
        }}
        />
      </div>
      <Divider />
      {
        values?.endDate
          && <TitleText label="Дата завершения промоакции" text={formatDate(values?.endDate)} />
      }
    </div>
  </ModalV2>
);

export default ModalPreviewPromo;
