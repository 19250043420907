import React, { useRef } from 'react';
import ModalSelect from 'components/modal/modal-course/components/form/components/modal-select';
import { inputStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import { requiredField } from 'utils/validation/validation';
import { useSelector, useDispatch } from 'react-redux';
import { getTags } from 'models/manualControlOfCouses/selectors';
import { Form, Input } from 'antd';
import { getSlug } from 'models/blogAndAdvertising/api';
import { setSlug } from 'models/blogAndAdvertising/actions';
import { getPostsSlug } from 'models/blogAndAdvertising/selectors';
import { useDebounce } from 'react-use';
import { errorMessageCatcher } from 'utils/request';


export default function TagsAndSlug({ readOnly, form }) {
  const tags = useSelector(getTags).items;
  const dispatch = useDispatch();
  const slug = useSelector(getPostsSlug);
  const prevSlug = useRef(slug);

  const onChangeSlugInput = (e) => {
    const pattern = new RegExp(/^[a-zA-Z0-9-]*$/);
    if (pattern.test(e.target.value) === true) {
      dispatch(setSlug(e.target.value));
    }
  };

  useDebounce(async () => {
    if (prevSlug.current !== slug && slug.length > 0) {
      try {
        const { status } = await getSlug(slug);
        if (status === 'used') {
          form.setFields([
            {
              name: 'slug',
              errors: ['Запись с таким наименованием уже создана'],
            },
          ]);
        }
      } catch (e) {
        errorMessageCatcher(e);
      }
    }
  }, 1000, [slug]);

  return (
    <div>
      <ModalSelect
        disabled={readOnly}
        options={tags}
        validation={[]}
        label="Теги"
        style={{ ...inputStyle, width: '100%' }}
        name="tags"
      />
      <Form.Item
        name="slug"
        label="Технический псевдоним"
        rules={[requiredField]}
        // className={b('info-input')}
        getValueProps={() => ({ value: slug })}
      >
        <Input
          style={{ ...inputStyle }}
          size="large"
          placeholder="Ввод текста"
          value={slug}
          onChange={onChangeSlugInput}
        />
      </Form.Item>
    </div>
  );
}
