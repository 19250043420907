import React, { useState } from 'react';
import './style.less';
import bem from 'easy-bem';
import Button from 'components/button-v2';
import { Tooltip, Form } from 'antd';
import { courseValidationSchema } from 'utils/validation/courseValidationSchema';


const FooterActionsCourse = ({ onClose, form }) => {
  const b = bem('modal-create-course-footer');
  const [disabledBtn, setDisabledBtn] = useState(true);

  const onCheckRequiredForm = async () => {
    const isValid = await courseValidationSchema.isValid(form.getFieldsValue());
    const requiredFieldsErrors = form.getFieldsError().filter(({ errors }) => errors.length)
      .length > 0;
    setDisabledBtn(!isValid || requiredFieldsErrors);
  };

  const submitTooltip = 'Проверьте, что все обязательные поля заполнены. Убедитесь в корректности внесённых данных.';

  return (
    <div className={b()}>
      <Button
        className={b('btn')}
        type="primary"
        ghost
        onClick={onClose}
      >
        <Tooltip title="После отмены восстановление заполненных  полей невозможно">
          <div>
            Отменить
          </div>
        </Tooltip>
      </Button>
      <Form.Item shouldUpdate className={b('btn')}>
        {
          () => {
            onCheckRequiredForm();

            return (
              <Tooltip title={submitTooltip}>
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={disabledBtn}
                  >
                    Сохранить
                  </Button>
                </div>
              </Tooltip>
            );
          }
        }
      </Form.Item>
    </div>
  );
};

export default FooterActionsCourse;

