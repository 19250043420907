import React, { useMemo } from 'react';
import bem from 'easy-bem';
import { Select } from 'antd';

import './style.less';


const { Option } = Select;

const DropdownSearchV2 = ({
  className: classNameProp,
  data = [],
  onSearch,
  onChange,
  children,
  ...rest
}) => {
  const b = bem('dropdown-search-v2');

  const options = useMemo(() => children || data.map(
    (item, key) => (
      <Option key={key} value={item.id} label={item.name}>
        {item.name}
      </Option>
    )
  ), [children, data]);

  const handleFilter = (input, option) => option.label.toLowerCase().includes(input.toLowerCase());

  const cn = classNameProp ? `${classNameProp} ${b()}` : b();

  return (
    <div className={cn}>
      <Select
        size="large"
        showSearch
        autoClearSearchValue={false}
        filterOption={handleFilter}
        dropdownClassName={cn}
        optionLabelProp="label"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        {...rest}
      >
        {options.length > 0 && options}
      </Select>
    </div>
  );
};


export default DropdownSearchV2;
