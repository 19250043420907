import * as c from './constants';


export const fetchContentManagerReport = (query = '') => ({
  type: c.getContentManagerReport.tries,
  payload: query,
});

export const fetchCourseReport = (id) => ({
  type: c.getCourseReport.tries,
  payload: id,
});
