import { Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import bem from 'easy-bem';
import React from 'react';
import './style.less';


const ReviewSelector = ({ title, text }) => {
  const b = bem('title-button');

  return (
    <div>
      <Typography className={b('text')}>
        <Text type="secondary" className={b('title')}>{title}</Text>
        <div className={b('buttons')}>
          {text?.map((item, key) => (
            <Text key={key} className={b('buttonText')}>
              {item.title}
            </Text>
          ))}
        </div>
      </Typography>
    </div>
  );
};

export default ReviewSelector;
