import { errorMessageCatcher } from './request';


export const onCheckedDuplicate = (form, fields, e) => {
  if (e.message.includes('duplicate key')) {
    fields.forEach((field) => {
      if (e.message.includes(field)) {
        form.setFields([
          {
            name: field,
            errors: ['Запись с таким наименованием уже создана'],
          },
        ]);
      }
    });
  } else {
    errorMessageCatcher(e);
  }
};
