import React from 'react';
import bem from 'easy-bem';
import { Input, Select, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import Tag from 'components/tag';
import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/modal-copy.svg';
import { requiredField, rulesValidationOne } from 'utils/validation/validation';
import TextEditor from 'components/text-editor';

import { inputStyle, selectStyle } from '../modal-upload/component';
import ModalAddBtn from '../../../add-more-btn';
import ModalUpload from '../modal-upload';

import './style.less';


const { Option } = Select;
const ModalTeachers = ({ form }) => {
  const b = bem('modal-teachers');
  const g = bem('modal-global-style');

  const copyItem = (add, name) => {
    const copyData = form.getFieldsValue('teachers').teachers[name];
    add(copyData);
  };

  return (
    <div className={b()}>
      <Form.List name="teachers">
        {(cards, { add, remove }) => (
          <>
            <div className={g('container')}>
              {cards.map(({ key, name, ...rest }) => (
                <div key={key} className={g('item')}>
                  <div className={g('header')}>
                    <Tag
                      color="secondary"
                      bgColor="#0071CE"
                      text={`Преподаватель #${name + 1}`}
                    />
                    <div className={g('header-drag')}>
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                    </div>
                    <div className={g('geader-icons')}>
                      <CopyIcon className={g('icon')} onClick={() => copyItem(add, name)} />
                      {cards.length > 1
                        ? <DeleteIcon className={g('icon')} onClick={() => remove(name)} />
                        : null}
                    </div>
                  </div>
                  <div className={b('head-inputs')}>
                    <Form.Item
                      {...rest}
                      name={[name, 'fullName']}
                      label="ФИО преподавателя"
                      rules={[...rulesValidationOne, requiredField]}
                    >
                      <TextArea
                        style={{ ...selectStyle, height: 52 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...rest}
                      name={[name, 'position']}
                      label="Должность"
                      rules={[...rulesValidationOne, requiredField]}
                    >
                      <TextArea
                        style={{ ...selectStyle, height: 52 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                  </div>
                  <div className={b('inputs-block')}>
                    <div className={g('inputs')}>
                      <Form.Item
                        {...rest}
                        name={[name, 'workplace']}
                        label="Место работы"
                        rules={[...rulesValidationOne, requiredField]}
                      >
                        <Input
                          size="large"
                          style={{ ...inputStyle, width: '100%' }}
                          placeholder="Ввод текста"
                          className={b('info-input')}
                        />
                      </Form.Item>
                      <Form.Item
                        {...rest}
                        name={[name, 'role']}
                        label="Роль на курсе"
                        rules={[requiredField]}
                      >
                        <Select
                          style={{ ...inputStyle, width: '100%' }}
                          size="large"
                          placeholder="Выберите роль"
                          className={b('role-select')}
                        >
                          <Option value="curator">Куратор</Option>
                          <Option value="speaker">Спикер</Option>
                          <Option value="teacher">Преподаватель</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <Form.Item
                      {...rest}
                      name={[name, 'additional']}
                      label="Дополнительная информация"
                      rules={[...rulesValidationOne]}
                    >
                      <TextEditor
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        placeholder="Текст, который отобразится, если в курсе только один преподаватель"
                      />
                    </Form.Item>
                    <Form.Item name={[name, 'avatar']} getValueProps={(file) => file}>
                      <ModalUpload
                        initialFile={form.getFieldValue('teachers')[name].avatar}
                        aspect={345 / 375}
                      />
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
            <div className={b('add-more-btn')}>
              <ModalAddBtn
                text="Добавить ещё преподавателя"
                onAdd={() => add({ avatar: '' })}
              />
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ModalTeachers;
