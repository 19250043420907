import { call, put, takeLatest } from 'redux-saga/effects';
import { setToken, removeToken } from 'utils/auth';
import { getUserInfo, onAuth } from './api';
import * as c from './constants';


function* getUserSaga() {
  const { success, failure } = c.getUser;
  const { success: loadingSuccess } = c.setLoading;
  try {
    const token = yield window.unione.getUnioneToken();
    if (token) {
      setToken(token);
      yield call(onAuth);
      const user = yield call(getUserInfo);
      yield put({ type: success, payload: user.user });
    } else {
      yield window.unione.removeUnioneToken();
      removeToken();
    }


    yield put({ type: loadingSuccess, payload: false });
  } catch (e) {
    yield put({ type: failure, payload: { message: e.message } });
    yield put({ type: loadingSuccess, payload: false });
  }
}

function* goToAuthSaga() {
  yield window.unione.goToAuth();
}

export default function* rootSaga() {
  yield takeLatest(c.getUser.tries, getUserSaga);
  yield takeLatest(c.goToAuth.success, goToAuthSaga);
}
