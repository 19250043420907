import React, { useState } from 'react';
import {
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLevel, createLevel } from 'models/manualControlOfCouses/actions';
import { deleteEducationLevel } from 'models/manualControlOfCouses/api';
import bem from 'easy-bem';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import { ReactComponent as Asteriks } from 'assets/emoticons/asteriksIcon.svg';
import { getEducationLevels } from 'models/manualControlOfCouses/selectors';
import { errorMessageCatcher } from 'utils/request';
import './style.less';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';
import { successMessage } from 'utils/message';


function ModalEducationLevel({
  onClose, isVisibleModal, isDelete, title, id
}) {
  const b = bem('education-level-modal');
  const [level, setLevel] = useState('');
  const [error, setError] = useState('');
  const symbols = /[!@$%^&*_\-=\\|,<>/?]/;
  const dispatch = useDispatch();
  const educationLevels = useSelector(getEducationLevels);

  const onCloseHandler = () => {
    onClose();
    setLevel('');
  };

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setLevel(value);
    if (symbols.test(value)) {
      setError('Поле может содержать только текст');
    } else {
      setError('');
    }
  };

  const handleDeleteLevel = async () => {
    try {
      const response = await deleteEducationLevel(id);
      dispatch(deleteLevel(response.data._id));
      successMessage('Уровень сложности удален!');
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  const addEducationLevel = () => {
    if (educationLevels.items.find((item) => item.title.toLocaleLowerCase() === level.toLocaleLowerCase().trim())) {
      setError('Запись с таким наименованием уже создана');

      return;
    }

    if (level.length >= 200) {
      setError('Запись не может быть длинее 200 символов');

      return;
    }

    const newLevel = {
      title: level.trim(),
      weight: 0,
      status: 'active',
    };
    dispatch(createLevel(JSON.stringify(newLevel)));
    onCloseHandler();
  };


  return (
    <ModalV2
      title={title}
      visible={isVisibleModal}
      width={isDelete ? '707px' : '800px'}
      footer=""
      onClose={onCloseHandler}
    >
      {
        isDelete ? (
          <>
            <div className={b('image')}>
              <DoneWarning />
              <p className={b('text')}>
                Вы уверены, что хотите удалить уровень сложности?
              </p>
            </div>

            <div className={b('action')}>
              <Button type="primary" ghost onClick={onCloseHandler}>Отменить</Button>
              <Button type="primary" onClick={handleDeleteLevel}>Удалить</Button>
            </div>
          </>
        )
          : (
            <div>
              <div className={b('input-wrapper')}>
                <p className={b('input-wrapper-title')}>
                  Уровень сложности
                  <i className={b('asteriks')}><Asteriks /></i>
                </p>
                <InputV2
                  size="large"
                  placeholder="Введите текст"
                  onChange={onChangeHandler}
                  value={level}
                  status={error && 'error'}
                />
                <b className={b('error')}>{error}</b>
              </div>
              <div className={b('action')}>
                <Tooltip
                  className={b('tooltip')}
                  placement="top"
                  title={!level ? 'Заполните все обязательные поля' : ''}
                >
                  <div className={b('btn-block')}>
                    <Button
                      type="primary"
                      ghost
                      onClick={onCloseHandler}
                    >
                      Отменить
                    </Button>
                    <Button
                      onClick={addEducationLevel}
                      type="primary"
                      disabled={!level}
                    >
                      Сохранить
                    </Button>
                  </div>

                </Tooltip>
              </div>
            </div>
          )
      }

    </ModalV2>
  );
}

export default ModalEducationLevel;
