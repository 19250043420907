import React from 'react';
import { useDispatch } from 'react-redux';
import { onPublishCourse as onPublishCourseFromApi } from 'models/courses/api';
import { successMessage } from 'utils/message';

import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import { errorMessageCatcher } from 'utils/request';
import { onPublishCourse } from 'models/courses/actions';
import ModalAdminCourse from '../component';


const PublicationCourseModal = ({ isModalVisible, onClose, id }) => {
  const dispatch = useDispatch();
  const onClick = async () => {
    try {
      const response = await onPublishCourseFromApi(id);
      dispatch(onPublishCourse(response));
      successMessage('Курс опубликован!');
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  return (
    <ModalAdminCourse
      title="Опубликовать курс"
      visible={isModalVisible}
      width="707px"
      onClose={onClose}
      isModalVisible={isModalVisible}
      onHandleClick={onClick}
      icon={<DoneWarning />}
      contentText="Убедитесь, что все поля курса заполнены корректно,
          редактирование после публикации возможно, но не рекомендовано. Опубликовать курс?"
      submitText="Опубликовать курс"
      buttonWidth={180}
    />
  );
};

export default PublicationCourseModal;
