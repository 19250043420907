import React, { useState } from 'react';
import {
  Form,
  Divider,
  Select
} from 'antd';
import bem from 'easy-bem';
import './style.less';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';
import { requiredField } from 'utils/validation/validation';
import dayjs from 'dayjs';
import { inputStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import { getPublishedCourses } from 'models/courses/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createPromo } from 'models/blogAndAdvertising/actions';
import DatePicker from 'components/datepicker';
import { getFullNameUser } from 'utils/get-fullname-user';
import ModalUpload from 'components/modal/modal-course/components/form/components/modal-upload';


// eslint-disable-next-line consistent-return
export const avoidColors = (color) => {
  switch (color) {
    case '#FFEEFA':
      return [{ title: '#E369C1' }];
    case '#EDEBFF':
      return [{ title: '#7D6FE1' }];
    case '#E1F2FF':
      return [
        { title: '#0071CE' },
        { title: '#50B7E0' }
      ];
    case '#D6F9FA':
      return [
        { title: '#50B7E0' },
        { title: '#00C2C4' }
      ];
    case '#D4F6D4':
      return [{ title: '#73D071' }];
    case '#FEF1E1':
      return [{ title: '#F7B055' }];
    default:
      break;
  }
};

const { Option } = Select;
function ModalCreatePromo({
  onClose,
  isModalVisible = false,
}) {
  const b = bem('promo-modal');

  const [form] = Form.useForm();
  const courses = useSelector(getPublishedCourses).items;
  const [author, setAuthor] = useState('');
  const [disableOption, setDisableOption] = useState(true);
  const [promoColor, setPromoColor] = useState(null);
  const dispatch = useDispatch();

  const onCloseHandler = () => {
    form.resetFields();
    onClose();
    setAuthor('');
  };

  const onSubmit = (promo) => {
    dispatch(createPromo(JSON.stringify(promo)));
    onCloseHandler();
  };

  const onCourseChange = (id) => {
    const { userCreator } = courses.find((elem) => elem._id === id);
    const { cardColor } = courses.find((elem) => elem._id === id);
    setAuthor(getFullNameUser(userCreator, true));

    setDisableOption(false);
    setPromoColor(avoidColors(cardColor));
  };


  return (
    <ModalV2
      title="Добавить промоакцию"
      closable={false}
      visible={isModalVisible}
      onClose={onCloseHandler}
      footer=""
    >
      <Form
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        form={form}
      >
        <Form.Item label="Выберите курс" name="course" rules={[requiredField]}>
          <Select
            style={{ ...inputStyle, width: '100%' }}
            size="large"
            placeholder="Выберите курс"
            onChange={onCourseChange}
          >
            {courses.map((item, key) => (
              <Option value={item._id} key={key}>{item.title}</Option>
            ))}
          </Select>
        </Form.Item>
        <InputV2
          label="Aвтор курса"
          type="form"
          disabled
          value={author}
        />
        <Form.Item
          name="endDate"
          label="Дата окончания промоакции"
        >
          <DatePicker
            placeholder="Ввод даты"
            style={{ ...inputStyle, width: 210 }}
            disabledDate={(current) => current < dayjs().subtract(1, 'days')}
          />
        </Form.Item>
        <Form.Item label="Цвет карточки промо" name="cardColor" rules={[requiredField]}>
          <Select
            disabled={disableOption}
            style={{ ...inputStyle, width: '100%' }}
            size="large"
            placeholder="Выберите цвет"
          >
            { promoColor !== null && promoColor.map((item, key) => (
              <Option value={item?.title} key={key}>
                <div className={b('option')}>
                  <div className={b('color')} style={{ background: `${item?.title}` }} />
                  {item?.title}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Иконка промо (SVG)"
          name="cardLogoSvg"
          getValueProps={(file) => file}
        >
          <ModalUpload
            crop={false}
            accept=".svg"
            initialFile={form?.getFieldValue('cardLogoSvg')}
          />
        </Form.Item>
        <Divider className={b('divider')} />
        <div className={b('footer')}>
          <Button
            onClick={onCloseHandler}
            type="primary"
            ghost
          >
            Отменить
          </Button>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                className="buttonSubmit"
                type="primary"
                disabled={
                  !form.isFieldTouched('course')
                    || form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                }
              >
                Сохранить
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </ModalV2>
  );
}

export default ModalCreatePromo;
