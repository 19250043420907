export const getUserRole = (state) => state.user.data.role;

export const getPhotoUrl = (state) => state.user.data.photoUrl;

export const getUserInfo = (state) => state.user.data;

export const getUserId = (state) => state.user.data._id;

export const getLoading = (state) => state.user.state.loading;

export const isAuth = (state) => state.user.state.isAuth;
