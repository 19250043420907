/* eslint-disable max-len */
import bem from 'easy-bem';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as InnopolisLogo } from 'assets/images/innopolis/logo-with-title.svg';
import { ReactComponent as Telegram } from 'assets/icons/social-media/telegram.svg';
import { ReactComponent as YouTube } from 'assets/icons/social-media/youtube.svg';
import { ReactComponent as VcRu } from 'assets/icons/social-media/vcru.svg';
import { ReactComponent as VK } from 'assets/icons/social-media/vk.svg';
import slug from 'slug';
import './style.less';
import { getEducationProfiles } from 'models/manualControlOfCouses/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { setFilter } from 'models/common/actions';
import { queryStringify } from 'utils/queryStringify';
import { getFilter } from 'models/common/selector';


const helpfulInformation = [
  {
    title: 'О нас',
    path: 'https://innopolis.university/ido/',
  },
  {
    title: 'Каталог курсов',
    path: '/courses',
  },
  {
    title: 'Специальное предложение',
    path: '/compilation/',
  },
  {
    title: 'Корпоративное обучение',
    path: 'https://unionepro.ru/forbusiness',
  },
  {
    title: 'Новости',
    path: 'https://innopolis.university/ido/#block4245',
  },
  {
    title: 'Календарь мероприятий',
    path: 'https://events.innopolis.university/',
  },
  {
    title: 'Блог',
    path: 'https://stc.innopolis.university/blog',
  },
];

const contacts = [
  {
    title: 'unionepro.support@innopolis.ru',
    path: 'mailto:unionepro.support@innopolis.ru',
  },
  {
    title: '@unionepro_bot',
    path: 'https://t.me/unionepro_bot',
  },
  {
    title: '8 800 550 31 71',
    path: 'tel:+996712345678'
  },
  {
    title:
  <span>
    420500, г. Иннополис,
    <br />
    ул. Университетская, д.1
  </span>,
  },
];
const icons = [
  {
    icon: <YouTube />,
    path: 'https://www.youtube.com/@innopolisuniversityido',
  },
  {
    icon: <VK />,
    path: 'https://vk.com/edtechiu',
  },
  {
    icon: <Telegram />,
    path: 'https://t.me/innokadriu',
  },
  {
    icon: <VcRu />,
    path: 'https://vc.ru/u/1271854-it-obrazovanie-v-innopolise',
  }
];

const legalInformation = [
  {
    title: 'Информация о платформе',
    path: 'https://unionepro.ru/information',
  },
  {
    title: 'Лицензия',
    path: 'https://innopolis.university/upload/iblock/d7f/%D0%9B%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D1%8F%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf',
  },
  {
    title: 'Выписка из реестра лицензий',
    path: 'https://innopolis.university/upload/iblock/b6f/ozxoa1j463v3s0m55w9kdc1qc0z31zfr/%D0%A0%D0%B5%D0%B5%D1%81%D1%82%D1%80%D0%BE%D0%B2%D0%B0%D1%8F%20%D0%B2%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B0.pdf',
  },
  {
    title: 'Политика конфиденциальности',
    path: 'https://innopolis.university/files/politicacookies.pdf',
  },
];

const regex = new RegExp('/courses/[a-zA-Z]+-[a-zA-Z]');
const regexForMainPage = new RegExp('/courses#[a-zA-Z]+$');
const Footer = () => {
  const b = bem('footer');
  const educationProfiles = useSelector(getEducationProfiles).items;
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef(null);

  const history = useHistory();
  const filter = useSelector(getFilter);

  useEffect(() => {
    if (regex.test(location.pathname)) {
      ref.current.classList.add('footer--dark');
    } else {
      ref.current.classList.remove('footer--dark');
    }
  }, [location.pathname]);

  const handleFilterClick = (item) => {
    if (regexForMainPage.test(location.pathname + location.hash)) {
      dispatch(setFilter({
        tags: [],
        educationType: '',
        levels: [],
        special: false,
        educationProfiles: [slug(item.title)],
      }));
      history.push({
        pathname: '/courses',
        search: queryStringify(filter)
      });

      return;
    }

    dispatch(setFilter({ educationProfiles: [slug(item.title)] }));
    const coursesFilters = document.getElementsByClassName('courses-tabs-pane__title');
    coursesFilters[0].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div ref={ref} className={b()}>
      <div className={b('data')}>
        <div className={b('logo')}>
          <InnopolisLogo />
        </div>
        <div className={b('educationProfiles')}>
          <h3 className={b('list-heading')}>Направления обучения</h3>
          <ul>
            {educationProfiles?.map((item, index) => (
              <li
                key={index}
                onClick={() => handleFilterClick(item)}
                role="presentation"
                onKeyDown={() => {}}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        <div className={b('helpfulInformation')}>
          <h3 className={b('list-heading')}>Полезная информация</h3>
          <ul>
            {helpfulInformation.map((item, index) => (
              <li key={index}>
                <a target="_blank" href={item.path} rel="noreferrer">{item.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className={b('contact')}>
          <h3 className={b('list-heading')}>Контакты</h3>
          <ul>
            {contacts
              .map((item, index) => (
                <li key={index}>
                  { item.path ? <a href={item.path}>{item.title}</a> : item.title }
                </li>
              ))}
          </ul>
          <div className={b('social-media')}>
            {icons.map((item, index) => (
              <li key={index}>
                <a target="_blank" href={item.path} rel="noreferrer">
                  {item.icon}
                </a>
              </li>
            ))}
          </div>
        </div>
        <div className={b('contact')}>
          <h3 className={b('list-heading')}>Правовая информация</h3>
          <ul>
            {legalInformation
              .map((item, index) => (
                <li key={index}><a href={item.path}>{item.title}</a></li>
              ))}
          </ul>
        </div>

      </div>
      <Divider />
      <div className={b('bottom')}>
        <p>© Университет Иннополис, 2023</p>
        <span>
          Мы
          {' '}
          <a href="https://innopolis.university/files/politicacookies.pdf">используем файлы cookie</a>
          , для персонализации сервисов и повышения удобства пользования сайтом.
          <br />
          Если вы не согласны на их использование, поменяйте настройки браузера.
        </span>
      </div>
    </div>
  );
};

export default Footer;
