import bem from 'easy-bem';
import React from 'react';
import { Divider } from 'antd';
import CardTag from 'components/card-tag';
import { CompetenceIcon } from 'components/competence-icon';
import TitleText from '../../title-text-component';
import './style.less';
import FileNameArea from '../../file-name-area';


const CompetenciesReview = ({
  competencies, digitalProfessions, anotherStatePrograms, stateProgramEmployment
}) => {
  const b = bem('competencies-review');

  return (
    <div className={b()}>
      {competencies.length > 0 && competencies.map((item, key) => (
        <div key={key}>
          <div className={b('competencies-item')}>
            <TitleText label="Название компетенции" text={item.title} />
            <TitleText label="Описание компетенции" text={item.description} />
            <div>
              <TitleText label="Иконка" text={item.icon ? '' : 'нет'} />
              <CompetenceIcon competenceType={item.icon} />
            </div>
          </div>
          <Divider />
        </div>
      ))}
      { digitalProfessions.length > 0 && <CardTag color="blue" text="Цифровая профессия" /> }
      { digitalProfessions.map((item, key) => (
        <div className={b('digitalProfessions')} key={key}>
          <TitleText label="Участник" text={item.participant} />
          <TitleText label="Описание" html={item.description} />
        </div>
      ))}
      { stateProgramEmployment.length > 0 && <CardTag color="blue" text="Содействие занятости" /> }
      { stateProgramEmployment.map((item, key) => (
        <div className={b('digitalProfessions')} key={key}>
          <TitleText label="Участник" text={item.participant} />
          <TitleText label="Описание" html={item.description} />
        </div>
      ))}
      {
        anotherStatePrograms.map((item, key) => (
          <div className={b('anotherStatePrograms')} key={key}>
            <CardTag color="blue" text="Госпрограмма" />
            <div className={b('banners')} key={key}>
              <TitleText label="Обложка для десктоп" />
              {item.bannerDesktop ? <FileNameArea title={item.bannerDesktop} />
                : <p>Нет</p>}
              <Divider />
              <TitleText label="Обложка для планшета" />
              {item.bannerTablet ? <FileNameArea title={item.bannerTablet} />
                : <p>Нет</p>}
              <Divider />
              <TitleText label="Обложка для мобильной версии" />
              {item.bannerPhone ? <FileNameArea title={item.bannerPhone} />
                : <p>Нет</p>}
              <Divider />
            </div>
            <TitleText label="URL" link={item.url} />
          </div>
        ))
      }
    </div>
  );
};

export default CompetenciesReview;
