import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';

import {
  setIsMobile,
  setIsTablet,
  setIsLaptop
} from 'models/common/actions';
import routes from 'utils/routes';
import ScrollToTop from 'components/scroll-to-top';
import { LimitedAccessModalProvider } from 'components/limited-access-modal';

import './style.less';
import Footer from 'components/footer/component';


// breakpoints
// const breakpointLaptopLarge = 1920; // by design desktop width
// const breakpointLaptopMedium = 1600; // key breakpoint
const breakpointLaptopSmall = 1200;


// const breakpointTabletLarge = 1024; // by design tablet width
// const breakpointTabletMedium = 992;
const breakpointTabletSmall = 768;

// const breakpointMobileLarge = 576; // key breakpoint
// const breakpointMobileMedium = 480;
// const breakpointMobileSmall = 375; // by design phone width


const PageWrapper = () => {
  const b = bem('page-wrapper');
  const contentRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMedia(`(max-width: ${breakpointTabletSmall - 1}px)`);
  const isTablet = useMedia(
    `(min-width: ${breakpointTabletSmall}px) and (max-width: ${breakpointLaptopSmall - 1}px)`
  );
  const isLaptop = useMedia(`(min-width: ${breakpointLaptopSmall}px)`);

  useEffect(() => dispatch(setIsMobile(isMobile)), [isMobile, dispatch]);
  useEffect(() => dispatch(setIsTablet(isTablet)), [isTablet, dispatch]);
  useEffect(() => dispatch(setIsLaptop(isLaptop)), [isLaptop, dispatch]);


  return (
    <div className={b()}>
      <LimitedAccessModalProvider>
        <Router>
          <div className={b('container')}>
            <div
              ref={contentRef}
              className={b('content')}
            >
              <ScrollToTop />
              { routes }
              <Footer />
            </div>
          </div>
        </Router>
      </LimitedAccessModalProvider>
    </div>
  );
};

export default PageWrapper;
