import { BACKEND_HOST } from 'utils/env';
import { get as oGet, post as oPost } from 'utils/request';


export const TEMP_USER_API = `${BACKEND_HOST}/api/v1`;


const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };
export const getUserInfo = () => {
  const url = '/profile';

  return oGet(url, HEADERS, TEMP_USER_API);
};
export const onAuth = () => {
  const url = '/auth';

  return oPost(url, '', '', false, TEMP_USER_API);
};
