import React from 'react';
import { Modal, Tooltip } from 'antd';
import bem from 'easy-bem';
import { PlusOutlined } from '@ant-design/icons';
import './style.less';


const ModalV2 = (props) => {
  const b = bem('modal-v2');
  const {
    className: propsClass,
    children,
    closable = false,
    title,
    onClose,
    tooltipClose = '',
    ...rest
  } = props;

  const cn = propsClass ? `${b()} ${propsClass}` : b();

  return (
    <Modal
      className={cn}
      closable={closable}
      title={(
        <div className={b('modal-header')}>
          <p className={b('title')}>{title}</p>
          <div className={b('close-btn')}>
            <Tooltip title={tooltipClose}>
              <PlusOutlined
                className={b('close-btn-icon')}
                onClick={onClose}
              />
            </Tooltip>
          </div>
        </div>
      )}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ModalV2;
