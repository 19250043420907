import React from 'react';
import bem from 'easy-bem';
import './style.less';


function Tag({ text, color, bgColor }) {
  const b = bem('tag');

  return (
    <p style={{ background: bgColor }} className={`${b()} ${b(color)}`}>
      {text}
    </p>
  );
}

export default Tag;
