import React, { useEffect, useState } from 'react';
import './style.less';
import bem from 'easy-bem';
import TextArea from 'antd/es/input/TextArea';
import {
  Form, Switch, Radio, Select
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { rulesValidationOne } from 'utils/validation/validation';
import FileDragger from 'components/dragger';
import Tag from 'components/tag';
import TextEditor from 'components/text-editor';
import { CompetenceIcon, competenceIconsList } from 'components/competence-icon';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestions } from 'models/manualControlOfCouses/selectors';
import ModalAddBtn from '../../../add-more-btn';
import { inputStyle, selectStyle } from '../modal-upload/component';
import initialValuesEmployment from './initialValuesEmployment.json';
import { fetchQuestions } from '../../../../../../../models/manualControlOfCouses/actions';
import { tagRender } from '../modal-select/component';


const { Option } = Select;
const Competence = ({ form }) => {
  const b = bem('competence-modal');
  const g = bem('modal-global-style');
  const hasFieldsValue = (field) => form.getFieldValue(field)?.length && form.getFieldValue(field);
  const hasAnotherStateProgramsValue = hasFieldsValue('anotherStatePrograms');
  const hasDigitalProfessionsValue = hasFieldsValue('digitalProfessions');
  const hasStateProgramEmploymentValue = hasFieldsValue('stateProgramEmployment');
  const questions = useSelector(getQuestions).items;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchQuestions());
  }, [dispatch]);


  const [choosenGosProgram, setChoosenGosProgram] = useState('stateProgramEmployment');

  const [gosPrograms, setGosPrograms] = useState(
    hasAnotherStateProgramsValue || hasDigitalProfessionsValue || hasStateProgramEmploymentValue
  );
  useEffect(() => {
    let gosProgram;
    if (hasAnotherStateProgramsValue) {
      gosProgram = 'anotherStatePrograms';
    } else if (hasDigitalProfessionsValue) {
      gosProgram = 'digitalProfessions';
    } else {
      gosProgram = 'stateProgramEmployment';
    }

    setChoosenGosProgram(gosProgram);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gosPrograms]);


  const handleChangeDigitPrograms = (isDigitPrograms) => {
    setGosPrograms(isDigitPrograms);

    if (isDigitPrograms
      && !hasStateProgramEmploymentValue && !hasAnotherStateProgramsValue && !hasDigitalProfessionsValue) {
      form.setFieldValue('stateProgramEmployment', initialValuesEmployment?.data);
    } else {
      form.setFieldValue('anotherStatePrograms', null);
      form.setFieldValue('stateProgramEmployment', null);
      form.setFieldValue('digitalProfessions', null);
    }
  };

  const onValuesChange = (e) => {
    const { value } = e.target;
    setChoosenGosProgram(value);
    if (value === 'stateProgramEmployment') {
      form.setFieldValue('stateProgramEmployment', initialValuesEmployment?.data);
    } else {
      form.setFieldValue(value, ['']);
    }
  };

  return (
    <div className={b()}>
      <div className={b('info')}>
        <div className={b('info-header')}>
          <Tag
            color="secondary"
            bgColor="#0071CE"
            text="Компетенции"
          />
        </div>
        <Form.List name="competencies">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }) => (
                <div key={key} className={b('item')}>
                  <div className={g('inputs')} style={{ gridTemplateColumns: '1fr 1fr 100px' }}>
                    <Form.Item
                      {...rest}
                      name={[name, 'title']}
                      label="Название компетенции"
                      rules={[...rulesValidationOne]}
                    >
                      <TextArea
                        style={{ ...selectStyle, height: 52 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...rest}
                      name={[name, 'description']}
                      label="Описание компетенции"
                      rules={[...rulesValidationOne]}
                    >
                      <TextArea
                        style={{ ...selectStyle, height: 52 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Иконка"
                      name={[name, 'icon']}
                    >
                      <Select
                        defaultValue="pen"
                        style={{ ...inputStyle, width: 100 }}
                        size="large"
                        placeholder="Выберите значение"
                      >
                        {Array.from(competenceIconsList.keys()).map((iconKey) => (
                          <Select.Option value={iconKey} key={iconKey}>
                            <CompetenceIcon competenceType={iconKey} className={b('icon')} />
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={g('geader-icons')}>
                    { fields.length > 1
                      && <DeleteIcon onClick={() => remove(name)} />}
                  </div>
                </div>
              ))}
              <Form.Item>
                <ModalAddBtn onAdd={() => add()} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className={b('info')}>
        <div className={b('info-header')}>
          <Tag
            color="secondary"
            bgColor="#0071CE"
            text="Целевая аудитория"
          />
        </div>
        <Form.List name="applicants">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }) => (
                <div key={key} className={b('item')}>
                  <Form.Item
                    {...rest}
                    name={[name, 'title']}
                    label="Соискатель"
                  >
                    <TextArea
                      style={{ ...selectStyle, height: 52 }}
                      size="large"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      placeholder="Ввод текста"
                      className={b('info-input')}

                    />
                  </Form.Item>
                  <div className={g('item-close')}>
                    { fields.length > 1
                      && <PlusOutlined onClick={() => remove(name)} />}
                  </div>
                </div>
              ))}
              <Form.Item>
                <ModalAddBtn onAdd={() => add()} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className={b('info')}>
        <div className={b('info-header')}>
          <Tag
            color="secondary"
            bgColor="#0071CE"
            text="Часто задаваемые вопросы"
          />
        </div>
        <Form.Item className={b('questions-select')} label="Выберите вопрос" name="questions">
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            style={{
              ...inputStyle, width: '100%', height: 'auto', borderRadius: '4px'
            }}
            size="large"
            placeholder="Выбрать вопрос"
          >
            {questions.map((item, key) => (
             
              <Option value={item._id} key={key}>
                {item.simpleId}
                {' '}
                :
                {' '}
                {item.text.length > 110 ? `${item.text.slice(0, 110)}...` : item.text}
              </Option>
             
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={b('info')}>
        <div className={b('info-header')}>
          <Tag
            color="secondary"
            bgColor="#0071CE"
            text="Госпрограмма"
          />
          <div className={b('checkbox')}>
            <Switch checked={gosPrograms} onChange={handleChangeDigitPrograms} />
            <p className={b('checkbox-label')}>Наличие госпрограммы</p>
          </div>
        </div>
        <div className={b('digital', { hidden: !gosPrograms })}>
          <Radio.Group name="scale" onChange={onValuesChange} value={choosenGosProgram}>
            <Radio className={b('input')} value="digitalProfessions">Цифровые профессии</Radio>
            <Radio className={b('input')} value="stateProgramEmployment">Содействие занятости</Radio>
            <Radio className={b('input')} value="anotherStatePrograms">Другая госпрограмма</Radio>
          </Radio.Group>
          {choosenGosProgram === 'digitalProfessions' && (
            <Form.List name="digitalProfessions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...rest }) => (
                    <div key={key} className={`${b('govement')} ${b('item')}`}>
                      <div className={g('inputs')}>
                        <Form.Item
                          {...rest}
                          name={[name, 'participant']}
                          label="Участник"
                        >
                          <TextArea
                            style={{ ...selectStyle, height: 52 }}
                            size="large"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            placeholder="Ввод текста"
                            className={b('info-input')}
                          />
                        </Form.Item>
                        <Form.Item
                          {...rest}
                          name={[name, 'description']}
                          label="Описание"
                        >
                          <TextEditor
                            size="large"
                            placeholder="Ввод текста"
                            className={b('info-input')}
                          />
                        </Form.Item>
                      </div>
                      <div className={g('item-close')}>
                        { fields.length > 1
                        && <PlusOutlined onClick={() => remove(name)} />}
                      </div>
                    </div>
                  ))}
                  <Form.Item>
                    <ModalAddBtn onAdd={() => add()} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          {choosenGosProgram === 'stateProgramEmployment' && (
            <Form.List name="stateProgramEmployment">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...rest }) => (
                    <div key={key} className={`${b('govement')} ${b('item')}`}>
                      <div className={g('inputs')}>
                        <Form.Item
                          {...rest}
                          name={[name, 'participant']}
                          label="Участник"
                        >
                          <TextArea
                            style={{ ...selectStyle, height: 52 }}
                            size="large"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            placeholder="Ввод текста"
                            className={b('info-input')}
                          />
                        </Form.Item>
                        <Form.Item
                          {...rest}
                          name={[name, 'description']}
                          label="Описание участника"
                        >
                          <TextEditor
                            size="large"
                            placeholder="Ввод текста"
                            className={b('info-input')}
                          />
                        </Form.Item>
                      </div>
                      <div className={g('item-close')}>
                        { fields.length > 1
                        && <PlusOutlined onClick={() => remove(name)} />}
                      </div>
                    </div>
                  ))}
                  <Form.Item>
                    <ModalAddBtn onAdd={() => add()} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          {choosenGosProgram === 'anotherStatePrograms' && (
            <Form.List name="anotherStatePrograms">
              {
                ((otherProgram) => (
                  <>
                    {otherProgram.map(({ key, name, ...rest }) => (
                      <div className={b('digital')} key={key}>
                        <Form.Item
                          label={<span className={b('digit-label')}>Баннер для десктоп</span>}
                          name={[name, 'bannerDesktop']}
                          getValueProps={(file) => file}
                          {...rest}
                        >
                          <FileDragger
                            accept=".png, .jpeg, .jpg"
                            fileType={['png', 'jpeg']}
                            text="Загрузите баннер размером 1920х200px, максимальный размер 10мб"
                            initialFile={form.getFieldValue('anotherStatePrograms')[name]?.bannerDesktop || ''}
                            maxLength={1}
                            aspect={1920 / 105}
                            special
                            crop
                          />
                        </Form.Item>
                        <Form.Item
                          label={<span className={b('digit-label')}>Баннер для планшета</span>}
                          name={[name, 'bannerTablet']}
                          getValueProps={(file) => file}
                          {...rest}
                        >
                          <FileDragger
                            accept=".png, .jpeg, .jpg"
                            fileType={['png', 'jpeg']}
                            initialFile={form.getFieldValue('anotherStatePrograms')[name]?.bannerTablet || ''}
                            text="Загрузите баннер размером 1024х136px, максимальный размер 10мб"
                            maxLength={1}
                            aspect={1024 / 136}
                            special
                            crop
                          />

                        </Form.Item>
                        <Form.Item
                          label={<span className={b('digit-label')}>Баннер для мобильной версии</span>}
                          name={[name, 'bannerPhone']}
                          getValueProps={(file) => file}
                          {...rest}
                        >
                          <FileDragger
                            accept=".png, .jpeg, .jpg"
                            fileType={['png', 'jpeg']}
                            initialFile={form.getFieldValue('anotherStatePrograms')[name]?.bannerPhone || ''}
                            text="Загрузите баннер размером 375х224px, максимальный размер 10мб"
                            maxLength={1}
                            aspect={375 / 224}
                            special
                            crop
                          />
                        </Form.Item>

                        <Form.Item
                          name={[name, 'url']}
                          label="URL"
                          {...rest}
                        >
                          <TextArea
                            style={{ ...selectStyle, height: 52 }}
                            size="large"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            placeholder="Ввод текста"
                            className={b('info-input')}
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </>
                ))
              }
            </Form.List>
          )}

        </div>
      </div>
    </div>
  );
};

export default Competence;
