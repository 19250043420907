// @ts-nocheck
import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import * as c from './constants';
import {
  getBanner,
  getBannerCourses,
  getBannerReviews
} from './api';


function* getBannerSaga({ payload, misc }) {
  const { success, failure } = c.getBanner;
  try {
    const banner = yield call(getBanner, payload);
    const courses = yield call(getBannerCourses, payload);
    const reviews = yield call(getBannerReviews, payload);
    yield put({ type: success, payload: { banner, courses, reviews } });
    if (misc.resolve) {
      misc.resolve();
    }
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
    if (misc.reject) {
      misc.reject(e);
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getBanner.tries, getBannerSaga);
}
