import React, { useEffect, useState } from 'react';
import './style.less';
import bem from 'easy-bem';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InputV2 from 'components/input-v2';
import Button from 'components/button-v2';
import { useSelector } from 'react-redux';
import { getPublishedCourses } from 'models/courses/selectors';
import { getPosts } from 'models/blogAndAdvertising/api';


export default function Recommendation({ readOnly }) {
  const b = bem('recommendation');
  const courses = useSelector(getPublishedCourses).items;
  const [posts, setPosts] = useState([]);
  const fetchPosts = async () => {
    const responsePosts = await getPosts('status=published');
    setPosts(responsePosts.items);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div>
      <h2 className={b('title')}>Рекомендации курсов</h2>
      <Form.List name="courses">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, ...rest }) => (
              <div key={key} className={b('courses-item')}>
                <InputV2
                  type="select"
                  options={courses}
                  disabled={readOnly}
                  placeholder="Название курса"
                  label="Справочник курсов"
                  {...rest}
                />
                <PlusOutlined
                  className={b('icon-delete')}
                  onClick={() => !readOnly && remove(key)}
                />
              </div>
            ))}
            {fields.length <= 3 && (
              <Button
                className={b('course-add-button')}
                type="text"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={readOnly}
              >
                добавить ещё
              </Button>
            )}
          </>
        )}
      </Form.List>
      <h2 className={b('title')}>Рекомендации статей</h2>
      <Form.List name="posts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, ...rest }) => (
              <div key={key} className={b('courses-item')}>
                <InputV2
                  type="select"
                  options={posts}
                  disabled={readOnly}
                  placeholder="Название статьи"
                  label="Статья блога"
                  {...rest}
                />
                <PlusOutlined className={b('icon-delete')} onClick={() => !readOnly && remove(key)} />
              </div>
            ))}
            {fields.length <= 3 && (
              <Button
                className={b('course-add-button')}
                type="text"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={readOnly}
              >
                добавить ещё
              </Button>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
}
