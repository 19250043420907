import React from 'react';
import { Divider, Form, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { requiredField } from 'utils/validation/validation';
import Button from 'components/button-v2';
import bem from 'easy-bem';
import './style.less';
import { selectStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';


const FormDirection = ({ onSubmitHandler, onClose, initialValues }) => {
  const b = bem('profiles-form-modal');
  const [form] = Form.useForm();
  const onSubmit = (direction) => {
    onSubmitHandler(direction);
  };

  const disableBtn = () => (!form.isFieldTouched('title') && !initialValues)
  || form.getFieldsError().filter(({ errors }) => errors.length)
    .length > 0;

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        required
        name="title"
        label="Название направления"
        rules={[requiredField]}
      >
        <TextArea
          style={{ ...selectStyle, height: 52 }}
          size="large"
          autoSize={{ minRows: 1, maxRows: 2 }}
          placeholder="Ввод текста"
        />
      </Form.Item>
      <Form.Item
        required
        rules={[requiredField]}
        name="status"
        label="Cтатус"
        initialValue="draft"
      >
        <Radio.Group name="status">
          <Radio value="active">Активен</Radio>
          <Radio value="draft">Черновик</Radio>
        </Radio.Group>
      </Form.Item>
      <Divider className={b('divider')} />
      <div className={b('footer')}>
        <Button type="primary" ghost onClick={onClose}>Отменить</Button>
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                disableBtn()
              }
            >
              Сохранить
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormDirection;
