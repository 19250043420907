import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import bem from 'easy-bem';

import './style.less';


const modulesDefault = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['clean'],
  ],
};

const formatsDefault = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
];

const TextEditorV2 = (props) => {
  const b = bem('text-editor-v2');
  const {
    value,
    onChange,
    theme,
    modules,
    formats,
    className,
    ...rest
  } = props;

  const cn = className ? `${className} ${b()}` : b();

  return (
    <ReactQuill
      {...rest}
      className={cn}
      theme={theme ?? 'snow'}
      value={value || ''}
      modules={modules ?? modulesDefault}
      formats={formats ?? formatsDefault}
      onChange={onChange}
    />
  );
};

export default TextEditorV2;
