/* eslint-disable no-param-reassign */
import React from 'react';
import { Form, Tabs, Divider } from 'antd';
import { useSelector } from 'react-redux';
import ModalSelect from 'components/modal/modal-course/components/form/components/modal-select';
import { inputStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import { getTags } from 'models/manualControlOfCouses/selectors';
import './style.less';
import bem from 'easy-bem';
import BlogArticle from './components/blogArticle';
// import VisualContent from './components/visualContent';
// import Citation from './components/citation';
import Recommendation from './components/recommendation';
import TagsAndSlug from './components/tagsAndSlug';


const { TabPane } = Tabs;
const FormPost = ({
  form,
  post = null,
  onHandleSubmit,
  readOnly = false,
  children,
}) => {
  const b = bem('form-blog');
  const onSubmit = (values) => {
    if (values.courses[0] === '') {
      delete values.courses;
    }

    if (values.posts[0] === '') {
      delete values.posts;
    }

    if (values.quotes[0] === '') {
      delete values.quotes;
    }

    onHandleSubmit(values);
  };

  const initialValues = post || {
    imageUrls: [],
    bgImageUrl: '',
    courses: [''],
    posts: [''],
    tags: [],
  };

  return (

    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Tabs>
        <TabPane tab="Контент" key="1" forceRender>
          <BlogArticle form={form} readOnly={readOnly} />
        </TabPane>
        {/* <TabPane tab="Изображения" key="2" forceRender>
          <VisualContent form={form} readOnly={readOnly} />
        </TabPane>
        <TabPane tab="Цитата" key="3" forceRender>
          <Citation form={form} readOnly={readOnly} />
        </TabPane> */}
        <TabPane tab="Рекомендации" key="2" forceRender>
          <Recommendation readOnly={readOnly} />
        </TabPane>
        <TabPane tab="CEO" key="3" forceRender>
          <TagsAndSlug form={form} readOnly={readOnly} />
        </TabPane>
      </Tabs>
      <Divider />
      <div className={b('footer')}>
        {children}
      </div>
    </Form>
  );
};

export default FormPost;
