import React, { useState } from 'react';
import './style.less';
import bem from 'easy-bem';
import { Divider, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import Tag from 'components/tag';
import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/modal-copy.svg';
import {
  requiredField,
  rulesValidationOne
} from 'utils/validation/validation';
import FileDragger from 'components/dragger';
import dayjs from 'dayjs';
import DatePicker from 'components/datepicker';
import TextEditor from 'components/text-editor';
import ModalAddBtn from '../../../add-more-btn';
import ModalUpload, { inputStyle, selectStyle } from '../modal-upload/component';


const format = 'DD.MM.YYYY';
const ModalProgram = ({ form, dates }) => {
  const b = bem('modal-program');
  const g = bem('modal-global-style');
  const { RangePicker } = DatePicker;
  const [programDates, setProgramDates] = useState(() => {
    const arrDatePrograms = [];
    form.getFieldValue('programs').forEach((program) => {
      if (program?.dates) {
        arrDatePrograms.push({ programDateStart: program.dates[0], programDateEnd: program.dates[1] });
      } else {
        arrDatePrograms.push({ programDateStart: undefined, programDateEnd: null });
      }
    });

    return arrDatePrograms;
  });
  const copyItem = (add, idx) => {
    const copyData = form.getFieldsValue('programs').programs[idx];
    const programDateStart = copyData.dates?.length > 0 ? copyData.dates[0] : null;
    const programDateEnd = copyData.dates?.length > 0 ? copyData.dates[1] : null;
    setProgramDates([...programDates, { programDateStart, programDateEnd }]);
    add(copyData);
  };

  const deleteBlog = (deleteFn, name) => {
    setProgramDates((prev) => prev.filter((_, index) => index !== name));
    deleteFn(name);
  };

  const getRangeDay = (current) => {
    const startRangeDate = (dates.length && dates[0].startDate) || dayjs();
    const endRangeDate = (dates.length && dates[0].endDate) || null;

    return current < dayjs(startRangeDate) || current > dayjs(endRangeDate).subtract(-1, 'days');
  };

  const handleChangeProgramDates = (values, idx) => {
    if (programDates[idx]) {
      setProgramDates((prev) => prev.map((date, index) => {
        if (index === idx) {
          return { programDateStart: values ? values[0] : null, programDateEnd: values ? values[1] : null };
        }

        return date;
      }));
    } else {
      setProgramDates([...programDates, { programDateStart: values[0], programDateEnd: values[1] }]);
    }
  };

  const createProgram = (fnCreate) => {
    fnCreate({ logo: '', events: [''] });
    setProgramDates([...programDates, { programDateStart: null, programDateEnd: null }]);
  };

  const getRangeDayForEvent = (current, idx) => {
    const startRangeDate = programDates[idx]?.programDateStart || (dates.length && dates[0].startDate) || dayjs();
    const endRangeDate = programDates[idx]?.programDateEnd || (dates.length && dates[0].endDate) || null;

    return current < dayjs(startRangeDate) || current > dayjs(endRangeDate).subtract(-1, 'days');
  };

  return (
    <div className={b()}>
      <Form.Item
        name="programFile"
        label="Программа курса (PDF)"
        getValueProps={(file) => file}
      >
        <FileDragger
          maxLength={1}
          text="Переместите фото сюда или"
          textBlue="выберите файл"
          accept=".pdf"
          fileType={['pdf']}
          initialFile={form.getFieldValue('programFile')}
        />
      </Form.Item>
      <Form.List name="programs">
        {(items, { add, remove }) => (
          <>
            <div className={g('container')}>
              {items.map(({ key, name, ...rest }) => (
                <div key={key} className={g('item')}>
                  <div className={g('header')}>
                    <Tag
                      color="secondary"
                      bgColor="#0071CE"
                      text={`Блок #${name + 1}`}
                    />
                    <div className={g('header-drag')}>
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                      <div className={g('dot')} />
                    </div>
                    <div className={g('geader-icons')}>
                      <CopyIcon className={g('icon')} onClick={() => copyItem(add, name)} />
                      {items.length > 1
                        ? <DeleteIcon className={g('icon')} onClick={() => deleteBlog(remove, name)} />
                        : null}
                    </div>
                  </div>
                  <div className={b('head-inputs')}>
                    <Form.Item
                      {...rest}
                      name={[name, 'title']}
                      label="Название блока"
                      rules={[...rulesValidationOne, requiredField]}
                    >
                      <TextArea
                        style={{ ...selectStyle, height: 52 }}
                        size="large"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                    <Form.Item name={[name, 'dates']} label="Дата блока">
                      <RangePicker
                        format={format}
                        placeholder={['Выберите дату', '']}
                        style={{ ...inputStyle, width: '100%' }}
                        separator=""
                        className={b('date-piker')}
                        disabledDate={getRangeDay}
                        onChange={(e) => handleChangeProgramDates(e, name)}
                      />
                    </Form.Item>
                  </div>
                  <div className={b('description')}>
                    <Form.Item
                      {...rest}
                      name={[name, 'description']}
                      label="Описание блока"
                    >
                      <TextEditor
                        style={{ ...selectStyle, height: 'auto', padding: 0 }}
                        size="large"
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        placeholder="Ввод текста"
                        className={b('info-input')}
                      />
                    </Form.Item>
                  </div>
                  <Divider />
                  <Form.List {...rest} name={[name, 'events']}>
                    {(event, operation) => (
                      <>
                        <div className={b('inputs-block')}>
                          {event.map(({ key: keyEvent, name: nameEvent, ...other }) => (
                            <div className={b('input-items')} key={keyEvent}>
                              <div className={b('inputs')}>
                                <Form.Item
                                  {...other}
                                  name={[nameEvent, 'title']}
                                  label="Наименование мероприятия"
                                  rules={[...rulesValidationOne]}
                                >
                                  <Input
                                    size="small"
                                    style={{ ...inputStyle, width: '100%' }}
                                    placeholder="Ввод текста"
                                    className={b('info-input')}
                                  />
                                </Form.Item>

                                <Form.Item name={[nameEvent, 'eventDates']} label="Дата мероприятия">
                                  <RangePicker
                                    format={format}
                                    placeholder={['Выберите дату', '']}
                                    style={{ ...inputStyle, width: '100%' }}
                                    separator=""
                                    className={b('date-piker')}
                                    disabledDate={(cur) => getRangeDayForEvent(cur, name)}
                                  />
                                </Form.Item>
                              </div>
                              <div className={b('item-close')}>
                                {event.length > 1 && <PlusOutlined onClick={() => operation.remove(nameEvent)} />}
                              </div>
                            </div>
                          ))}
                        </div>
                        <ModalAddBtn onAdd={() => operation.add()} />
                      </>
                    )}
                  </Form.List>
                  <Form.Item name={[name, 'image']} getValueProps={(file) => file}>
                    <ModalUpload
                      initialFile={form.getFieldValue('programs')[name].image}
                      aspect={292 / 384}
                    />
                  </Form.Item>
                </div>
              ))}
            </div>
            <div className={b('add-more-btn')}>
              <ModalAddBtn
                text="Добавить ещё блок"
                onAdd={() => createProgram(add)}
              />
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ModalProgram;
