import React, { useState, useMemo, useCallback } from 'react';
import bem from 'easy-bem';
import ButtonV2 from 'components/button-v2';
import {
  Button,
  Dropdown,
  Select
} from 'antd';
import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import {
  EllipsisOutlined,
  CloseOutlined,
  CaretDownOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { getContentManagers, getContentManagersPart } from 'models/users/selectors';
import { fetchContentManagersPart, unAssignedRoleUser } from 'models/users/actions';
import RemoveRoleModal from 'components/admin-panel/modal/remove-role-modal';
import Menu from 'components/menu';
import { roleText } from 'utils/textOfStatus';
import { fetchContentManagerReport } from 'models/reports/actions';
import { getFullNameUser } from 'utils/get-fullname-user';

import AssignmentRoleModal from './components/assignment-role';
import Report from './components/report/component';

import './style.less';


const ContentManagers = () => {
  const b = bem('content-managers');
  const { Option } = Select;
  const allContentManagers = useSelector(getContentManagers);
  const contentManagers = useSelector(getContentManagersPart);
  const dispatch = useDispatch();
  const [callBackSearch, setCallBackSearch] = useState(null);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [unAssignedUser, setUnAssignedUser] = useState(null);
  const [isReportModal, setIsReportModal] = useState(false);
  const [filters, setFilters] = useState({
    role: null,
    organization: null,
  });


  const sortUsers = useMemo(() => contentManagers.items
    .map((item) => ({ ...item, key: item._id }))
    .sort((a, d) => getFullNameUser(a).localeCompare(getFullNameUser(d))), [contentManagers]);

  const optionsOrganizations = useMemo(() => {
    const uniqueValues = new Set();
    allContentManagers.forEach((item) => {
      uniqueValues.add(item.work?.place);
    });

    return Array.from(uniqueValues).filter((item) => item !== '');
  }, [allContentManagers]);

  const resetFilter = () => {
    setFilters({
      role: null,
      organization: null,
    });
  };

  const handleChangeFilters = (value, name) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const unAssignedRoleHandle = () => {
    const sendData = { idAuth: unAssignedUser?.idAuth, role: 'user' };
    dispatch(unAssignedRoleUser(JSON.stringify(sendData)));
    setUnAssignedUser(null);
  };

  const handleClickReport = useCallback((id) => {
    dispatch(fetchContentManagerReport(id));
    setIsReportModal(true);
  }, [dispatch]);

  const menu = useCallback((user) => (
    <Menu>
      <li>
        <Button
          type="text"
          onClick={() => setUnAssignedUser(user)}
        >
          Изъять роль
        </Button>
      </li>
      <li>
        <Button
          type="text"
          onClick={() => handleClickReport(user.id)}
        >
          Отчет
        </Button>
      </li>
    </Menu>
  ), [handleClickReport]);


  const columns = [
    {
      title:
  <span className={b('table-title')}>
    ФИО
    <Sort style={{ marginLeft: 10 }} />
  </span>,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, c) => a.name.localeCompare(c.name),
      render: (name, user) => `${user.surname} ${name} ${user.patronymic}`
    },
    {
      title:
  <span>
    E-mail
  </span>,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title:
  <span>
    Роль
  </span>,
      dataIndex: 'role',
      key: 'role',
      render: (role) => roleText[role]
    },
    {
      title:
  <span>
    Организация
  </span>,
      dataIndex: 'work',
      key: 'work',
      render: (work) => work.place
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];
  useDebounce(() => {
    const params = {
      ...filters,
      ...callBackSearch,
      search: callBackSearch?.title,
      role: filters.role
    };
    delete params.title;
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const query = new URLSearchParams(params);
    if (!filters.role) {
      query.append('role', 'content-manager');
      query.append('role', 'partner-content-manager');
    }

    dispatch(fetchContentManagersPart(query.toString()));
  }, 500, [callBackSearch, filters]);

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortUsers}
        total={contentManagers.total}
        paginationWithBack
        changePage={setCallBackSearch}
        placeholder="Поиск по ФИО или E-mail"
        ms={0}
      >
        <div className={b('select-box')}>
          <Select
            className={b('select')}
            value={filters.role}
            onChange={(value) => handleChangeFilters(value, 'role')}
            placeholder="Роль"
            suffixIcon={<CaretDownOutlined />}
            allowClear
          >
            <Option value="content-manager">Контент-менеджер УИ</Option>
            <Option value="partner-content-manager">Контент-менеджер партнёра</Option>
          </Select>
          <Select
            className={b('select')}
            value={filters.organization}
            onChange={(value) => handleChangeFilters(value, 'organization')}
            placeholder="Организация"
            suffixIcon={<CaretDownOutlined />}
            allowClear
          >
            {optionsOrganizations.map((item) => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </Select>
          <Button
            className={b('btn-clear', { hidden: !(filters.role || filters.organization) })}
            type="text"
            icon={<CloseOutlined className={b('clear-icon')} />}
            onClick={resetFilter}
          >
            Сбросить фильтр
          </Button>

        </div>
        <ButtonV2
          className={b('btn-add')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <RemoveRoleModal
        isModalsVisible={!!unAssignedUser}
        closeHandler={() => setUnAssignedUser(null)}
        onSubmit={unAssignedRoleHandle}
        user={unAssignedUser}
      />
      <AssignmentRoleModal
        isModalVisible={isModalAdd}
        onClose={() => setIsModalAdd(false)}
      />
      <Report
        isModalVisible={isReportModal}
        onCloseModal={() => setIsReportModal(false)}
      />
    </div>
  );
};

export default ContentManagers;
