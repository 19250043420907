import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import { successMessage } from 'utils/message';
import * as c from './constants';
import {
  getAds,
  createAd,
  editAd,
  onPublishAd,
  onCopyAd,
  deleteAd,
  getPosts,
  createPost,
  editPost,
  deletePost,
  onArchivedPost,
  onPublishPost,
  createPromo,
  editPromo,
  onCancelPromo,
  getPromosActual,
  getAdminPromos
} from './api';


function* getAdsSaga({ payload }) {
  const { success, failure } = c.getAds;
  try {
    const ads = yield call(getAds, payload);
    yield put({ type: success, payload: ads });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createAdSaga({ payload }) {
  const { success, failure } = c.createAd;
  try {
    const ad = yield call(createAd, payload);
    yield put({ type: success, payload: ad });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* copyAdSaga({ payload }) {
  const { success, failure } = c.onCopyAd;
  try {
    const ad = yield call(onCopyAd, payload);
    yield put({ type: success, payload: ad });
    successMessage('Подборка cкопирована');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editAdSaga({ payload }) {
  const { success, failure } = c.editAd;
  try {
    const ad = yield call(editAd, payload);
    yield put({ type: success, payload: ad });
    successMessage('Измения сохранены');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onPublishAdSaga({ payload }) {
  const { success, failure } = c.onPublishAd;
  try {
    const ad = yield call(onPublishAd, payload);
    yield put({ type: success, payload: ad });
    successMessage('Подборка опубликована');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onDeleteAdSaga({ payload }) {
  const { success, failure } = c.deleteAd;
  try {
    const ad = yield call(deleteAd, payload);
    yield put({ type: success, payload: ad });
    successMessage('Подборка удалена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPostsSaga({ payload }) {
  const { success, failure } = c.getPosts;
  try {
    const posts = yield call(getPosts, payload);
    yield put({ type: success, payload: posts });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createNewPostSaga({ payload }) {
  const { success, failure } = c.createPost;
  try {
    const post = yield call(createPost, payload);
    yield put({ type: success, payload: post });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editPostSaga({ payload }) {
  const { success, failure } = c.editPost;
  try {
    const post = yield call(editPost, payload);
    yield put({ type: success, payload: post });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onDeletePostSaga({ payload }) {
  const { success, failure } = c.deletePost;
  try {
    yield call(deletePost, payload);
    yield put({ type: success, payload });
    successMessage('Статья удалена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onPublishedPostSaga({ payload }) {
  const { success, failure } = c.onPublishPost;
  try {
    const post = yield call(onPublishPost, payload);
    yield put({ type: success, payload: post });
    successMessage('Статья опубликована');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onArchivedPostSaga({ payload }) {
  const { success, failure } = c.onArchivedPost;
  try {
    const post = yield call(onArchivedPost, payload);
    yield put({ type: success, payload: post });
    successMessage('Статья перемещена в архив');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getAdminPromosSaga({ payload }) {
  const { success, failure } = c.getAdminPromos;
  try {
    const promos = yield call(getAdminPromos, payload);
    yield put({ type: success, payload: promos });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPromosActualSaga({ payload }) {
  const { success, failure } = c.getPromosActual;
  try {
    const promos = yield call(getPromosActual, payload);
    yield put({ type: success, payload: promos });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* createPromoSaga({ payload }) {
  const { success, failure } = c.createPromo;
  try {
    const promo = yield call(createPromo, payload);
    yield put({ type: success, payload: promo });
    successMessage('Запись справочника сохранена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* editPromoSaga({ payload }) {
  const { success, failure } = c.editPromo;
  try {
    const record = yield call(editPromo, payload);
    yield put({ type: success, payload: record });
    successMessage('Промоакция продлена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* onCancelPromoSaga({ payload }) {
  const { success, failure } = c.onCancelPromo;
  try {
    const record = yield call(onCancelPromo, payload);
    yield put({ type: success, payload: record });
    successMessage('Промоакция завершена');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getAds.tries, getAdsSaga);
  yield takeLatest(c.onCopyAd.tries, copyAdSaga);
  yield takeLatest(c.createAd.tries, createAdSaga);
  yield takeLatest(c.editAd.tries, editAdSaga);
  yield takeLatest(c.onPublishAd.tries, onPublishAdSaga);
  yield takeLatest(c.deleteAd.tries, onDeleteAdSaga);
  yield takeLatest(c.getPosts.tries, getPostsSaga);
  yield takeLatest(c.createPost.tries, createNewPostSaga);
  yield takeLatest(c.editPost.tries, editPostSaga);
  yield takeLatest(c.deletePost.tries, onDeletePostSaga);
  yield takeLatest(c.onPublishPost.tries, onPublishedPostSaga);
  yield takeLatest(c.onArchivedPost.tries, onArchivedPostSaga);
  yield takeLatest(c.getAdminPromos.tries, getAdminPromosSaga);
  yield takeLatest(c.createPromo.tries, createPromoSaga);
  yield takeLatest(c.editPromo.tries, editPromoSaga);
  yield takeLatest(c.onCancelPromo.tries, onCancelPromoSaga);
  yield takeLatest(c.getPromosActual.tries, getPromosActualSaga);
}
