import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import ModalCourseReview from 'components/modal/modal-review/course-review/component';
import CoursesFilters from 'components/admin-panel/components/course-filters';
import dayjs from 'dayjs';
import './style.less';
import Menu from 'components/menu';
import { Dropdown, Button } from 'antd';
import bem from 'easy-bem';
import { getArchivedCourses } from 'models/courses/selectors';
import React, {
  useEffect, useMemo, useState, useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/date';
import { EllipsisOutlined } from '@ant-design/icons';
import { dateTypeText } from 'utils/textOfStatus';
import { fetchUsers } from 'models/users/actions';
import { useDebounce } from 'react-use';
import { fetchArchivedCourses, replicateCourse } from 'models/courses/actions';
import { replicateCourse as replicateCourseApi } from 'models/courses/api';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import { getFullNameUser } from '../../../../utils/get-fullname-user';


const ArchiveCourses = () => {
  const courses = useSelector(getArchivedCourses);
  
  const b = bem('archive-courses');
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    userCreator: null,
    educationProfiles: null,
    partnersTitle: null,
  });
  const [callBackSearch, setCallBackSearch] = useState(null);
  const [lookCourse, setLookCourse] = useState(null);
  
  const sortCourses = useMemo(() => courses.items
    .map((item) => ({ ...item, key: item._id }))
    .sort((a, d) => (dayjs(d.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1)), [courses]);

  const contractedDate = (course) => {
    if (course.dateType !== 'choose_dates') {
      return dateTypeText[course.dateType];
    }

    const startDate = formatDate(course.dates[0].startDate);
    const endDate = formatDate(course.dates[0].endDate);

    return `${startDate} - ${endDate}`;
  };


  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleReplicate = async ({ _id }) => {
    try {
      const response = await replicateCourseApi(_id);
      dispatch(replicateCourse(response));
      successMessage('Курс скопирован');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };


  const menu = useCallback((value) => (
    <Menu>
      <li>
        <Button type="text" onClick={() => setLookCourse(value)}>
          Посмотреть
        </Button>
      </li>
      <li>
        <Button type="text" onClick={() => handleReplicate(value)}>
          Создать копию
        </Button>
      </li>
    </Menu>
  ), []);

  const columns = [
    {
      title: <span>Название курса</span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: <span>Даты проведения</span>,
      dataIndex: 'dates',
      key: 'dates',
      render: (_, record) => (<span>{contractedDate(record)}</span>)
    },
    {
      title: <span>Автор</span>,
      dataIndex: 'userCreator',
      key: 'userCreator',
      render: (userCreator) => (<span>{getFullNameUser(userCreator, 'short')}</span>),
    },
    {
      title: <span>Дата cнятия с публикации</span>,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => (<span>{formatDate(date)}</span>)
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];


  useDebounce(() => {
    const params = {
      ...callBackSearch,
      ...filters,
      status: 'archived',
      partnersTitle: filters.partnersTitle ?? '',
    };
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchArchivedCourses(paramsString));
  }, 1000, [callBackSearch, filters]);

  const placeholderSelect = {
    author: 'Автор',
    educationProfiles: 'Направления обучения',
    partners: 'Партнер',
  };

  const clearFilters = {
    author: undefined,
    educationProfiles: undefined,
    partners: undefined,
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortCourses}
        total={courses.total}
        changePage={setCallBackSearch}
        paginationWithBack
        ms={0}
      >
        <CoursesFilters
          setFilters={setFilters}
          filters={filters}
          placeholderSelect={placeholderSelect}
          clearFilters={clearFilters}
        />
      </GeneralTableTemplate>
      <ModalCourseReview
        values={lookCourse}
        isModalVisible={!!lookCourse}
        onClose={() => setLookCourse(false)}
        title="Заархивированный курс"
        review
      />
    </div>
  );
};

export default ArchiveCourses;
