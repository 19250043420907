const onlySymbolsRegex = /[*%=@&^#\\/|+-]$/;
const onlyNumbersRegex = new RegExp('^[0-9.]+$');
const emailParams = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

export const requiredField = { required: true, message: 'Заполните все обязательные поля' };
export const whitespaceRule = { whitespace: true, message: 'Поле может содержать только текст' };
export const rulesValidationOne = [
  whitespaceRule,
  {
    validator: (_, value) => (onlySymbolsRegex.test(value)
      ? Promise.reject(new Error('Поле может содержать только текст'))
      : Promise.resolve()),
  },
];

export const rulesValidationFour = (data, fieldKey = 'name') => (
  {
    validator: (_, value) => (data.find((item) => item[fieldKey].toLocaleLowerCase()
    === value?.toLocaleLowerCase().trim())
      ? Promise.reject(new Error('Запись с таким наименованием уже создана'))
      : Promise.resolve()),
  }
);

export const rulesValidationThree = {
  validator: (_, value) => {
    if (value) {
      return (!onlyNumbersRegex.test(value)
        ? Promise.reject(new Error('Поле может содержать только число'))
        : Promise.resolve());
    }

    return Promise.resolve();
  },
};
export const rulesValidationPhone = {
  validator: (_, value) => {
    if (value) {
      const count = parseInt(value.replace(/\D+/g, ''), 10);

      return (count.toString().length !== 11
        ? Promise.reject(new Error('Поле телефон введено некорректно'))
        : Promise.resolve());
    }

    return Promise.resolve();
  },
};
export const rulesValidationEmail = {
  validator: (_, value) => {
    if (value) {
      return (!emailParams.test(value)
        ? Promise.reject(new Error('Некорректный email-адрес'))
        : Promise.resolve());
    }

    return Promise.resolve();
  },
};

const conventQuilToText = (quil) => quil.replace(/<(.|\n)*?>/g, '');

export const whitespaceQuil = {
  validator: (_, value) => ((conventQuilToText(value).trim().length === 0 && conventQuilToText(value).length > 0)
    ? Promise.reject(new Error('Поле может содержать только текст'))
    : Promise.resolve()),
};
export const rulesQuillValidationOne = [
  whitespaceQuil,
  {
    validator: (_, value) => (onlySymbolsRegex.test(conventQuilToText(value))
      ? Promise.reject(new Error('Поле может содержать только текст'))
      : Promise.resolve()),
  },
];

export const requiredQuill = {
  validator: (_, value) => ((conventQuilToText(value).length === 0)
    ? Promise.reject(new Error('Заполните все обязательные поля'))
    : Promise.resolve()),
};
