import React from 'react';
import ModalV2 from 'components/modal-v2';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import { createCourse } from 'models/courses/api';
import { successMessage } from 'utils/message';
import { addCourse, setSlug } from 'models/courses/actions';
import { errorMessageCatcher } from 'utils/request';
import FormCourse from '../form';


const CreateCourseModal = ({ isModalVisible, onClose }) => {
  const b = bem('modal-create-course');
  const dispatch = useDispatch();
  const onCreateCourse = async (newCourse) => {
    try {
      const response = await createCourse(newCourse);
      dispatch(addCourse(response));
      successMessage('Запись справочника сохранена');
      onClose();
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const initialValues = {
    allowMessages: true,
    dateType: 'choose_dates',
    dates: [{ startDate: null, endDate: null }],
    priceType: 'full_price',
    specialRequirements: [''],
    competencies: [''],
    applicants: [''],
    programs: [{ events: [''] }],
    governmentProgram: [''],
    teachers: [''],
    videoUrls: [''],
    partners: [{
      title: 'Университет Иннополис',
      // eslint-disable-next-line max-len
      description: 'Российская автономная некоммерческая организация высшего образования в городе Иннополис, специализирующаяся на образовании, исследованиях и разработках в области информационных технологий и робототехники.',
      logo: 'innopolis.png'
    }],
  };

  return (
    <ModalV2
      className={b()}
      title="Создание курса"
      visible={isModalVisible}
      width={992}
      closable={false}
      footer=""
      tooltipClose="После отмены восстановление заполненных  полей невозможно"
      onClose={onClose}
      afterClose={() => dispatch(setSlug(''))}
      destroyOnClose
    >
      <FormCourse
        onSubmitHandler={onCreateCourse}
        initialValues={initialValues}
        onClose={onClose}
      />
    </ModalV2>
  );
};

export default CreateCourseModal;
