import bem from 'easy-bem';
import React from 'react';
import DOMPurify from 'dompurify';
import './style.less';


const TitleText = ({
  label,
  text,
  html,
  link
}) => {
  const purifiedHTML = html ? DOMPurify.sanitize(html) : null;
  const b = bem('title-text');

  return (
    <div className={b()}>
      <p className={b('label')}>{label}</p>
      { purifiedHTML
        && (
          <p
            className={`${b('text')} position-inside`}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: purifiedHTML }}
          />
        )}
      { text && <p className={b('text')}>{text}</p>}
      { link && <a href={link} className={b('link')}>{link}</a>}
    </div>
  );
};

export default TitleText;

