import React from 'react';

import FileDragger from 'components/dragger';


export const inputStyle = {
  height: 40,
  fontSize: 16,
  color: '#3B4168',
  fontFamily: 'IBM Plex Sans',
  borderRadius: '4px',
};
export const selectStyle = {
  height: 40,
  fontSize: 16,
  color: '#3B4168',
  fontFamily: 'IBM Plex Sans',
  borderRadius: '4px',
  padding: '10px 16px',
};

const ModalUpload = (props) => (
  <FileDragger
    maxLength={1}
    text="Переместите фото сюда или"
    textBlue="выберите файл"
    crop
    accept=".png, .jpeg, .jpg"
    fileType={['png', 'jpeg', 'svg']}
    {...props}
  />
);

export default ModalUpload;
