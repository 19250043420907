import * as c from './constants';


export const fetchUsers = (query = '') => ({
  type: c.getUsers.tries,
  payload: query,
});

export const fetchUsersPart = (query = '') => ({
  type: c.getUsersPart.tries,
  payload: query,
});

export const fetchContentManagersPart = (query = '') => ({
  type: c.getContentManagersPart.tries,
  payload: query,
});

export const fetchUsersOneId = (query = '') => ({
  type: c.getUsersOneId.tries,
  payload: query,
});

export const unAssignedRoleUser = (user) => ({
  type: c.unAssignedRole.tries,
  payload: user,
});
