import React, {
  createContext, useContext, useState
} from 'react';
import { Modal } from 'antd';
import bem from 'easy-bem';

import ButtonV2 from '../button-v2';
import './style.less';


const modalStyle = { maxWidth: '796px' };

export const LimitedAccessModalContext = createContext(null);

export const LimitedAccessModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const b = bem('limited-access-modal');
  const toggle = () => setIsOpen((v) => !v);

  return (
    <LimitedAccessModalContext.Provider value={{ toggle }}>
      {children}
      <Modal
        footer={[
          <ButtonV2 onClick={toggle} type="primary">
            Хорошо
          </ButtonV2>
        ]}
        width="100%"
        style={modalStyle}
        className={b()}
        visible={isOpen}
        onOk={toggle}
        onCancel={toggle}
      >
        <div className="content">
          <img src="/images/limited-access/work-time-bro.png" alt="" />
          <p className="content-text">
            Доступ к части функционала ограничен и будет открыт в ближайшее время.
          </p>
        </div>
      </Modal>
    </LimitedAccessModalContext.Provider>
  );
};

export const useLimitedAccessModalContext = () => useContext(LimitedAccessModalContext);

export default LimitedAccessModalProvider;
