import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { Modal } from 'antd';

import 'react-image-crop/dist/ReactCrop.css';
import bem from 'easy-bem';
import './style.less';
import { calcCropParams } from 'utils/calcCropParams';


const FileCrop = ({
  aspect, imgSrc, setImgSrc, onOkModal
}) => {
  const b = bem('file-crop');
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(undefined);
  const [completedCrop, setCompletedCrop] = useState();
  const [imageRealSize, setImageRealSize] = useState();

  const handleModalOk = () => {
    const croppedParams = calcCropParams({ ...completedCrop, ...imageRealSize });
    onOkModal(croppedParams);
  };

  const handleModalCancel = () => {
    setImgSrc('');
  };

  const onImageSrcLoaded = (e) => {
    imgRef.current = e.currentTarget;
    const {
      width, height, naturalWidth, naturalHeight
    } = e.currentTarget;

    const scaleX = naturalWidth / width;
    const scaleY = naturalHeight / height;

    setImageRealSize({
      scaleX,
      scaleY,
      naturalWidth,
      naturalHeight,
    });

    const cropParams = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        width,
        height,
      ),
      width,
      height,
    );
    setCrop(cropParams);
  };

  return (
    <Modal
      visible={!!imgSrc}
      maskClosable={false}
      okText="Применить"
      cancelText="Закрыть"
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      className={b()}
    >
      <ReactCrop
        crop={crop}
        ruleOfThirds
        onChange={(_, percentCrop) => {
          setCrop(percentCrop);
        }}
        aspect={aspect}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          src={imgSrc}
          onLoad={onImageSrcLoaded}
        />
      </ReactCrop>
    </Modal>
  );
};

export default FileCrop;
