import bem from 'easy-bem';
import React from 'react';

import './style.less';


const defaultColorsCollection = [
  false, 'green', 'red', 'yellow', 'gray', 'transparent', 'orange', 'violet', 'purple', 'lightBlue' // , 'white'
];

const CardTag = ({
  color = false,
  cardColorTag,
  promo,
  text,
  avoidColorName = null,
  colorsOfTags = defaultColorsCollection,
  className = ''
}) => {
  const b = bem('cardTag');


  const getColorFromCollection = (tagName = 'random', parentColor) => {
    const letters = tagName.split('');
    const colorIndex = letters.reduce((acc, item) => item.charCodeAt(0) + acc, 0) % defaultColorsCollection.length;

    return (!avoidColorName || colorsOfTags[colorIndex] !== parentColor)
      ? colorsOfTags[colorIndex]
      : colorsOfTags[colorIndex - 1];
  };

  const tagColor = color || getColorFromCollection(text, avoidColorName);

  const promoCard = () => {
    if (promo && cardColorTag === '#FF5CAE') {
      return (
        <div style={{ background: cardColorTag, color: '#ffffff' }} className={b('text')}>
          <p>{text}</p>
        </div>
      );
    }

    if (promo && cardColorTag === '#FFFFFF') {
      return (
        <div style={{ background: cardColorTag, color: '#FF5CAE' }} className={b('text')}>
          <p>{text}</p>
        </div>
      );
    }

    if (!promo && cardColorTag) {
      return (
        <div style={{ background: cardColorTag, color: '#ffffff' }} className={b('text')}>
          <p>{text}</p>
        </div>
      );
    }

    return (
      <div className={`${b('text', { [tagColor]: !!tagColor })} ${className}`}>
        <p>{text}</p>
      </div>
    );
  };


  return promoCard();
};

export default CardTag;
