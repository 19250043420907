import { getConstant } from 'utils/constant-factory';


export const addCourse = getConstant('ADD_COURSE');
export const editCourse = getConstant('EDIT_COURSE');
export const onPublishCourse = getConstant('PUBLISH_COURSE');
export const onArchiveCourse = getConstant('ARCHIVE_COURSE');
export const unPublishCourse = getConstant('UNPUBLISH_COURSE');
export const moderateCourse = getConstant('MODERATE_COURSE');
export const moderateRevokeCourse = getConstant('MODERATE_REVOKE_COURSE');
export const reworkCourse = getConstant('REWORK_COURSE');
export const replicateCourse = getConstant('REPLICATE_COURSE');
export const deleteCourse = getConstant('DELETE_COURSE');

export const getAdminCourses = getConstant('GET_ADMIN_COURSES');
export const getNecessaryDataToCourse = getConstant('GET_NECESSARY_DATA_TO_COURSE');
export const onChangeDisabledLocationCourse = getConstant('CHANGE_DISABLED_LOCATION_COURSE');
export const getPublishedCourses = getConstant('GET_PUBLISHED_COURSES');
export const getCoursesBundle = getConstant('GET_COURSES_BUNDLE');
export const getArchivedCourses = getConstant('GET_ARCHIVED_COURSES');

export const setSlug = getConstant('SET_SLUG');
export const getPublishedAdminCourses = getConstant('GET_PUBLISHED_ADMIN_COURSES');
