import { combineReducers } from 'redux';

import data from './data';
import courses from './courses';


export default combineReducers({
  data,
  courses,
});
