import React, { useState, useMemo, useCallback } from 'react';
import bem from 'easy-bem';
import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPosts,
  onArchivedPost,
  onDeletePost,
  onPublishedPost
} from 'models/blogAndAdvertising/actions';
import { getPosts } from 'models/blogAndAdvertising/selectors';
import { Dropdown, Button } from 'antd';
import ButtonV2 from 'components/button-v2';
import dayjs from 'dayjs';
import './style.less';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { textStatus } from 'utils/textOfStatus';
import Menu from 'components/menu';
import ModalCreateBlog from './components/modalCreatePost';
import ActionModal from '../actionModal';
import ModalReviewPost from './components/modalReviewPost';
import ModalEditBlog from './components/modalEditPost';


const Blogs = () => {
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [isShowModal, setIsShowModal] = useState(null);
  const [isCopyModal, setIsCopyModal] = useState(null);
  const [isEditModal, setIsEditModal] = useState(null);
  const [isArchive, setIsArchive] = useState(null);
  const [isPublication, setIsPublication] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(null);
  const b = bem('blogs');
  const posts = useSelector(getPosts);
  const dispatch = useDispatch();
  const getStatus = (status) => status === 'published';
  const menu = useCallback((value) => {
    const dropdownMenuVersions = {
      draft:
        (
          <>
            <li>
              <Button type="text" onClick={() => setIsEditModal(value)}>
                Редактировать
              </Button>
            </li>
            <li>
              <Button type="text" onClick={() => setIsPublication(value._id)}>
                Опубликовать
              </Button>
            </li>
            <li>
              <Button className="menu-manual__delete" type="text" onClick={() => setIsDeleteModal(value._id)}>
                Удалить
              </Button>
            </li>
          </>
        ),
      published: (
        <>
          <li>
            <Button type="text" onClick={() => setIsShowModal(value)}>
              Посмотреть
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsCopyModal(value)}>
              Копировать
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsArchive(value._id)}>
              Архивировать
            </Button>
          </li>
        </>
      ),
    };

    return (
      <Menu>
        {
          !dropdownMenuVersions[value.status] ? null : dropdownMenuVersions[value.status]
        }
      </Menu>
    );
  }, []);
  const columns = [
    {
      title:
  <span className={b('table-title')}>
    Название статьи
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Дата публикации
  </span>,
      dataIndex: 'publishedAt',
      key: 'publishedAt'
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'status',
      render: (status, index) => (
        <span className={b('status', { active: getStatus(status) })} key={index.id}>
          {textStatus[status]}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={menu(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];

  const sortPosts = useMemo(() => posts.items
    .map((post) => ({
      ...post,
      key: post._id,
      posts: post.posts.map((item) => item._id),
      courses: post.courses.map((item) => item._id),
      tags: post.tags.map((item) => item._id),
    }))
    .sort((a, d) => ((d.status.localeCompare(a.status))
    || (dayjs(d.updatedAt).isAfter(dayjs(a.updatedAt))) ? 1 : -1)), [posts]);

  const handleChangePage = (params) => {
    const query = new URLSearchParams(params).toString();
    dispatch(fetchPosts(query));
  };

  const handleDeletePost = () => {
    dispatch(onDeletePost(isDeleteModal));
  };

  const handleArchivedPost = () => {
    dispatch(onArchivedPost(isArchive));
  };

  const handlePublishedPost = () => {
    dispatch(onPublishedPost(isPublication));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortPosts}
        changePage={handleChangePage}
        paginationWithBack
        total={posts.total}
      >
        <ButtonV2
          className={b('button-add')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalCreate(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalCreateBlog
        isVisibleModal={!!isModalCreate}
        onClose={() => setIsModalCreate(false)}
      />
      <ModalCreateBlog
        isVisibleModal={!!isCopyModal}
        post={isCopyModal}
        onClose={() => setIsCopyModal(null)}
      />
      <ModalReviewPost
        isVisibleModal={!!isShowModal}
        onClose={() => setIsShowModal(null)}
        post={isShowModal}
      />
      <ModalEditBlog
        isVisibleModal={!!isEditModal}
        post={isEditModal}
        onClose={() => setIsEditModal(null)}
      />
      <ActionModal
        isModalVisible={isPublication !== null}
        contentText="Вы уверены что хотите опубликовать статью"
        onClose={() => setIsPublication(null)}
        submitText="Опубликовать"
        title="Публикация статьи"
        onSubmit={handlePublishedPost}
      />
      <ActionModal
        isModalVisible={isArchive !== null}
        contentText="Поместить статью в архив?"
        onClose={() => setIsArchive(null)}
        submitText="Aрхивировать"
        title="Aрхивировать"
        onSubmit={handleArchivedPost}
      />
      <ActionModal
        isModalVisible={isDeleteModal !== null}
        contentText="Восстановление удалённой статьи невозможно, удалить?"
        onClose={() => setIsDeleteModal(null)}
        submitText="Удалить"
        title="Удаление статьи"
        onSubmit={handleDeletePost}
      />
    </div>
  );
};

export default Blogs;
