import React, { useEffect } from 'react';
import bem from 'easy-bem';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';

import { UnderConstruction } from 'components/under-construction';
import {
  fetchEducationLevels,
  fetchEducationProfiles,
  fetchEducationTypes,
  fetchTags
} from 'models/manualControlOfCouses/actions';
import { fetchPublishedAdminCourses } from 'models/courses/actions';
import { fetchUsers } from 'models/users/actions';
import UserManagement from '../user-management';
import CourseManagement from '../course-management';
import BlogAndAdvertising from '../blog-advertising';

import './style.less';


const menuItems = [
  { label: 'Профиль', key: '1', component: <UnderConstruction /> },
  { label: 'Управление пользователями', key: '2', component: <UserManagement /> },
  { label: 'Управление витриной курсов', key: '3', component: <CourseManagement /> },
  { label: 'Блог и реклама', key: '4', component: <BlogAndAdvertising /> }
];

const AdminPage = () => {
  const b = bem('admin-view');
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchEducationTypes());
    dispatch(fetchEducationProfiles());
    dispatch(fetchEducationLevels());
    dispatch(fetchPublishedAdminCourses());
  }, [dispatch]);

  const handleChangePage = (active) => {
    if (active === '4') {
      dispatch(fetchTags());
    }
  };

  return (
    <div className={b()}>
      <Tabs defaultActiveKey="3" className={b('list')} onChange={handleChangePage}>
        {menuItems.map((item) => (
          <TabPane tab={<span className={b('main-tabs')}>{item.label}</span>} key={item.key}>
            {item.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default AdminPage;
