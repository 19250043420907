import React, { useMemo, useState } from 'react';
import { Dropdown, Button } from 'antd';
import bem from 'easy-bem';
import './style.less';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getTags } from 'models/manualControlOfCouses/selectors';
import ButtonV2 from 'components/button-v2';
import { fetchTags } from 'models/manualControlOfCouses/actions';
import Menu from 'components/menu';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import ModalTag from './components/modalTag/index';


const Tags = () => {
  const b = bem('tags');
  const tags = useSelector(getTags);
  const [isModalDelete, setIsModalDelete] = useState(null);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [tagId, setTagId] = useState(null);
  const dispatch = useDispatch();

  const openDeleteModal = (id) => {
    setTagId(id);
    setIsModalDelete(true);
  };

  const columns = [
    {
      title:
  <span>
    Тег
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Использование в курсах
  </span>,
      dataIndex: 'coursesCount',
      key: 'coursesCount'
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={() => (
            <Menu>
              <li>
                <Button type="text" className="menu-manual__delete" onClick={() => openDeleteModal(record._id)}>
                  Удалить
                </Button>
              </li>
            </Menu>
          )}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];
  const allTags = useMemo(() => (tags.items
    .map((tag) => ({ ...tag, key: tag._id }))
    .sort((curr, next) => curr.title.localeCompare(next.title))), [tags]);

  const handleChangePage = (params) => {
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchTags(paramsString));
  };

  return (
    <div className={b()}>
      <GeneralTableTemplate
        data={allTags}
        columns={columns}
        changePage={handleChangePage}
        paginationWithBack
        total={tags.total}
      >
        <ButtonV2
          className={b('button')}
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsModalAdd(true)}
        >
          Добавить новую запись
        </ButtonV2>
      </GeneralTableTemplate>
      <ModalTag
        key={1}
        isDelete="true"
        id={tagId}
        isVisibleModal={isModalDelete}
        onClose={() => setIsModalDelete(null)}
        title="Удалить запись"
      />
      <ModalTag
        key={2}
        isDelete={false}
        isVisibleModal={isModalAdd}
        onClose={() => setIsModalAdd(null)}
        title="Добавить новую запись"
      />
    </div>
  );
};

export default Tags;
