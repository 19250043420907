import React, { useState } from 'react';
import {
  Form,
  Tooltip,
  Radio,
} from 'antd';
import bem from 'easy-bem';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { addEvaluationCriteria } from 'models/manualControlOfCouses/actions';
import { getCriterions } from 'models/manualControlOfCouses/selectors';
import { rulesValidationFour, rulesValidationOne, requiredField } from 'utils/validation/validation';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';

import './styles.less';


const ModalAddCriteria = ({ isVisibleModal, onClose, }) => {
  const b = bem('modal-add-criteria');
  const [form] = Form.useForm();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const criterionList = useSelector(getCriterions);
  const rulesValidation = [
    requiredField,
    ...rulesValidationOne,
    rulesValidationFour(criterionList.items, 'title')
  ];
  const onCloseHandler = () => {
    form.resetFields();
    onClose();
    setName('');
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    values.title = values.title.trim();
    const data = { ...values, date: dayjs().format('YYYY-MM-DD hh:mm:ss') };
    dispatch(addEvaluationCriteria(JSON.stringify(data)));
    onCloseHandler();
  };

  return (
    <ModalV2
      className={b('modal')}
      title="Добавить новую запись"
      closable={false}
      visible={isVisibleModal}
      onClose={onCloseHandler}
      footer={(
        <div className={b('footer')}>
          <Button type="primary" ghost onClick={onCloseHandler}>Отменить</Button>
          <Tooltip
            placement="top"
            title={!name ? 'Заполните все обязательные поля' : ''}
          >
            <div>
              <Button type="primary" onClick={onSubmit} disabled={!name}>Сохранить</Button>
            </div>
          </Tooltip>
        </div>
      )}
      width={528}
    >
      <Form
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        form={form}
      >

        <InputV2
          rules={rulesValidation}
          type="form"
          name="title"
          label="Название критерия оценки"
          placeholder="Введите текст"
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Form.Item
          rules={[requiredField]}
          name="scale"
          initialValue="ten-point"
          label="Шкала оценки"
        >
          <Radio.Group name="scale">
            <Radio className={b('input')} value="ten-point">от 1 до 10</Radio>
            <Radio className={b('input')} value="binary">Да - Нет</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          rules={[requiredField]}
          name="status"
          initialValue="active"
          label="Статус"
        >
          <Radio.Group name="status">
            <Radio className={b('input')} value="active">Активен</Radio>
            <Radio className={b('input')} value="archived">В архиве</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </ModalV2>
  );
};

export default ModalAddCriteria;
