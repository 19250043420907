import React, { useEffect, useMemo } from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';

import { textStatus } from 'utils/textOfStatus';
import { getFullNameUser } from 'utils/get-fullname-user';
import { getEducationProfiles, getEducationLevels, getEducationTypes } from 'models/manualControlOfCouses/selectors';
import { fetchAuthors, fetchStatuses, fetchPartners } from 'models/filters/actions';
import { getAuthors, getPartners, getStatuses } from 'models/filters/selectors';

import './style.less';


const { Option } = Select;

const CoursesFilters = ({ filters = {}, setFilters }) => {
  const b = bem('courses-filters');
  const directionsOfStudies = useSelector(getEducationProfiles).items;
  const educationLevels = useSelector(getEducationLevels).items;
  const types = useSelector(getEducationTypes).items;
  const userCreators = useSelector(getAuthors).items;
  const statuses = useSelector(getStatuses).items;
  const partnersTitles = useSelector(getPartners).items;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthors());
    dispatch(fetchPartners());
    dispatch(fetchStatuses());
  }, [dispatch]);

  const placeholderSelect = {
    userCreator: 'Автор',
    educationProfiles: 'Направления обучения',
    partnersTitle: 'Партнер',
    levels: 'Сложность',
    educationType: 'Тип обучения',
    status: 'Статус',
  };

  const options = useMemo(() => Object.entries(filters).reduce((acc, [key]) => {
    const filterSelectors = {
      educationProfiles: directionsOfStudies,
      userCreator: userCreators,
      partnersTitle: partnersTitles,
      levels: educationLevels,
      educationType: types,
      status: statuses,
    };
    const selector = filterSelectors[key];
    let optionValues = [];
    if (key === 'educationProfiles' || key === 'levels' || key === 'educationType') {
      optionValues = selector?.map((item) => ({ id: item._id, title: item.title }));
    } else if (key === 'userCreator') {
      optionValues = selector?.map((item) => ({ id: item._id, title: getFullNameUser(item, true) }));
    } else {
      optionValues = selector?.map((item) => ({ id: item._id || '', title: item }));
    }

    acc[key] = optionValues;

    return acc;
  }, {}), [filters, directionsOfStudies, userCreators, partnersTitles, statuses]);

  const onHandleChangeFilter = (name, id, value) => {
    if (name === 'educationProfiles' || name === 'userCreator' || name === 'levels' || name === 'educationType') {
      const newFilters = { ...filters, [name]: id };
      setFilters(newFilters);
    } else {
      const newFilters = { ...filters, [name]: value?.title };
      setFilters(newFilters);
    }
  };

  const onHandleClickClear = () => {
    const clearFilters = Object.fromEntries(Object.entries(filters).map(([key]) => [key, null]));
    setFilters(clearFilters);
  };

  const showClearBtn = useMemo(() => Object.values(filters).filter((item) => item).length !== 0, [filters]);

  const filtersSelect = Object.keys(filters).map((item) => (
    <Select
      key={item}
      onChange={(value, title) => onHandleChangeFilter(item, value, title)}
      value={filters[item]}
      allowClear
      className={b('select')}
      placeholder={placeholderSelect[item]}
      suffixIcon={<CaretDownOutlined />}
    >
      {options[item].map((option, idx) => (
        option && (
          <Option
            key={option.id || idx}
            title={textStatus[option.title] || option.title}
            value={option.id || idx}
          >
            {textStatus[option.title] || option.title}
          </Option>
        )
      ))}
    </Select>
  ));

  return (
    <div className={b()}>
      {filtersSelect}
      <Button
        className={b('btn-clear', { show: showClearBtn })}
        type="text"
        icon={<CloseOutlined className={b('clear-icon')} />}
        onClick={onHandleClickClear}
      >
        Сбросить фильтр
      </Button>
    </div>
  );
};

export default CoursesFilters;
