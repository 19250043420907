import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import { textStatus, ordersStatus } from 'utils/textOfStatus';
import { formatDate } from 'utils/date';
import { getFullNameUser } from 'utils/get-fullname-user';
import * as c from './constants';
import { getContentManagerReport, getCourseReport } from './api';


function* getContentManagerReportSaga({ payload }) {
  const { success, failure } = c.getContentManagerReport;
  yield put({ type: c.setLoadingReport.success, payload: true });
  try {
    const report = yield call(getContentManagerReport, payload);
    const courses = report.courses.map((item) => ({
      ...item,
      statusText: textStatus[item.status],
      createdAt: formatDate(item.createdAt)
    }));
    yield put({ type: success, payload: { ...report, courses } });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  } finally {
    yield put({ type: c.setLoadingReport.success, payload: false });
  }
}

function* getCourseReportsSaga({ payload }) {
  const { success, failure } = c.getCourseReport;
  try {
    const reports = yield call(getCourseReport, payload);
    const orders = reports.orders.map((item) => {
      const { price, priceType } = item.courseSnapshot;

      return ({
        ...item,
        createdAt: formatDate(item.courseSnapshot.createdAt),
        price: priceType === 'free' ? 'Бесплатно' : price,
        userFullName: getFullNameUser(item.userCustomer),
        statusText: ordersStatus[item.stage],
      });
    });
    yield put({ type: success, payload: { ...reports, orders } });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getContentManagerReport.tries, getContentManagerReportSaga);
  yield takeLatest(c.getCourseReport.tries, getCourseReportsSaga);
}
