import React from 'react';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';

import { Form, Divider, Button } from 'antd';
import AntIcon from '@ant-design/icons';
import slug from 'slug';


import { setSlug } from 'models/blogAndAdvertising/actions';
import { errorMessageCatcher } from 'utils/request';

import FileDragger from 'components/dragger';
import InputV2 from 'components/input-v2';
import { rulesValidationOne, requiredField } from 'utils/validation/validation';

import { ReactComponent as TextIcon } from 'assets/icons/blog-admin/text-icon.svg';
import { ReactComponent as QuoteIcon } from 'assets/icons/blog-admin/align-center-alt-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/blog-admin/image-square-icon.svg';
import { SortableContent } from './components/SortableContent';

import './style.less';


const BlogArticle = ({ form, readOnly }) => {
  const b = bem('blog-article');
  const dispatch = useDispatch();

  const setSlugName = (value) => {
    const pseudonym = slug(value);
    form.setFields([
      {
        name: 'slug',
        errors: [],
        value: pseudonym
      },
    ]);
    dispatch(setSlug(pseudonym));
  };

  const onTitleChange = (event) => {
    const { value } = event.target;
    const title = value.trim();
    try {
      setSlugName(title);
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  return (
    <div className={b()}>
      <InputV2
        type="form"
        label="Название статьи"
        rules={[...rulesValidationOne, requiredField]}
        placeholder="Введите название статьи"
        name="title"
        readOnly={readOnly}
        required
        onChange={onTitleChange}
      />
      <Form.Item
        label="Обложка статьи"
        name="bgImageUrl"
        rules={[requiredField]}
      >
        <FileDragger
          accept=".png, .jpeg, .jpg"
          form={form}
          initialFile={form.getFieldValue('bgImageUrl')}
          fileType={['png', 'jpeg']}
          crop
          disabled={readOnly}
          maxLength={1}
          aspect={180 / 234}
        />
      </Form.Item>
      <Divider orientation="left" orientationMargin="0" className={b('divider')}>
        Добавить контент
      </Divider>
      <Form.List name="parts">
        {(fields, { add, remove, move }) => (
          <>
            <SortableContent fields={fields} form={form} move={move} remove={remove} readOnly={readOnly} />
            <div className={b('buttons')}>
              <Button
                onClick={() => add({ defaultValue: { type: 'text' } })}
                type="primary"
                icon={<AntIcon component={TextIcon} />}
              >
                Текст
              </Button>
              <Button
                onClick={() => add({ defaultValue: { type: 'image' } })}
                type="primary"
                icon={<AntIcon component={ImageIcon} />}
              >
                Картинка
              </Button>
              <Button
                onClick={() => add({ defaultValue: { type: 'quote' } })}
                type="primary"
                icon={<AntIcon component={QuoteIcon} />}
              >
                Цитата
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default BlogArticle;
