import React, { useMemo, useState } from 'react';
import {
  Form,
  Divider,
  Select,
  Tooltip
} from 'antd';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import InputV2 from 'components/input-v2';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { requiredField } from 'utils/validation/validation';
import DropdownSearchV2 from 'components/dropdown-search';
import { getUsersOneId } from 'models/users/selectors';
import { useDebounce } from 'react-use';
import { fetchUsersOneId } from 'models/users/actions';
import { assignmentRole } from 'models/users/api';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import './style.less';


const { Option } = Select;


const formInitialValues = {
  idAuth: null,
  role: null,
};

const userInfoInitialValues = {
  organization: null,
  email: null,
};


const AssignmentRoleModal = ({ onClose, isModalVisible }) => {
  const b = bem('assignment-role-modal');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const usersAtOneId = useSelector(getUsersOneId);
  const [search, setSearch] = useState(null);
  const [userInfoShow, setUserInfoShow] = useState(userInfoInitialValues);


  const onCloseHandler = () => {
    form.resetFields();
    setUserInfoShow(userInfoInitialValues);
    onClose();
  };

  const options = useMemo(() => usersAtOneId
    .map((user) => ({
      ...user,
      name: `${user.passport?.sur_name} ${user.passport?.name} ${user.passport?.patronymic}`
    }))
    .filter((user) => user.passport?.name), [usersAtOneId]);

  const findUserData = (userId, { label }) => {
    const authorToFind = usersAtOneId?.find((user) => user.id === userId);
    const userInfo = {
      organization: authorToFind?.work?.place,
      email: authorToFind?.email
    };
    form.setFieldValue('email', authorToFind?.email);
    setUserInfoShow(userInfo);
    setSearch(label);
  };

  const onSubmit = async (user) => {
    try {
      await assignmentRole(JSON.stringify(user));
      successMessage('Роль назначена');
      onCloseHandler();
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const isDisabledButton = () => !form.isFieldsTouched(true)
    || form.getFieldsError().filter(({ errors }) => errors.length)
      .length > 0 || !userInfoShow?.email;

  useDebounce(() => {
    if (search && search.length > 0) {
      const query = `search=${search}`;
      dispatch(fetchUsersOneId(query));
    }
  }, 500, [search]);

  return (
    <ModalV2
      className={b('modal')}
      title="Добавить новую запись"
      closable={false}
      visible={isModalVisible}
      onClose={onCloseHandler}
      footer=""
    >
      <Form
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={formInitialValues}
      >

        <Form.Item
          label="Пользователь"
          name="idAuth"
          rules={[requiredField]}
        >
          <DropdownSearchV2
            onSearch={setSearch}
            onChange={findUserData}
            placeholder="Начните вводить фамилию/email пользователя"
            autoClearSearchValue
            optionLabelProp="children"
            searchValue={search}
            notFoundContent={null}
          >
            { options.length > 0
              && options.map((user) => <Option key={user.id} label={`${user.name} ${user.email}`}>{user.name}</Option>)}
          </DropdownSearchV2>
        </Form.Item>
        <InputV2
          value={userInfoShow?.email}
          label="Email"
          type="form"
          disabled
          required
          placeholder="example@email.ru"
          readOnly
        />
        <InputV2
          type="select"
          label="Роль"
          name="role"
          rules={[requiredField]}
          placeholder="Выберите роль"
        >
          <Select.Option value="content-manager">Контент-менеджер УИ</Select.Option>
          <Select.Option value="partner-content-manager">Контент-менеджер партнёра</Select.Option>
        </InputV2>
        <InputV2
          label="Организация"
          type="form"
          placeholder="Место работы"
          value={userInfoShow?.organization}
          readOnly
        />
        <Divider className={b('divider')} />
        <div className={b('footer')}>
          <Button
            type="primary"
            ghost
            onClick={onCloseHandler}
          >
            Отменить
          </Button>
          <Form.Item shouldUpdate className="submit">
            {() => (
              <Tooltip title={isDisabledButton() && 'Заполните все обязательные поля'}>
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={isDisabledButton()}
                  >
                    Сохранить
                  </Button>
                </div>
              </Tooltip>
            )}
          </Form.Item>
        </div>
      </Form>
    </ModalV2>
  );
};

export default AssignmentRoleModal;
