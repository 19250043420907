import * as c from './constants';


const initialState = {
  partners: { total: 0, items: [] },
  statuses: { total: 0, items: [] },
  authors: { total: 0, items: [] },
  bitrixCategory: [],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getPartners.success: {
      return { ...state, partners: action.payload };
    }

    case c.getStatuses.success: {
      return { ...state, statuses: action.payload };
    }

    case c.getAuthors.success: {
      return { ...state, authors: action.payload };
    }

    case c.getBitrixCategory.success: {
      return { ...state, bitrixCategory: action.payload };
    }

    default:
      return state;
  }
};

export default reducers;
