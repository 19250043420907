import bem from 'easy-bem';
import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import './style.less';


const FileNameArea = ({ title = 'Название файла' }) => {
  const b = bem('file-name-area');
  
  return (
    <div className={b()}>
      <FileImageOutlined className={b('icon')} />
      <span className={b('fileName')}>{title}</span>
    </div>
  );
};

export default FileNameArea;
