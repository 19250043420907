import React from 'react';

import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import ModalV2 from 'components/modal-v2';

import { editQuestion } from 'models/manualControlOfCouses/actions';
import FormQuestion from '../form/component';


const ModalToEditQuestion = ({ values, isModalVisible, onClose }) => {
  const b = bem('question-modal');
  const dispatch = useDispatch();


  const onSubmit = (newQuestion) => {
    dispatch(editQuestion(values._id, newQuestion));
    onClose();
  };


  return (
    <ModalV2
      className={b('modal')}
      title="Редактировать запись"
      closable={false}
      visible={isModalVisible}
      onClose={onClose}
      destroyOnClose
      footer=""
    >
      <FormQuestion
        question={values}
        onClose={onClose}
        onSubmit={onSubmit}
      />
   
    </ModalV2>
  );
};

export default ModalToEditQuestion;
