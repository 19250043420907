import React from 'react';

import ArchiveCourses from 'components/admin-panel/components/archive-courses';
import Courses from 'components/admin-panel/components/courses';

import { useDispatch } from 'react-redux';
import DirectionsOfStudy from './components/directionsOfStudy';
import DifficultyLevels from './components/difficultyLevels';
import EducationType from './components/educationType';
import Tags from './components/tags';
import EvaluationCriteria from './components/evaluation-criteria';
import WrapperTabsAdmin from '../wrapper-tabs-admin';
import EducationForm from './components/educationForm';
import Questions from './components/questions';
import Reviews from './components/reviews';
import { fetchAdminCourse } from '../../../../models/courses/actions';


const tabsItems = [
  { label: 'Курсы', key: '1', component: <Courses /> },
  { label: 'Архив', key: '2', component: <ArchiveCourses /> },
  { label: 'Направления', key: '3', component: <DirectionsOfStudy /> },
  { label: 'Теги', key: '4', component: <Tags /> },
  { label: 'Сложность', key: '5', component: <DifficultyLevels /> },
  { label: 'Типы обучения', key: '6', component: <EducationType /> },
  { label: 'Форматы обучения', key: '8', component: <EducationForm /> },
  { label: 'Оценка', key: '7', component: <EvaluationCriteria /> },
  { label: 'Вопросы', key: '9', component: <Questions /> },
  { label: 'Отзывы', key: '10', component: <Reviews /> }
];

const CourseManagement = () => {
  const dispatch = useDispatch();

  const handleChangePage = (active) => {
    if (active === '1') {
      dispatch(fetchAdminCourse('status=published, moderate, draft'));
    }
  };

  return (
    <WrapperTabsAdmin onChangeTab={handleChangePage} tabsItems={tabsItems} title="Управление витриной курсов" />
  );
};

export default CourseManagement;
