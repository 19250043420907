import React from 'react';
import { useDispatch } from 'react-redux';
import { copyAd } from 'models/blogAndAdvertising/actions';
import ModalV2 from 'components/modal-v2';
import FormAds from '../../form';


const ModalAdsCopy = ({ isVisible, onClose, value }) => {
  const dispatch = useDispatch();

  const onSubmit = (ad) => {
    dispatch(copyAd((JSON.stringify(ad))));
    onClose();
  };

  const ad = { ...value, title: '' };

  return (
    <ModalV2
      title="Добавить подборку"
      closable={false}
      visible={isVisible}
      footer=""
      onClose={onClose}
      width={548}
      destroyOnClose
    >
      <FormAds
        handleClose={onClose}
        onSubmit={onSubmit}
        ad={ad}
      />
    </ModalV2>
  );
};

export default ModalAdsCopy;
