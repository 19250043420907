import React from 'react';
import ModalV2 from 'components/modal-v2';
import { Divider } from 'antd';
import TitleText from 'components/modal/modal-review/title-text-component';
import FileNameArea from 'components/modal/modal-review/file-name-area';
import { useSelector } from 'react-redux';
import { getPublishedCourses } from 'models/courses/selectors';


const ModalReviewAd = ({ isVisible, onClose, ad }) => {
  const courses = useSelector(getPublishedCourses).items;
  const getCourseName = (id) => {
    const course = courses.find((item) => id === item._id);

    return course?.title;
  };

  return (
    <ModalV2
      title="Реклама"
      closable={false}
      visible={isVisible}
      footer=""
      onClose={onClose}
      width={548}
    >
      <TitleText label="Название подборки" text={ad?.title} />
      <Divider />
      <TitleText label="Обложка для десктоп" text="Баннер размером 1920х200px, максимальный размер 10мб" />
      <FileNameArea title={ad?.imageUrls?.desktop} />
      <Divider />
      <TitleText label="Обложка для планшета" text="Баннер размером 1024х136px, максимальный размер 10мб" />
      <FileNameArea title={ad?.imageUrls?.tablet} />
      <Divider />
      <TitleText label="Обложка для мобильной версии" text="Баннер размером 375х224px, максимальный размер 10мб" />
      <FileNameArea title={ad?.imageUrls?.phone} />
      <Divider />
      <TitleText label="Курсы" />
      {
        ad?.courses.map((course) => <TitleText key={course} text={getCourseName(course)} />)
      }
    </ModalV2>
  );
};

export default ModalReviewAd;
