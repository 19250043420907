import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';

import { ReactComponent as DeleteIcon } from 'assets/icons/modal-delete.svg';
import { onUploadfile, onUploadImage, onUploadSvg } from 'models/courses/api';
import { errorMessageCatcher } from 'utils/request';
import { jsonToFormData } from 'utils/json-to-form-data';

import FileUpload from './components/file-upload';
import DraggerFileUpload from './components/dragger-file-upload';

import './style.less';

// 10mb in bytes
const IMAGE_LIMIT = 10 * 1024 * 1024;
// 50mb in bytes
const PDF_LIMIT = 50 * 1024 * 1024;

const createFile = (file) => {
  if (file) {
    return {
      uid: file,
      name: file,
      url: file,
    };
  }

  return null;
};

function getDefaultFiles(initialFile) {
  let formFiles;
  if (Array.isArray(initialFile)) {
    formFiles = initialFile.map((item) => createFile(item));
  } else {
    const callBackFile = createFile(initialFile);
    formFiles = callBackFile ? [callBackFile] : [];
  }

  return formFiles;
}

const FileDragger = ({
  onChange,
  initialFile,
  disabled = false,
  className: classNameProp,
  maxLength = 20,
  crop = false,
  text = 'Переместите файлы сюда или',
  special = false,
  textBlue = 'выберите файлы',
  fileType = [],
  aspect = 1 / 0.5,
  tooltip = '',
  multiple = false,
  ...rest
}) => {
  const b = bem('file-dragger');
  const [imgSrc, setImgSrc] = useState('');
  const [error, setError] = useState('');
  const [files, setFiles] = useState(() => getDefaultFiles(initialFile));
  const [preparedList, setPreparedList] = useState([]);

  useEffect(() => {
    if (files.length > 0) {
      if (multiple) {
        onChange(files.map((item) => item.fileName));
      } else {
        onChange(files[0].fileName);
      }
    }
  }, [files]);

  const checkValidFormat = (file) => {
    const isTrueFormat = fileType.some((item) => (file.type.includes(item)));
    if (!isTrueFormat) {
      setError(`Файл должен быть в формате ${fileType.join(', ')}.`);
    }

    return isTrueFormat;
  };

  const checkValidSize = (file) => {
    const limit = file.type.includes('image') ? IMAGE_LIMIT : PDF_LIMIT;
    const isLimitExceed = file.size > limit;
    if (isLimitExceed) {
      setError(`Размер файла не должен превышать ${limit / 1024 / 1024}Мб.`);
    }

    return !isLimitExceed;
  };

  const showUploadList = {
    showRemoveIcon: true,
    removeIcon: <DeleteIcon />,
  };

  const onUpload = async (fileList, cropParams = null) => {
    let responseFile;
    let responseFiles = [];
    const uploadFiles = fileList.map((item) => (crop
      ? {
        uploadFile: item,
        cropParams
      }
      : { uploadFile: item }));

    uploadFiles.forEach(async (item) => {
      if (item.uploadFile.type.includes('image') && !item.uploadFile.type.includes('svg')) {
        responseFile = await onUploadImage(jsonToFormData(item));
      } else if (item.uplaodFile.name.includes('svg')) {
        responseFile = await onUploadSvg(jsonToFormData(item));
      } else {
        responseFile = await onUploadfile(jsonToFormData(item));
      }

      responseFiles = [...responseFiles, responseFile];
      if (responseFiles.length === uploadFiles.length) {
        responseFiles = responseFiles.map((item) => ({ ...item, name: item.fileName }));
        setFiles(responseFiles);
      }
    });

    // const newFileLists = [...files, responseFiles];

    // if (Array.isArray(initialFile)) {
    //   onChange(newFileLists.map((item) => item.fileName));
    // } else {
    //   onChange(newFileLists[0].fileName);
    // }
  };

  const onSelectFile = (data) => {
    const { fileList } = data;
    const initFiles = fileList.map((item) => item.originFileObj);
    initFiles.forEach((item) => {
      if (checkValidFormat(item) && checkValidSize(item)) {
        if (crop) {
          setPreparedList(initFiles);
          const reader = new FileReader();
          reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
          reader.readAsDataURL(item);
        } else {
          onUpload([item]);
        }

        setError('');
      }
    });
  };

  const removeFormValues = (file) => {
    const filterFileList = files.filter((item) => item.uid !== file.uid);
    setFiles(filterFileList);
    if (Array.isArray(initialFile)) {
      onChange(filterFileList.map((item) => item.fileName));
    } else {
      onChange(filterFileList.join(''));
    }
  };

  const handleModalOk = async (croppedParams) => {
    setImgSrc('');
    try {
      await onUpload(preparedList, croppedParams);
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  if (special && crop) {
    return (
      <FileUpload
        text={text}
        fileList={files}
        onChangeHandler={(e) => (e.file.status === 'removed' ? removeFormValues(e.file) : onSelectFile(e))}
        showUploadList={showUploadList}
        disabled={disabled}
        tooltip={tooltip}
        error={error}
        onOkModal={handleModalOk}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        aspect={aspect}
        multiple={multiple}
        {...rest}
      />
    );
  }

  return (
    <div className={b()}>
      <DraggerFileUpload
        text={text}
        fileList={files}
        onChangeHandler={(e) => (e.file.status === 'removed' ? removeFormValues(e.file) : onSelectFile(e))}
        showUploadList={showUploadList}
        textBlue={textBlue}
        error={error}
        disabled={disabled}
        maxLength={maxLength}
        tooltip={tooltip}
        crop={crop}
        onOkModal={handleModalOk}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        aspect={aspect}
        multiple={multiple}
        {...rest}
      />
    </div>
  );
};

export default FileDragger;
