import {
  call, put, takeLatest
} from 'redux-saga/effects';

import { serverErrorsLogger } from 'utils/server-errors-logger';
import { successMessage } from 'utils/message';
import * as c from './constants';

import {
  deleteCourse,
  getAdminCourses,
  onArchiveCourse,
  getCoursesBundle,
  getCourses,
} from './api';

import {
  getEducationFormats,
  getEducationLevels,
  getEducationProfiles,
  getEducationTypes,
  getEvaluationCriteria,
  getTags
} from '../manualControlOfCouses/api';


function* getAllDataToCreateCourseSaga({ payload }) {
  const { success, failure } = c.getNecessaryDataToCourse;
  try {
    const tags = yield call(getTags, payload);
    const educationTypes = yield call(getEducationTypes, payload);
    const educationProfiles = yield call(getEducationProfiles, payload);
    const educationLevels = yield call(getEducationLevels, payload);
    const educationFormats = yield call(getEducationFormats, payload);
    const evaluationCriteria = yield call(getEvaluationCriteria, payload);

    yield put({
      type: success,
      payload: {
        tags,
        educationTypes,
        educationProfiles,
        educationLevels,
        educationFormats,
        evaluationCriteria
      }
    });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getCourseAdminCourses({ payload }) {
  const { success, failure } = c.getAdminCourses;
  try {
    const courses = yield call(getAdminCourses, payload);
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPublishedAdminCoursesSaga() {
  const { success, failure } = c.getPublishedAdminCourses;
  try {
    const courses = yield call(getAdminCourses, 'status=published');
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPublishedCoursesSaga({ payload }) {
  const { success, failure } = c.getPublishedAdminCourses;
  try {
    const courses = yield call(getCourses, payload);
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getCoursesBundleSaga({ payload }) {
  const { success, failure } = c.getCoursesBundle;
  try {
    const courses = yield call(getCoursesBundle, payload);
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getArchivedCoursesSaga({ payload }) {
  const { success, failure } = c.getArchivedCourses;
  try {
    const courses = yield call(getAdminCourses, payload);
    yield put({ type: success, payload: courses });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* deleteCourseSaga({ payload }) {
  const { success, failure } = c.deleteCourse;
  try {
    yield call(deleteCourse, payload);
    yield put({ type: success, payload });
    successMessage('Курс удалён');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}


function* onArchiveCourseSaga({ payload }) {
  const { success, failure } = c.onArchiveCourse;
  try {
    const response = yield call(onArchiveCourse, payload);
    yield put({ type: success, payload: response });
    successMessage('Курс перемещён в архив!');
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.deleteCourse.tries, deleteCourseSaga);
  yield takeLatest(c.onArchiveCourse.tries, onArchiveCourseSaga);
  yield takeLatest(c.getAdminCourses.tries, getCourseAdminCourses);
  yield takeLatest(c.getNecessaryDataToCourse.tries, getAllDataToCreateCourseSaga);
  yield takeLatest(c.getPublishedCourses.tries, getPublishedCoursesSaga);
  yield takeLatest(c.getCoursesBundle.tries, getCoursesBundleSaga);
  yield takeLatest(c.getPublishedAdminCourses.tries, getPublishedAdminCoursesSaga);
  yield takeLatest(c.getArchivedCourses.tries, getArchivedCoursesSaga);
}
