/* eslint-disable no-param-reassign */
import * as XLSX from 'xlsx-js-style';

import { today } from 'utils/date';


function columnStyles(ws) {
  Object.entries(ws).forEach(([header, value]) => {
    if (header.slice(0, 1) !== '!') {
      value.s = {
        font: {
          color: { rgb: '3B4168' },
          sz: 11,
          name: 'Times New Roman',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    }

    if (['A4', 'B4', 'C4', 'D4'].includes(header)) {
      value.s = {
        font: {
          color: { rgb: '808080' },
          sz: 11,
          name: 'Times New Roman',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    }

    if (['A1'].includes(header)) {
      value.s = {
        font: {
          sz: 18,
          name: 'Times New Roman',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    }
  });
}

export const writeXlsx = (report, title) => {
  const rows = report?.orders.map((row) => ({
    fullname: row.userFullName,
    price: row.price,
    date: row.createdAt,
    status: row.statusText,
  }));
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 2, c: 3 } },
  ];

  const worksheet = XLSX.utils.json_to_sheet(rows, { origin: 'A5' });
  const workbook = XLSX.utils.book_new();

  worksheet['!merges'] = merge;
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Заявки');

  XLSX.utils.sheet_add_aoa(worksheet, [['ФИО', 'Стоимость курса, руб.', 'Дата заявки', 'Статус']], {
    origin: 'A5',
  });

  XLSX.utils.sheet_add_aoa(worksheet, [[`${title}`]], {
    origin: 'A1',
  });
  // eslint-disable-next-line no-sparse-arrays
  XLSX.utils.sheet_add_aoa(worksheet, [, [,,, today.format('DD MMMM YYYY')]], {
    origin: -1,
  });
  worksheet['!cols'] = [{ wch: 40 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
  columnStyles(worksheet);
  XLSX.writeFile(workbook, `${title}.xlsx`, { compression: true });
};
