import { getConstant } from 'utils/constant-factory';


export const getTags = getConstant('GET_TAGS');
export const addTag = getConstant('ADD_TAG');
export const deleteTag = getConstant('DELETE_TAG');

export const setEvaluationCriteria = getConstant('GET_EVALUATION_CRITERIA');
export const addEvaluationCriteria = getConstant('CREATE_EVALUATION_CRITERIA');
export const onArchiveStatusEvaluationCriteria = getConstant('ARCHIVE_STATUS_EVALUATION_CRITERIA');

export const getEducationTypes = getConstant('GET_EDUCATION_FORMS');
export const createEducationType = getConstant('CREATE_EDUCATION_TYPE');
export const deleteEducationType = getConstant('DELETE_EDUCATION_TYPE');

export const getEducationLevels = getConstant('GET_EDUCATION_LEVELS');
export const createEducationLevel = getConstant('CREATE_EDUCATION_LEVEL');
export const deleteEducationLevel = getConstant('DELETE_EDUCATION_LEVEL');

export const getEducationProfiles = getConstant('GET_EDUCATION_PROFILES');
export const getNewEducationProfile = getConstant('GET_NEW_EDUCATION_PROFILE');
export const editProfile = getConstant('EDIT_EDUCATION_PROFILE');
export const editStatusEducationProfile = getConstant('EDIT_EDUCATION_STATUS');
export const deleteSingleEducationProfile = getConstant('DELETE_EDUCATION_PROFILE');
export const getEducationFormats = getConstant('GET_EDUCATION_FORMATS');
export const getNewEducationFormat = getConstant('GET_NEW_EDUCATION_FORM');
export const deleteEducationFormat = getConstant('DELETE_EDUCATION_FORMAT');

export const getQuestions = getConstant('GET_QUESTIONS');
export const getQuestionsAdmin = getConstant('GET_QUESTIONS_ADMIN');
export const addQuestion = getConstant('ADD_QUESTION');
export const deleteQuestion = getConstant('DELETE_QUESTION');
export const editQuestion = getConstant('EDIT_QUESTION');
export const editQuestionToDraft = getConstant('EDIT_QUESTION_TO_DRAFT');
export const editQuestionData = getConstant('EDIT_QUESTION_DATA');

export const getReviewsAdmin = getConstant('GET_REVIEWS_ADMIN');
export const addReview = getConstant('ADD_REVIEW');
export const deleteReview = getConstant('DELETE_REVIEW');
export const editReview = getConstant('EDIT_REVIEW');

