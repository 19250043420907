import * as Yup from 'yup';


const datesType = Yup.lazy((value) => {
  switch (typeof value) {
    case 'object':
      return (Yup.object().shape({
        startDate: Yup.string().when('dateType', {
          is: 'choose_dates',
          then: Yup.string().required(),
        }),
        endDate: Yup.string().when('dateType', {
          is: 'choose_dates',
          then: Yup.string().required(),
        }),
      }));
    default:
      return Yup.string();
  }
});

export const courseValidationSchema = Yup.object().shape({
  dates: Yup.array()
    .of(datesType),
  dateType: Yup.string(),
  month: Yup.number()
    .when('priceType', {
      is: 'installment',
      then: Yup.number().min(1).required(),
      otherwise: Yup.number().notRequired()
    }),
  programs: Yup.array()
    .of(Yup.object().shape({
      title: Yup.string().required(),
    })),
  partners: Yup.array()
    .of(Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().required()
    })),
  teachers: Yup.array()
    .of(Yup.object().shape({
      fullName: Yup.string().required(),
      position: Yup.string().required(),
      role: Yup.string().required(),
      workplace: Yup.string().required()
    }))
});
