import queryString from 'query-string';
import * as c from './constants';


// eslint-disable-next-line max-len
const parsedFilterFromQuery = queryString.parse(window.location.search, { arrayFormat: 'bracket-separator', arrayFormatSeparator: ',' });
const initialState = {
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  drawerVisible: true,
  appWidth: window.innerWidth,
  filter: {
    tags: [],
    educationProfiles: [],
    educationType: '',
    special: '',
    levels: '',
    sort: '',
    durationPreset: '',
    ...parsedFilterFromQuery
  }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.setIsMobile.success: {
      return {
        ...state,
        isMobile: action.payload
      };
    }

    case c.setIsTablet.success: {
      return {
        ...state,
        isTablet: action.payload
      };
    }

    case c.setIsLaptop.success: {
      return {
        ...state,
        isLaptop: action.payload
      };
    }

    case c.setDrawerVisible.success: {
      return {
        ...state,
        drawerVisible: action.payload
      };
    }

    case c.setAppWidth.success: {
      return {
        ...state,
        appWidth: action.payload
      };
    }

    case c.setFilter.success: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default reducers;
