import { Divider } from 'antd';
import CardTag from 'components/card-tag';
import bem from 'easy-bem';
import React from 'react';
import { formatDate } from 'utils/date';
import FileNameArea from '../../file-name-area/component';
import TitleText from '../../title-text-component/component';
import './style.less';


const ProgramsReview = ({ programs, programFile }) => {
  const b = bem('program-review');

  return (
    <div className={b()}>
      {programs.map((program, i) => (
        <div className={b('card')} key={i}>
          <CardTag text={program.title} color="blue" />
          {programFile && <FileNameArea title={programFile} />}
          {program.dates?.length > 0
            && (
              <TitleText
                label="Дата блока"
                text={`${formatDate(program.dates?.programDateStart)} - ${formatDate(program.dates?.programDateEnd)}`}
              />
            )}
          <TitleText label="Описание блока" html={program.description} />
          <Divider />
          <div className={b('events')}>
            {program.events.map((oneEvent, idx) => (
              <div key={idx} className={b('events-item')}>
                <TitleText
                  label="Наименование мероприятия"
                  text={oneEvent.title || 'Нет'}
                />
                <TitleText
                  label="Дата мероприятия"
                  text={`${formatDate(oneEvent.eventDateStart)} - ${formatDate(oneEvent.eventDateEnd)}` || 'Нет'}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgramsReview;
