import * as c from './constants';


export const fetchAds = (query = '') => ({
  type: c.getAds.tries,
  payload: query,
});

export const createAd = (advertising) => ({
  type: c.createAd.tries,
  payload: advertising
});

export const editAd = (id, advertising) => ({
  type: c.editAd.tries,
  payload: ({ id, advertising }),
});

export const changeStatusToPublishedAd = (id) => ({
  type: c.onPublishAd.tries,
  payload: id
});
  
export const deletingAd = (id) => ({
  type: c.deleteAd.tries,
  payload: id
});

export const copyAd = (advertising) => ({
  type: c.onCopyAd.tries,
  payload: advertising
});

export const fetchPosts = (query = '') => ({
  type: c.getPosts.tries,
  payload: query,
});
export const createPost = (newPost) => ({
  type: c.createPost.tries,
  payload: newPost,
});

export const editPost = (id, post) => ({
  type: c.editPost.tries,
  payload: ({ id, post }),
});
export const onDeletePost = (id) => ({
  type: c.deletePost.tries,
  payload: id,
});
export const onArchivedPost = (id) => ({
  type: c.onArchivedPost.tries,
  payload: id,
});
export const onPublishedPost = (id) => ({
  type: c.onPublishPost.tries,
  payload: id,
});

export const fetchAdminPromos = (query = '') => ({
  type: c.getAdminPromos.tries,
  payload: query,
});
export const fetchPromosActual = (query = '') => ({
  type: c.getPromosActual.tries,
  payload: query,
});

export const createPromo = (promo) => ({
  type: c.createPromo.tries,
  payload: promo,
});

export const editPromo = (promo) => ({
  type: c.editPromo.tries,
  payload: promo,
});

export const cancelPromo = (promo) => ({
  type: c.onCancelPromo.tries,
  payload: promo,
});

export const setSlug = (slug) => ({
  type: c.setSlug.success,
  payload: slug,
});
