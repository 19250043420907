import React from 'react';
import './style.less';
import bem from 'easy-bem';
import { ReactComponent as DoneIcon } from 'assets/images/courses/done-img.svg';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import { roleText } from 'utils/textOfStatus';


const RemoveRoleModal = ({
  isModalsVisible,
  closeHandler,
  user,
  onSubmit
}) => {
  const b = bem('remove-role-modal');
  const fullName = `${user?.surname} ${user?.name} ${user?.patronymic}`;

  return (
    <ModalV2
      visible={isModalsVisible}
      width={707}
      footer={(
        <div className={b('footer')}>
          <Button
            type="primary"
            ghost
            onClick={closeHandler}
          >
            Отменить
          </Button>
          <Button type="primary" onClick={onSubmit}>Изъять роль</Button>
        </div>
      )}
      onClose={closeHandler}
      title="Изъять роль"
    >
      <div className={b('container')}>
        <div className={b('box')}>
          <DoneIcon />
          <p className={b('text')}>
            Вы уверены, что хотите изъять роль
            {' '}
            {user && roleText[user?.role]}
            <br />
            у пользователя
            {' '}
            {fullName}
          </p>
        </div>
      </div>
    </ModalV2>
  );
};

export default RemoveRoleModal;
