import React from 'react';
import ModalV2 from 'components/modal-v2';
import bem from 'easy-bem';
import TitleText from 'components/modal/modal-review/title-text-component/component';
import { Divider } from 'antd';


const ModalQuestionReview = ({ values, isModalVisible, onClose }) => {
  const b = bem('modal-question-review');

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <ModalV2
      className={b('modal')}
      title="Вопрос"
      closable={false}
      visible={isModalVisible}
      onClose={onCloseHandler}
      footer=""
    >
      <TitleText label="Вопрос" text={values?.text} />
      <Divider />
      <TitleText label="Ответ" html={values?.answer} />
    </ModalV2>
  );
};

export default ModalQuestionReview;
