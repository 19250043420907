import {
  get as oGet,
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getBanner = (id) => {
  const url = `/banners/${id}`;

  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};

export const getBannerCourses = (id) => {
  const url = `/banners/${id}/courses`;

  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};

export const getBannerReviews = (id) => {
  const url = `/banners/${id}/reviews`;

  return oGet(
    `${url}`,
    HEADERS,
    API
  );
};
