import React from 'react';
import { Form, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import ModalV2 from 'components/modal-v2';
import Button from 'components/button-v2';
import { editPost } from 'models/blogAndAdvertising/actions';
import FormPost from '../form';


const ModalEditBlog = ({
  isVisibleModal, onClose, post
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const closeHandler = () => {
    form.resetFields();
    onClose();
  };

  const onEditPost = (postEdit) => {
    dispatch(editPost(post._id, JSON.stringify({ ...postEdit, status: 'draft' })));
    closeHandler();
  };

  const disabledBtn = () => form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

  return (
    <ModalV2
      getContainer={false}
      title="Изменить запись блога"
      closable={false}
      visible={isVisibleModal}
      footer=""
      onClose={closeHandler}
      width={992}
    >
      <FormPost
        form={form}
        onHandleSubmit={onEditPost}
        post={post}
      >
        <Tooltip title="После отмены восстановление заполненных полей невозможно">
          <Button type="primary" ghost onClick={closeHandler}>Отменить</Button>
        </Tooltip>
        <Form.Item shouldUpdate>
          {
            () => (
              <Tooltip title={disabledBtn() && 'Заполните все обязательные поля'}>
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={disabledBtn()}
                  >
                    Сохранить
                  </Button>
                </div>
              </Tooltip>
            )
          }
        </Form.Item>
      </FormPost>
    </ModalV2>
  );
};

export default ModalEditBlog;
