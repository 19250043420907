import React from 'react';
import bem from 'easy-bem';
import { Form } from 'antd';
import { rulesValidationOne } from 'utils/validation/validation';
import TextArea from 'antd/es/input/TextArea';
import FileDragger from 'components/dragger';
import { selectStyle } from 'components/modal/modal-course/components/form/components/modal-upload/component';
import './style.less';
import { TextPart } from '../TextPart/component';


export const QuotePart = ({ field, readOnly, form }) => {
  const { name, key, ...rest } = field;
  const b = bem('quote-part');

  return (
    <>
      <div className={b('inputs-container')}>
        <Form.Item
          {...rest}
          name={[name, 'fullName']}
          label="ФИО"
          rules={[...rulesValidationOne]}
        >
          <TextArea
            style={{ ...selectStyle, height: 52 }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 2 }}
            placeholder="Ввод текста"
            className={b('input')}
          />
        </Form.Item>
        <Form.Item
          {...rest}
          name={[name, 'position']}
          label="Должность"
          rules={[...rulesValidationOne]}
        >
          <TextArea
            style={{ ...selectStyle, height: 52 }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 2 }}
            placeholder="Ввод текста"
            className={b('input')}
          />
        </Form.Item>
      </div>
      <TextPart field={field} readOnly={readOnly} subName="quoteText" />
      <Form.Item
        label=""
        name={[name, 'quoteImage']}
      >
        <FileDragger
          accept=".png, .jpeg, .jpg"
          form={form}
          initialFile={form.getFieldValue([name, 'quoteImage'])}
          fileType={['png', 'jpeg']}
          crop
          disabled={readOnly}
          maxLength={1}
        />
      </Form.Item>
    </>
  );
};
