import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'routes/private-route/PrivateRoute';
import AdminPanel from 'components/admin-panel';
import './style.less';
import ErrorPage from '../../pages/error';


const Courses = () => (
  <Route>
    <Switch>
      <PrivateRoute
        exact
        path="/"
        Component={AdminPanel}
      />
      <Route exact path="*">
        <ErrorPage />
      </Route>
    </Switch>
  </Route>
);

export default Courses;
