
import React from 'react';
import ModalV2 from 'components/modal-v2';
import { useDispatch } from 'react-redux';
import { addReview } from 'models/manualControlOfCouses/actions';
import FormReview from '../form/component';


const ModalToCreateReview = ({
  onClose,
  isModalVisible,
  title
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    courseId: '',
    authorName: '',
    authorAvatar: '',
    text: '',
  };


  const onSubmit = (values) => {
    dispatch(addReview(JSON.stringify(values)));
    onClose();
  };

  
  return (
    <ModalV2
      title={title}
      closable={false}
      visible={isModalVisible}
      onClose={onClose}
      footer=""
      destroyOnClose
    >
      <FormReview
        onSubmit={onSubmit}
        onCloseHandler={onClose}
        review={initialValues}
      />
    </ModalV2>
  );
};

export default ModalToCreateReview;
