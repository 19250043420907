import { Tabs } from 'antd';
import bem from 'easy-bem';
import React from 'react';

import ModalV2 from 'components/modal-v2';
import AllInformationReview from './all-information-review';
import ProgramsReview from './programs-review';
import ModalPartnersReview from './modal-partners-review';
import PriceReview from './price-review';
import TeachersReview from './teachers-review';
import CompetenciesReview from './competencies-review';
import ModalOverviewReview from './overview-review';

import './style.less';


const { TabPane } = Tabs;

const ModalCourseReview = ({ values, isModalVisible, onClose }) => {
  const b = bem('modal-course-review');
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <ModalV2
      className={b()}
      visible={isModalVisible}
      getContainer={false}
      width={992}
      closable={false}
      title={(<span style={{ fontSize: '24px', padding: 0 }}>Курс</span>)}
      footer=""
      onClose={onCloseHandler}
    >
      <Tabs className={b('tabs')} defaultActiveKey="1">
        <TabPane tab="Общая информация" key="1">
          <AllInformationReview
            initialValues={values}
          />
        </TabPane>
        <TabPane tab="Стоимость" key="2">
          <PriceReview
            priceType={values?.priceType}
            payment={values?.price}
            months={values?.month}
            paymentTerm={values?.paymentTerm}
            monthlyPayment={values?.monthlyPayment}
            bitrixUserId={values?.bitrixData?.assignedUserId}
            bitrixCategoryId={values?.bitrixData?.categoryId}
          />
        </TabPane>
        <TabPane tab="Слушатели" key="3">
          <CompetenciesReview
            competencies={values?.competencies}
            anotherStatePrograms={values?.anotherStatePrograms}
            digitalProfessions={values?.digitalProfessions}
            stateProgramEmployment={values?.stateProgramEmployment}
          />
        </TabPane>
        <TabPane tab="Программа" key="4">
          <ProgramsReview
            programs={values?.programs}
            programFile={values?.programFile}
          />
        </TabPane>
        <TabPane tab="Преподаватели" key="5">
          <TeachersReview
            teachers={values?.teachers}
          />
        </TabPane>
        <TabPane tab="Партнеры" key="6">
          <ModalPartnersReview
            partners={values?.partners}
          />
        </TabPane>

        <TabPane tab="Обзор" key="7">
          <ModalOverviewReview overview={values} />
        </TabPane>
      </Tabs>
    </ModalV2>
  );
};

export default ModalCourseReview;
