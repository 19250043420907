import * as c from './constants';


const initialState = {
  reviews: { total: 0, items: [] },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case c.getReviews.success: {
      return { ...state, reviews: action.payload };
    }

    default:
      return state;
  }
};

export default reducers;
