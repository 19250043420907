import {
  get as oGet,
} from 'utils/request';
import { BACKEND_HOST } from 'utils/env';


export const TEMP_REPORTS_API = `${BACKEND_HOST}/api/v1`;

const HEADERS = { 'Content-Type': 'application/json', Accept: 'application/json' };

export const getContentManagerReport = (id) => {
  const url = `/reports/content-manager/${id}`;

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_REPORTS_API
  );
};

export const getCourseReport = (id) => {
  const url = `/reports/course/${id}`;

  return oGet(
    `${url}`,
    HEADERS,
    TEMP_REPORTS_API
  );
};

