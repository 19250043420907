import React, { useMemo, useState } from 'react';
import bem from 'easy-bem';
import './style.less';
import {
  Select, Dropdown, Button
} from 'antd';
import { ReactComponent as Sort } from 'assets/icons/sort-none.svg';
import {
  CaretDownOutlined, CloseOutlined, EllipsisOutlined
} from '@ant-design/icons';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUsersPart } from 'models/users/selectors';
import { formatDate } from 'utils/date';
import dayjs from 'dayjs';
import Menu from 'components/menu';
import { useDebounce } from 'react-use';
import DatePicker from 'components/datepicker';
import { fetchUsersPart } from 'models/users/actions';
import { getFullNameUser } from 'utils/get-fullname-user';


const AllUsers = () => {
  const b = bem('all-users');
  const { Option } = Select;
  const allUsers = useSelector(getUsers);
  const users = useSelector(getUsersPart);
  const dispatch = useDispatch();
  const [callBackSearch, setCallBackSearch] = useState(null);
  const [filters, setFilters] = useState({
    organization: null,
    registration: null,
  });

  const columns = [
    {
      title:
  <span className={b('table-title')}>
    ФИО
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, c) => a.fullName.localeCompare(c.fullName)
    },
    {
      title:
  <span className={b('table-title')}>
    E-mail
  </span>,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title:
  <span className={b('table-title')}>
    Организация
  </span>,
      dataIndex: 'work',
      key: 'work',
      render: (work) => work?.place
    },
    {
      title:
  <span className={b('table-title')}>
    Регистрация
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      sorter: (a, d) => (dayjs(d.registrationDate).isAfter(dayjs(a.registrationDate)) ? 1 : -1),
      render: (registrationDate, index) => (<span key={index.id}>{formatDate(registrationDate)}</span>)
    },
    {
      title:
  <span className={b('table-title')}>
    Посещение
    <Sort className={b('sort-btn')} />
  </span>,
      dataIndex: 'lastActiveDate',
      key: 'lastActiveDate',
      sorter: (a, d) => (dayjs(d.lastActiveDate).isAfter(dayjs(a.lastActiveDate)) ? 1 : -1),
      render: (lastActiveDate, index) => (<span key={index.id}>{formatDate(lastActiveDate)}</span>)
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: () => (
        <Dropdown
          className={b('dropdown')}
          overlay={(
            <Menu>
              <li>
                <Button
                  type="text"
                >
                  Отчет
                </Button>
              </li>
            </Menu>
          )}
        >
          <EllipsisOutlined className={b('dropdown-icon')} />
        </Dropdown>
      )
    },
  ];

  const sortUsers = useMemo(() => users.items
    .map((item) => ({ ...item, key: item.id, fullName: getFullNameUser(item) }))
    .sort((a, d) => a.fullName.localeCompare(d.fullName)), [users.items]);

  const options = useMemo(() => {
    const uniqueValues = new Set();
    allUsers.items.forEach((item) => {
      uniqueValues.add(item?.work?.place);
    });

    return Array.from(uniqueValues).filter((item) => item !== '');
  }, [allUsers]);

  const resetFilter = () => {
    setFilters({
      organization: null,
      registration: null,
    });
  };

  const handleChangeFilters = (value, name) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  useDebounce(() => {
    const params = { ...filters, search: callBackSearch?.title, ...callBackSearch };
    delete params.title;
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const query = new URLSearchParams(params).toString();
    dispatch(fetchUsersPart(query));
  }, 500, [callBackSearch, filters]);

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        data={sortUsers}
        total={users.total}
        paginationWithBack
        changePage={setCallBackSearch}
        placeholder="Поиск по ФИО или E-mail"
        ms={0}
      >
        <div className={b('filter-box')}>
          <Select
            className={b('select')}
            value={filters.organization}
            allowClear
            onChange={(value) => handleChangeFilters(value, 'organization')}
            suffixIcon={<CaretDownOutlined />}
            placeholder="Организация"
          >
            {options.map((item, idx) => (
              <Option key={idx} value={item}>{item}</Option>
            ))}
          </Select>
          <DatePicker
            className={b('date-piker')}
            value={filters.registration}
            bordered={false}
            format="DD.MM.YYYY"
            onChange={(date) => handleChangeFilters(date, 'registration')}
            placeholder="Дата регистрации"
          />
          <Button
            className={b('btn-clear', { hidden: !(filters.registration || filters.organization) })}
            type="text"
            icon={<CloseOutlined className={b('clear-icon')} />}
            onClick={resetFilter}
          >
            Сбросить фильтр
          </Button>
        </div>
      </GeneralTableTemplate>
    </div>
  );
};

export default AllUsers;
