import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import bem from 'easy-bem';
import {
  Button, Dropdown
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDebounce } from 'react-use';

import {
  fetchAdminCourse, reworkCourse, moderateCourse, moderateRevokeCourse
} from 'models/courses/actions';
import { getCourses } from 'models/courses/selectors';
import ButtonV2 from 'components/button-v2';

import CreateCourseModal from 'components/modal/modal-course/components/create-course-modal';
import EditCourseModal from 'components/modal/modal-course/components/edit-course-modal';
import ModalCourseReview from 'components/modal/modal-review/course-review/component';
import { textStatus, dateTypeText } from 'utils/textOfStatus';
import Menu from 'components/menu';
import { formatDate } from 'utils/date';
import GeneralTableTemplate from 'components/admin-panel/general-table-template';
import { getFullNameUser } from 'utils/get-fullname-user';
import { getUserId, getUserRole } from 'models/user/selectors';
import CoursesFilters from 'components/admin-panel/components/course-filters';
import { fetchCourseReport } from 'models/reports/actions';

import {
  reworkCourse as reworkCourseApi,
  moderateCourse as moderateCourseApi,
  moderateRevokeCourse as moderateRevokeCourseApi
} from 'models/courses/api';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';

import PublicationCourseModal from './components/modal/publicationCourseModal';
import NotificationModal from './components/modal/notficationModal';
import DeleteCourseModal from './components/modal/deleteCoursModal';
import RemovePublicationModal from './components/modal/removePublicationModal';
import CourseReport from './components/modal/course-report';
import Priority from './components/priority';

import './style.less';


const statusText = {
  Опубликован: 'published',
  'На согласовании': 'agreed',
  Черновик: 'draft',
  Активен: 'active',
  'На модерации': 'moderate',
  'На доработке': 'rework'
};

const Courses = ({ position = 'default' }) => {
  const b = bem('admin-courses');
  const dispatch = useDispatch();
  const courses = useSelector(getCourses);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);
  const [isPublicationModal, setIsPublicationModal] = useState(null);
  const [isNotificationModal, setIsNotificationModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState('');
  const [isRemovePublicationModal, setIsRemovePublicationModal] = useState(null);
  const [courseReportTitle, setCourseReportTitle] = useState('');
  const [lookCourse, setLookCourse] = useState(null);
  const [isEditCourse, setIsEditCourse] = useState(null);
  const [isAddCourse, setIsAddCourse] = useState(false);
  const [filters, setFilters] = useState({
    userCreator: null,
    educationProfiles: null,
    partnersTitle: null,
    levels: null,
    educationType: null,
    status: null,
  });
  const [callBackSearch, setCallBackSearch] = useState(null);

  const contractedDate = (course) => {
    if (course.dateType !== 'choose_dates') {
      return dateTypeText[course.dateType];
    }

    const startDate = formatDate(course.dates[0].startDate);
    const endDate = formatDate(course.dates[0].endDate);

    return `${startDate} - ${endDate}`;
  };

  const handleModerate = async ({ _id }) => {
    try {
      const response = await moderateCourseApi(_id);
      dispatch(moderateCourse(response));
      successMessage('Курс отправлен на модерацию!');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const handleRevokeCourse = async ({ _id }) => {
    try {
      const response = await moderateRevokeCourseApi(_id);
      dispatch(moderateRevokeCourse(response));
      successMessage('Курс отозван!');
    } catch (e) {
      errorMessageCatcher(e);
    }
  };

  const menuContentManager = useCallback((value) => {
    const reworkAndDraftContent = (
      <>
        {value.userCreator._id === userId ? (
          <>
            <li>
              <Button type="text" onClick={() => setIsEditCourse(value)}>
                Редактировать
              </Button>
            </li>
            <li>
              <Button type="text" onClick={() => handleModerate(value)}>
                Отправить на модерацию
              </Button>
            </li>
            <li>
              <Button type="text" onClick={() => setLookCourse(value)}>
                Просмотреть
              </Button>
            </li>
            <li>
              <Button className="menu-manual__delete" type="text" onClick={() => setIsDeleteModal(value._id)}>
                Архивировать
              </Button>
            </li>
          </>
        ) : (
          <li>
            <Button type="text" onClick={() => setLookCourse(value)}>
              Просмотреть
            </Button>
          </li>
        )}
      </>
    );

    const dropdownMenuVersions = {
      rework: reworkAndDraftContent,
      draft: reworkAndDraftContent,
      published: (
        <>
          <li>
            <Button type="text" onClick={() => setLookCourse(value)}>
              Просмотреть
            </Button>
          </li>
        </>
      ),
      moderate: (
        <>
          {value.userCreator._id === userId && (
            <li>
              <Button type="text" onClick={() => handleRevokeCourse(value)}>
                Отозвать
              </Button>
            </li>
          )}
          <li>
            <Button type="text" onClick={() => setLookCourse(value)}>
              Просмотреть
            </Button>
          </li>
        </>
      )
    };

    return (
      <Menu>
        {// @ts-ignore
          !dropdownMenuVersions[value.status] ? null : dropdownMenuVersions[value.status]
        }
      </Menu>
    );
  }, [userId]);

  const menu = useCallback((value) => {
    const handleShowCourseReport = ({ _id, title }) => {
      setCourseReportTitle(title);
      dispatch(fetchCourseReport(_id));
    };

    const handleReworkCourse = async ({ _id }) => {
      try {
        const response = await reworkCourseApi(_id);
        dispatch(reworkCourse(response));
        successMessage('Курс отправлен на доработку!');
      } catch (e) {
        errorMessageCatcher(e);
      }
    };

    const dropdownMenuVersions = {
      draft:
            (
              <>
                <li>
                  <Button type="text" onClick={() => setIsPublicationModal(value._id)}>
                    Опубликовать
                  </Button>
                </li>
                <li>
                  <Button className="menu-manual__delete" type="text" onClick={() => setIsDeleteModal(value._id)}>
                    Архивировать
                  </Button>
                </li>
                <li>
                  <Button type="text" onClick={() => setIsEditCourse(value)}>
                    Редактировать
                  </Button>
                </li>
              </>
            ),
      published: (
        <>
          <li>
            <Button type="text" onClick={() => setIsRemovePublicationModal(value._id)}>
              Снять с публикации
            </Button>
          </li>
          <li>
            <Button
              type="text"
              onClick={() => {
                handleShowCourseReport(value);
              }}
            >
              Отчет
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setLookCourse(value)}>
              Просмотреть
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsEditCourse(value)}>
              Редактировать
            </Button>
          </li>
        </>
      ),
      rework: (
        <>
          <li>
            <Button type="text" onClick={() => setLookCourse(value)}>
              Просмотреть
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsEditCourse(value)}>
              Редактировать
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsPublicationModal(value._id)}>
              Опубликовать
            </Button>
          </li>
        </>
      ),
      moderate: (
        <>
          <li>
            <Button type="text" onClick={() => setIsEditCourse(value)}>
              Редактировать
            </Button>
          </li>
          <li>
            <Button type="text" onClick={() => setIsPublicationModal(value._id)}>
              Опубликовать
            </Button>
          </li>
          <li>
            <Button className="menu-manual__delete" type="text" onClick={() => handleReworkCourse(value)}>
              Отправить на доработку
            </Button>
          </li>
        </>
      )
    };

    return (
      <Menu>
        {// @ts-ignore
          !dropdownMenuVersions[value.status] ? null : dropdownMenuVersions[value.status]
        }
      </Menu>
    );
  }, [dispatch]);

  const columns = [
    {
      title:
  <span>
    Название курса
  </span>,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title:
  <span>
    Даты проведения
  </span>,
      dataIndex: 'dates',
      key: 'dates',
      render: (_, record) => (<span>{contractedDate(record)}</span>)
    },
    {
      title:
  <span>
    Автор
  </span>,
      dataIndex: 'userCreator',
      key: 'userCreator',
      render: (author) => getFullNameUser(author, true),
    },
    {
      title:
  <span>
    Статус
  </span>,
      dataIndex: 'status',
      key: 'status',
      render: (data, index) => (<span className={b('status', { [data]: true })} key={index}>{textStatus[data]}</span>)
    },
    {
      title:
  <span>
    Дата публикации
  </span>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, records) => (<span>{records.status === 'published' && formatDate(date)}</span>)
    },
    {
      title:
  <span>
    Приоритет
  </span>,
      dataIndex: 'sortPriority',
      key: 'sortPriority',
      render: (_, records) => (
        <Priority course={records} />
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Dropdown
          className={b('dropdown')}
          overlay={userRole === 'admin' ? menu(record) : menuContentManager(record)}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    },
  ];

  const onOpenNotificationModal = () => {
    setIsPublicationModal(false);
    setIsNotificationModal(true);
  };

  const sortCourses = useMemo(() => courses.items
    .map((item) => ({ ...item, key: item._id }))
    .sort((a, d) => (dayjs(d.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1)), [courses]);

  useDebounce(() => {
    const params = {
      ...callBackSearch,
      ...filters,
      status: statusText[filters.status] || 'published, draft, moderate, rework',
      partnersTitle: filters.partnersTitle ?? '',
    };
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const paramsString = new URLSearchParams(params).toString();
    dispatch(fetchAdminCourse(paramsString));
  }, 1000, [callBackSearch, filters]);

  return (
    <div className={b()}>
      <GeneralTableTemplate
        columns={columns}
        changePage={setCallBackSearch}
        data={sortCourses}
        total={courses.total}
        paginationWithBack
        ms={0}
      >
        <>
          <ButtonV2
            className={b('button-add', { [position]: true })}
            type="primary"
            ghost
            icon={<PlusOutlined />}
            onClick={() => setIsAddCourse(true)}
          >
            Создать новый курс
          </ButtonV2>
          <CoursesFilters
            setFilters={setFilters}
            filters={filters}
          />

        </>
      </GeneralTableTemplate>
      <PublicationCourseModal
        onClose={() => setIsPublicationModal(null)}
        isModalVisible={!!isPublicationModal}
        onHandleClick={onOpenNotificationModal}
        id={isPublicationModal}
      />
      <NotificationModal
        onClose={() => setIsNotificationModal(false)}
        isModalVisible={isNotificationModal}
      />
      <DeleteCourseModal
        isModalVisible={isDeleteModal !== ''}
        onClose={() => setIsDeleteModal('')}
        id={isDeleteModal}
      />
      <RemovePublicationModal
        onClose={() => setIsRemovePublicationModal(false)}
        isModalVisible={!!isRemovePublicationModal}
        id={isRemovePublicationModal}
      />
      <CourseReport
        onClose={() => setCourseReportTitle('')}
        isModalVisible={courseReportTitle.length > 0}
        courseTitle={courseReportTitle}
      />
      <EditCourseModal
        values={isEditCourse}
        isModalVisible={!!isEditCourse}
        onClose={() => setIsEditCourse(null)}
      />
      <CreateCourseModal
        isModalVisible={isAddCourse}
        onClose={() => setIsAddCourse(false)}
      />
      <ModalCourseReview
        values={lookCourse}
        isModalVisible={!!lookCourse}
        onClose={() => setLookCourse(false)}
        title="Курс"
        review
      />
    </div>
  );
};

export default Courses;

